import { useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import ToggleButton from "@mui/material/ToggleButton";

// @mui icons
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import HourglassTopOutlinedIcon from "@mui/icons-material/HourglassTopOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";

import branding from "../branding";
import { formatDollars, numShortener, stringToNumber } from "helpers/formatters";

const formatForm = (form) => {
  let formatted = form;
  formatted.monthlyPayment = stringToNumber(form.monthlyPayment);
  formatted.mortgagePayment = stringToNumber(form.mortgagePayment);
  return formatted;
};

function Page4({ nextPage, form, updateForm }) {
  const { name } = branding;
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const { value, name } = e.target;
    updateForm(name, value);
  };

  const handleContinue = async () => {
    setError(false);
    if (!form.fullName || !form.email || !form.phone) {
      setError(true);
      return;
    }
    const formatted = formatForm(form);
    await axios.post("/api/v1/calcon-quote", formatted);
    nextPage();
  };

  return (
    <>
      <MKBox
        sx={{
          px: 1,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <MKTypography
          mt={2}
          variant="h4"
          color="text"
          fontWeight="bold"
          textAlign="center"
          maxWidth="420px"
        >
          Get started with a lender
        </MKTypography>
        <MKTypography variant="body2" mt={1} mb={1} textAlign="center" maxWidth="420px">
          Fill out your contact information and a mortgage professional from {name} will be in
          touch.
        </MKTypography>
        <MKBox sx={{ maxWidth: "420px", width: "100%", mt: 1 }}>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} mb={1.75}>
              <ToggleButton
                value="calcon"
                color="primary"
                disabled
                selected={false}
                sx={{
                  display: "block",
                  cursor: "pointer",
                  flex: 1,
                  width: "100%",
                  height: "100%",
                  minHeight: "90px",
                  pt: 2,
                  pb: 2,
                  px: 2,
                  backgroundColor: "white",
                  boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.05)",
                  "&:hover:not(:disabled)": {
                    borderColor: "primary.main",
                    opacity: 1,
                  },
                  "& .Mui-selected": {
                    backgroundColor: "#F6F7FF !important",
                    borderColor: "#2A2CB1 !important",
                  },
                  "&:active": {
                    backgroundColor: "#FFF",
                  },
                  // "&:disabled": {
                  //   backgroundColor: "#F5F5F5",
                  //   opacity: 0.5,
                  //   pointerEvents: "auto",
                  // },
                }}
              >
                <MKBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <MKBox sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <MKTypography variant="body1">
                      {formatDollars(form.monthlyPayment)}
                      <span>
                        <MKTypography variant="body4" color="info">
                          {" "}
                          /mo.
                        </MKTypography>
                      </span>
                    </MKTypography>
                  </MKBox>
                  <Chip
                    label="2nd Mortgage"
                    size="small"
                    sx={{
                      backgroundColor: "primary.light",
                      color: "text.main",
                      fontWeight: "bold",
                      textTransform: "none",
                      fontSize: "12px",
                      px: 0.5,
                    }}
                  />
                </MKBox>
                <MKBox
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", iframe: "row" },
                    justifyContent: "space-between",
                    alignItems: { xs: "start", iframe: "center" },
                    mt: 1,
                  }}
                >
                  <MKBox display="flex" flexDirection="row" alignItems="center" mr={"16px"}>
                    <PercentOutlinedIcon
                      color="info"
                      sx={{
                        fontSize: "13px !important",
                        padding: 0.15,
                        mr: 0.35,
                        borderRadius: "50%",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: "info.main",
                        color: "info.main",
                      }}
                    />
                    <MKTypography variant="body1" color="info" fontWeight="regular" fontSize="85%">
                      {(form.potentialRates[0] * 100).toFixed(2)}% APR
                    </MKTypography>
                  </MKBox>

                  <MKBox display="flex" flexDirection="row" alignItems="center" mr={"16px"}>
                    <HourglassTopOutlinedIcon
                      color="info"
                      sx={{
                        fontSize: "13px !important",
                        padding: 0.15,
                        mr: 0.35,
                        borderRadius: "50%",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: "info.main",
                        color: "info.main",
                      }}
                    />
                    <MKTypography variant="body1" color="info" fontWeight="regular" fontSize="85%">
                      30/15
                    </MKTypography>
                  </MKBox>
                  <MKBox display="flex" flexDirection="row" alignItems="center">
                    <HomeOutlinedIcon
                      color="info"
                      sx={{
                        fontSize: "14px !important",
                        padding: 0.15,
                        mr: 0.25,
                        borderRadius: "50%",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: "info.main",
                        color: "info.main",
                      }}
                    />
                    <MKTypography
                      variant="body1"
                      color="info"
                      fontWeight="regular"
                      fontSize="85%"
                      mr={1}
                    >
                      {`$${numShortener(form.totalInterest)} Lifetime Interest`}
                    </MKTypography>
                  </MKBox>
                </MKBox>
                <Divider sx={{ my: 1 }} />
                <MKBox textAlign="left">
                  <MKTypography variant="body4" textTransform="none" color="info">
                    <span style={{ fontWeight: "bold" }}>A second mortgage</span> with a 30/15 fixed
                    balloon means lower monthly payments with payoff or refinancing of the remainder
                    of the loan at the end of the 15-year term.
                  </MKTypography>
                </MKBox>
              </ToggleButton>
            </Grid>
            <Grid item xs={12}>
              <MKInput
                label="Full Name"
                placeholder="Enter Full Name..."
                fullWidth
                size="medium"
                name="fullName"
                error={error && !form.fullName}
                type="text"
                variant="outlined"
                sx={{ my: 1.75 }}
                value={form.fullName}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MKInput
                label="Email Address"
                placeholder="Enter Email..."
                variant="outlined"
                name="email"
                error={error && !form.email}
                type="email"
                fullWidth
                sx={{ my: 1.75 }}
                value={form.email}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MKInput
                label="Phone Number"
                placeholder="Enter Phone Number..."
                variant="outlined"
                name="phone"
                error={error && !form.phone}
                type="tel"
                fullWidth
                sx={{ my: 1.75 }}
                value={form.phone}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </MKBox>
        <MKButton
          size="large"
          color="primary"
          sx={{ maxWidth: "420px", mt: 1, mb: 4 }}
          fullWidth
          onClick={handleContinue}
        >
          <LockOutlinedIcon color="light" sx={{ mr: "10px" }} />
          Submit
        </MKButton>
      </MKBox>
    </>
  );
}

// Typechecking props for the Page3
Page4.propTypes = {
  nextPage: PropTypes.func.isRequired,
  form: PropTypes.shape({
    address: PropTypes.shape({
      formatted: PropTypes.string.isRequired,
    }),
    potentialRates: PropTypes.arrayOf(PropTypes.number),
    monthlyPayment: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalInterest: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    email: PropTypes.string,
    phone: PropTypes.string,
    fullName: PropTypes.string,
  }).isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default Page4;
