import { MemberstackProtected } from "@memberstack/react";
import { Container } from "@mui/material";

import Login from "Users/Auth/Login";
import Estimate from "Estimate";
import Navbar from "Users/Components/Navbar";

const Calculator = () => {
  return (
    <>
      <Container
        sx={{
          pb: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Navbar />
        <MemberstackProtected onUnauthorized={<Login />}>
          <Estimate />
        </MemberstackProtected>
      </Container>
    </>
  );
};

export default Calculator;
