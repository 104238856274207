import { useEffect, useState } from "react";

import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import Chip from "@mui/material/Chip";
import ToggleButton from "@mui/material/ToggleButton";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

// @mui icons
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ThumbUpRoundedIcon from "@mui/icons-material/ThumbUpRounded";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import HourglassTopOutlinedIcon from "@mui/icons-material/HourglassTopOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

// Loan descriptions
import { loanDescriptions } from "../form/loanDescriptions";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import { stringToNumber, formatDollars, numShortener } from "helpers/formatters";

const Page2 = ({ nextPage, form, loan, rates, updateForm }) => {
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const { value, name } = e.target;
    if (value) {
      if (typeof value === "number") {
        updateForm(name, Math.round(value).toLocaleString());
        return;
      }
      const formattedValue = (Number(value.replace(/\D/g, "")) || "").toLocaleString();
      updateForm(name, formattedValue);
    } else {
      updateForm(name, "");
    }
    return null;
  };

  const handleRateSelection = (_, i) => {
    updateForm("selected", i);
  };

  const handleContinue = () => {
    setError(false);
    if (loan.maxLoan > 0 && typeof form.selected !== "number") {
      setError(true);
      return;
    }
    updateForm("rate", loan.optionsArr[form.selected]);
    nextPage();
  };

  useEffect(() => {
    if (typeof form.selected !== "number") {
      return;
    }
    if (rates[form.selected]?.msg && rates[form.selected].msg.length > 0) {
      updateForm("selected", "");
    }
  }, [rates]);

  useEffect(() => {
    if (!form.principal) {
      let startingLoan = stringToNumber(loan.maxLoan);
      if (stringToNumber(form.desiredAmount) < startingLoan) {
        updateForm("principal", form.desiredAmount);
      } else {
        updateForm("principal", loan.maxLoan.toLocaleString());
      }
    }
  }, [form.desiredAmount]);

  return (
    <MKBox
      sx={{
        py: 2,
        display: "flex",
        flexDirection: "column",
        maxWidth: "450px",
        width: "100%",
        mx: "auto",
      }}
    >
      {loan.maxLoan > 0 ? (
        <MKBox>
          <MKTypography variant="h4" color="text" textAlign="center" fontWeight="bold" mb={0}>
            Select what works for you...
          </MKTypography>
          <MKBox
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <MKTypography
              textTransform="uppercase"
              variant="caption"
              fontWeight="bold"
              color="text"
              fontSize="0.675rem"
              mr={1.5}
            >
              Amount
            </MKTypography>
            <Slider
              aria-label="Principal"
              value={stringToNumber(form.principal, 10) || stringToNumber(loan.maxLoan, 10)}
              valueLabelDisplay="on"
              color="primary"
              valueLabelFormat={formatDollars}
              name="principal"
              onChange={handleChange}
              step={5000}
              min={5000}
              max={stringToNumber(loan.maxLoan, 10)}
              marks={[
                {
                  value: stringToNumber(loan.maxLoan, 10),
                  label: `$${numShortener(stringToNumber(loan.maxLoan, 10))}`,
                },
              ]}
              sx={{
                maxWidth: "400px",
                mt: 3,
                mr: 2,
                ml: 1.5,
                "& .MuiSlider-track": { height: "6px" },
                "& .MuiSlider-markLabel": {
                  fontSize: "12px",
                  pr: 1,
                  fontWeight: "bold",
                  mt: -1.25,
                },
              }}
            />
          </MKBox>
          <MKBox
            display="flex"
            justifyContent="center"
            sx={{ my: 2, height: "450px", overflowY: "scroll" }}
          >
            <Grid container rowSpacing={1.5} sx={{ maxWidth: "450px" }}>
              {loanDescriptions.map((rate, i) => (
                <Grid item xs={12} key={rate.id}>
                  <ToggleButton
                    value={rates[i]}
                    color="primary"
                    selected={i === form.selected}
                    sx={{
                      display: "block",
                      border: i === form.selected ? "1px solid #2A2CB1" : "1px solid #DFE3FF",
                      cursor: "pointer",
                      flex: 1,
                      width: "100%",
                      height: "100%",
                      minHeight: "90px",
                      pt: 2,
                      pb: 2,
                      px: 2,
                      "&:hover:not(:disabled)": {
                        borderColor: "#646A96",
                        opacity: 1,
                      },
                      "& .Mui-selected": {
                        backgroundColor: "#F6F7FF !important",
                        borderColor: "#2A2CB1 !important",
                      },
                      "&:active": {
                        backgroundColor: "#FFF",
                      },
                      "&:disabled": {
                        backgroundColor: "#F5F5F5",
                        opacity: 0.5,
                        pointerEvents: "auto",
                      },
                    }}
                    onClick={() => handleRateSelection(rates[i], i)}
                    disabled={!!rates[i].apr <= 0 || !!rates[i].msg.length > 0}
                  >
                    <MKBox
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <MKBox sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <MKTypography
                          variant="body1"
                          color={i === form.selected ? "primary" : "text"}
                          fontWeight="bold"
                          textAlign="right"
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          {formatDollars(rates[i].monthly)}
                          <span
                            style={{
                              fontSize: "60%",
                              marginLeft: "3px",
                              color: "#646A96",
                            }}
                          >
                            /month
                          </span>
                        </MKTypography>
                      </MKBox>
                      <Chip
                        icon={rates[i].msg.length > 0 ? <></> : <ThumbUpRoundedIcon />}
                        label={
                          rates[i].msg.length > 0
                            ? rates[i].msg.map((i, n) => n === 0 && Object.values(i)[0])
                            : rate.hook
                        }
                        size="small"
                        sx={{
                          backgroundColor: rates[i].msg.length > 0 ? "error.main" : "primary.light",
                          color: rates[i].msg.length > 0 ? "white.main" : "text.main",
                          fontWeight: "bold",
                          textTransform: "none",
                          fontSize: "12px",
                          px: 0.5,
                        }}
                      />
                    </MKBox>
                    <MKBox
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "start",
                        mt: 1,
                      }}
                    >
                      <MKTypography
                        variant="body1"
                        color={i === form.selected ? "primary" : "text"}
                        textAlign="left"
                        sx={{ display: "inline", fontSize: "85%", fontWeight: "regular" }}
                      >
                        <span
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {rate.name}
                        </span>
                        {" is a "}
                        {rate.detail}
                      </MKTypography>
                    </MKBox>
                    <MKBox
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", iframe: "row" },
                        justifyContent: "start",
                        alignItems: { xs: "start", iframe: "center" },
                        mt: 1,
                      }}
                    >
                      {rates[i].apr >= 0 ? (
                        <MKBox display="flex" flexDirection="row" alignItems="center" mr={"16px"}>
                          <PercentOutlinedIcon
                            color="info"
                            sx={{
                              fontSize: "13px !important",
                              padding: 0.15,
                              mr: 0.35,
                              borderRadius: "50%",
                              borderStyle: "solid",
                              borderWidth: "1px",
                              borderColor: "info.main",
                              color: "info.main",
                              mt: -0.25,
                            }}
                          />
                          <MKTypography
                            variant="body1"
                            color="info"
                            fontWeight="regular"
                            fontSize="85%"
                          >
                            {(rates[i].apr * 100).toFixed(2)}% APR
                          </MKTypography>
                        </MKBox>
                      ) : (
                        <MKBox />
                      )}
                      <MKBox display="flex" flexDirection="row" alignItems="center" mr={"16px"}>
                        <HourglassTopOutlinedIcon
                          color="info"
                          sx={{
                            fontSize: "13px !important",
                            padding: 0.15,
                            mr: 0.35,
                            borderRadius: "50%",
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderColor: "info.main",
                            color: "info.main",
                            mt: -0.25,
                          }}
                        />
                        <MKTypography
                          variant="body1"
                          color="info"
                          fontWeight="regular"
                          fontSize="85%"
                        >
                          {typeof rates[i].term === "string"
                            ? rates[i].term
                            : `${rates[i].term / 12} yr term`}
                        </MKTypography>
                      </MKBox>

                      {rate.id === "personal" ? (
                        <MKBox display="flex" flexDirection="row" alignItems="center" mr={"16px"}>
                          <AttachMoneyIcon
                            color="info"
                            sx={{
                              fontSize: "14px !important",
                              padding: 0.15,
                              mr: 0.35,
                              borderRadius: "50%",
                              borderStyle: "solid",
                              borderWidth: "1px",
                              borderColor: "info.main",
                              color: "info.main",
                              mt: -0.25,
                            }}
                          />
                          <MKTypography
                            variant="body1"
                            color="info"
                            fontWeight="regular"
                            fontSize="85%"
                          >
                            Maximum $100k
                          </MKTypography>
                        </MKBox>
                      ) : (
                        <MKBox display="flex" flexDirection="row" alignItems="center">
                          <HomeOutlinedIcon
                            color="info"
                            sx={{
                              fontSize: "14px !important",
                              padding: 0.15,
                              mr: 0.25,
                              borderRadius: "50%",
                              borderStyle: "solid",
                              borderWidth: "1px",
                              borderColor: "info.main",
                              color: "info.main",
                              mt: -0.25,
                            }}
                          />
                          <MKTypography
                            variant="body1"
                            color="info"
                            fontWeight="regular"
                            fontSize="85%"
                            mr={1}
                          >
                            Requires ${numShortener(stringToNumber(form.principal) / rates[i].cltv)}{" "}
                            home equity
                          </MKTypography>
                        </MKBox>
                      )}
                    </MKBox>
                  </ToggleButton>
                </Grid>
              ))}
            </Grid>
          </MKBox>
          <MKButton
            size="large"
            color="primary"
            sx={{ maxWidth: "450px", width: "100%", mt: 1 }}
            fullWidth
            onClick={handleContinue}
          >
            <LockOutlinedIcon color="light" sx={{ mr: "10px" }} />
            Continue
          </MKButton>{" "}
          <MKBox height="10px" textAlign="center" mb={1}>
            {error && !form.selected && (
              <MKTypography color="error" variant="caption" textAlign="center">
                Please select an option to continue
              </MKTypography>
            )}
          </MKBox>
        </MKBox>
      ) : (
        <MKBox pb={2}>
          <MKTypography variant="h5" color="text" fontWeight="regular" textAlign="center" px={4}>
            We don&apos;t currently have any loans that you would qualify for. However, feel free to
            continue if you want to learn more about our ADU financing options.
          </MKTypography>
          <MKBox height="10px" textAlign="center">
            {error && !form.selected && (
              <MKTypography color="error" variant="caption" textAlign="center">
                Please select an option to continue
              </MKTypography>
            )}
          </MKBox>
          <MKBox display="flex" flexDirection="column" alignItems="center" pt={3} pb={1}>
            <MKButton
              size="large"
              color="primary"
              sx={{ maxWidth: "450px", width: "100%" }}
              fullWidth
              onClick={handleContinue}
            >
              <LockOutlinedIcon color="light" sx={{ mr: "10px" }} />
              Continue
            </MKButton>
          </MKBox>
        </MKBox>
      )}
    </MKBox>
  );
};

// Typechecking props for the Page1
Page2.propTypes = {
  nextPage: PropTypes.func.isRequired,
  form: PropTypes.shape({
    principal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    desiredAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    loanTerm: PropTypes.number,
  }).isRequired,
  rates: PropTypes.arrayOf(
    PropTypes.shape({
      monthly: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      apr: PropTypes.number,
      cltv: PropTypes.number,
      msg: PropTypes.array,
      term: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  loan: PropTypes.shape({
    maxLoan: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.string,
    monthlyPayment: PropTypes.string,
    optionsArr: PropTypes.arrayOf(
      PropTypes.shape({
        down: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        downPercent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        monthly: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        apr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        cltv: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
  }).isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default Page2;
