import { useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import { Check } from "@mui/icons-material";
import MKBox from "components/MKBox";

import portal from "assets/images/adu/portal.png";

const ZipCode = ({ zip, setZip, nextStep }) => {
  const [error, setError] = useState(false);

  const handleStep = () => {
    setError(false);
    if (!zip || zip.length !== 5) {
      setError(true);
      return;
    }
    nextStep();
  };

  return (
    <MKBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" pt={8}>
      <Grid container maxWidth="650px" textAlign="center" spacing={2} mb={2}>
        <Grid item xs={12}>
          <img
            width="100%"
            style={{
              marginBottom: "-60px",
              maskImage: "linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))",
              WebkitMaskImage:
                "-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))",
            }}
            src={portal}
            alt=""
          />
        </Grid>
        <Grid item xs={12}>
          <MKTypography variant="h3" fontWeight="regular" mb={1}>
            Let&apos;s get started on your project
          </MKTypography>
          <MKTypography variant="body2" fontWeight="regular">
            We&apos;ll provide quotes, property analysis, financing...and it&apos;s all free
          </MKTypography>
        </Grid>
      </Grid>
      <Grid container maxWidth="420px" rowSpacing={2} pb={6}>
        <Grid
          item
          xs={12}
          sm={4}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Check
            color="primary"
            sx={{
              fontSize: "20px !important",
              padding: 0.15,
              mr: 0.75,
              borderRadius: "50%",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "primary.main",
              mt: -0.35,
            }}
          />
          <MKTypography variant="body2" fontWeight="bold" color="primary">
            Private & secure
          </MKTypography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Check
            color="primary"
            sx={{
              fontSize: "20px !important",
              padding: 0.15,
              mr: 0.75,
              borderRadius: "50%",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "primary.main",
              mt: -0.35,
            }}
          />
          <MKTypography variant="body2" fontWeight="bold" color="primary">
            No obligation
          </MKTypography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Check
            color="primary"
            sx={{
              fontSize: "20px !important",
              padding: 0.15,
              mr: 0.75,
              borderRadius: "50%",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "primary.main",
              mt: -0.35,
            }}
          />
          <MKTypography variant="body2" fontWeight="bold" color="primary">
            No pushy sales
          </MKTypography>
        </Grid>
        <Grid item xs={12}>
          <MKInput
            fullWidth
            size="large"
            style={{ backgroundColor: "white", borderRadius: "10px" }}
            name="zip"
            placeholder="Zip Code"
            onChange={setZip}
            value={zip}
            error={error}
            inputProps={{
              minLength: "5",
              maxLength: "5",
              inputMode: "numeric",
              style: { fontSize: "18px" },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <MKButton fullWidth size="large" color="primary" onClick={handleStep}>
            Let&apos;s Go
          </MKButton>
        </Grid>
      </Grid>
    </MKBox>
  );
};

// Typechecking props for the Page3
ZipCode.propTypes = {
  zip: PropTypes.string,
  setZip: PropTypes.func,
  nextStep: PropTypes.func,
};

export default ZipCode;
