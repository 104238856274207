import { useState, useRef } from "react";

import PropTypes from "prop-types";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const AddressAutoComplete = ({ isLoading, onChange, error, startingValue, maxWidth, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [empty, setIsEmpty] = useState(true);
  const [noResults, setNoResults] = useState(false);
  const [addressOptions, setAddressOptions] = useState([]);
  const timeout = useRef();
  const loading = !empty && (isLoading || (open && addressOptions.length === 0 && !noResults));

  const handleDebounceSearch = (input) => {
    //Clear the previous timeout.
    clearTimeout(timeout.current);
    setNoResults(false);

    // If there is no search term, do not make API call
    if (!input.trim()) {
      setIsEmpty(true);
      setAddressOptions([]);
      return;
    }
    timeout.current = setTimeout(() => {
      fetch(
        `https://api.geoapify.com/v1/geocode/autocomplete?&limit=5&lang=en&format=json&filter=countrycode:us&text=${input}&apiKey=${process.env.REACT_APP_GEOAPIFY_API}`
      )
        .then(async (response) => {
          if (!response.ok) {
            console.log("Something went wrong!");
          } else {
            const data = await response.json();
            if (data.results.length === 0) {
              setNoResults(true);
              setAddressOptions([]);
            }
            setAddressOptions(data.results);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }, 75); //75ms debounce (shorter for more responsive, longer for less geoapify calls
  };

  return (
    <MKBox maxWidth={maxWidth}>
      <Autocomplete
        disablePortal
        id="address-autocomplete"
        options={addressOptions}
        onOpen={() => {
          setOpen(true);
        }}
        noOptionsText={empty ? "Start Typing..." : "Address not found"}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(_, o) => onChange(o)}
        isOptionEqualToValue={(option, value) => option.formatted === value.formatted}
        getOptionLabel={(o) => o.formatted || ""}
        value={startingValue}
        loading={loading}
        fullWidth
        filterOptions={(x) => x}
        renderInput={(params) => (
          <TextField
            fullWidth
            variant="outlined"
            label="Property Address"
            placeholder="Start typing..."
            error={error}
            onChange={(ev) => {
              // dont fire API if the user delete or not entered anything
              if (ev.target.value === "" || ev.target.value === null) {
                return setIsEmpty(true);
              }
              setIsEmpty(false);
              handleDebounceSearch(ev.target.value);
            }}
            inputProps={{
              ...params.inputProps,
            }}
            sx={{ mt: 1 }}
            InputLabelProps={{
              ...params.InputLabelProps,
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        {...rest}
      />
      <MKTypography
        variant="body2"
        color="info"
        textAlign="right"
        sx={{ mt: -2.25, mr: 1, textAlign: "right", fontSize: "10px" }}
      >
        powered by{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://www.geoapify.com/">
          Geoapify
        </a>
      </MKTypography>
    </MKBox>
  );
};

// Setting default values for the props of MKInput
AddressAutoComplete.defaultProps = {
  startingValue: { formatted: "" },
  isLoading: false,
  error: false,
};

// Typechecking props for the MKInput
AddressAutoComplete.propTypes = {
  startingValue: PropTypes.shape({
    formatted: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  maxWidth: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default AddressAutoComplete;
