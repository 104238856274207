export const loanDescriptions = [
  {
    id: "second",
    name: "Second mortgage",
    desc: "Take out a second mortgage on your existing property",
    hook: "Qualifies for CalHFA ADU grant",
    detail: "way to access your existing equity easily with a loan format that will feel familiar.",
  },
  // {
  //   id: "refi",
  //   name: "Cash out refinance",
  //   desc: "Tap into your home equity to get 90% of it in cash",
  //   hook: "Refinance at lower rates",
  //   detail:
  //     "lump sum refinancing of your current mortgage in exchange for an increase in term length, monthly payment, or both.",
  // },
  {
    id: "heloc",
    name: "HELOC",
    hook: "$0 closing costs",
    desc: "Get a line of credit using your home equity",
    detail:
      "line of credit backed by your home’s existing equity. Borrow as and when needed and repay in monthly installments like a credit card.",
  },
  // {
  //   id: "reno",
  //   name: "Renovation loan",
  //   hook: "Lower equity requirements",
  //   desc: "Tap into 110% of your home equity to get cash for a renovation",
  //   detail:
  //     "scheduled draw loan paid directly to builder. Backed by the improved value of your home after renovations.",
  // },
  {
    id: "equity",
    name: "Equity share",
    hook: "Pay off when you sell",
    desc: "Tap into your future home equity to get up to $500k in cash",
    detail:
      "lump sum in exchange for equity in your home. Repayable at any time up during the term length or upon the sale of your home.",
  },
  // {
  //   id: "personal",
  //   name: "Personal loan",
  //   hook: "Good for smaller amounts",
  //   desc: "Get up to $100k cash to finance almost anything",
  //   detail:
  //     "lump sum in exchange for a monthly payback. Often carries a higher interest rate than a comparable equity backed loans.",
  // },
];
