import PropTypes from "prop-types";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MKBox from "components/MKBox";
import { BusinessOutlined, OpenInFull, WorkHistoryOutlined } from "@mui/icons-material";
import MKTypography from "components/MKTypography";
import { IconButton, Link } from "@mui/material";

const keys = [
  "Builder",
  "Floorplan",
  "Construction Type",
  "Bedrooms",
  "Bathrooms",
  "Kitchen",
  "Notes",
  "Cost Per Ft²",
  "Total Cost",
  "PDF Quote",
  "Action",
];

const renderCell = (n, q, i) => {
  if (n === "Builder") {
    return (
      <TableCell
        component="th"
        variant="head"
        scope="row"
        sx={{
          verticalAlign: "top",
          py: "0px",
          minWidth: "220px",
          backgroundColor: (i - 1) % 2 ? "#fff" : "inherit",
        }}
        key={n + q.quote.id}
      >
        <MKBox>
          <MKBox
            pb={1}
            pt={1}
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            style={{ borderRadius: 5 }}
          >
            <Link href={q.business.website}>
              <MKTypography
                variant="h5"
                mt={1}
                fontWeight="regular"
                textAlign="center"
                style={{ textDecoration: "underline" }}
              >
                {q.business.name}
              </MKTypography>
            </Link>
            <MKTypography variant="caption" mt={0} display="flex">
              <BusinessOutlined style={{ marginRight: 5 }} /> {q.business.address}
            </MKTypography>
            <MKTypography variant="caption" mt={0} display="flex">
              <WorkHistoryOutlined style={{ marginRight: 5 }} />{" "}
              {/* {currentYear - q.business.yearFounded} years in business */}
            </MKTypography>
          </MKBox>
        </MKBox>
      </TableCell>
    );
  } else if (n === "Floorplan") {
    console.log(q["quote"]["images"]["floorplan"]);
    return (
      <TableCell style={{ width: "150px", backgroundColor: (i - 1) % 2 ? "#fff" : "inherit" }}>
        <MKBox my={1} display="flex" position="relative">
          <img
            src={q["quote"]["images"]["floorplan"]}
            loading="lazy"
            style={{
              height: "160px",
              maxWidth: "100%",
              display: "block",
              position: "relative",
            }}
            alt="Floorplan"
          />
          <IconButton
            size="small"
            color="text"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: "#ffffffaa",
            }}
          >
            <OpenInFull />
          </IconButton>
        </MKBox>
      </TableCell>
    );
  } else {
    return (
      <TableCell style={{ width: "150px", backgroundColor: (i - 1) % 2 ? "#fff" : "inherit" }}>
        <MKTypography variant="body2" fontWeight="light" mr={2} minWidth="60px">
          {q["quote"][n]}
        </MKTypography>
      </TableCell>
    );
  }
};

const QuoteTable = ({ quotes }) => {
  return (
    <TableContainer>
      <Table aria-label="simple table" sx={{ tableLayout: { xs: "auto", lg: "fixed" } }}>
        <TableBody>
          {keys.map((k) => {
            return (
              <TableRow key={k}>
                <TableCell component="th" scope="row" sx={{ py: "0px", minWidth: "110px" }}>
                  {k}
                </TableCell>
                {quotes.map((q, i) => {
                  return renderCell(k, q, i);
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// Typechecking props for the MKInput
QuoteTable.propTypes = {
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      business: PropTypes.shape({}),
      quote: PropTypes.shape({}),
    })
  ),
};

export default QuoteTable;
