/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { MemberstackProvider } from "@memberstack/react";

import App from "App";

const { REACT_APP_MEMBERSTACK_PUBLIC_KEY, REACT_APP_MEMBERSTACK_APP_ID } = process.env;

const config = {
  publicKey: REACT_APP_MEMBERSTACK_PUBLIC_KEY,
  appId: REACT_APP_MEMBERSTACK_APP_ID,
};

ReactDOM.render(
  <MemberstackProvider config={config}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </MemberstackProvider>,

  document.getElementById("root")
);
