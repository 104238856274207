import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import ReactGA from "react-ga4";

import Authentication from "Context/auth";

// @mui icons
import TurnLeft from "@mui/icons-material/TurnLeft";
import CloseIcon from "@mui/icons-material/Close";

import LinearProgress from "@mui/material/LinearProgress";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// branding
import branding from "./branding";

// form initialization
import { initialState } from "./form/initialState";

// helper for loan calculation
import { getFinancing } from "helpers/calculators/loanCalc";
import { stringToNumber } from "helpers/formatters";
import { setLocalStorage, readLocalStorage } from "helpers/localStorage";

import Page0 from "./pages/page0";
import Page1 from "./pages/page1";
import Page2 from "./pages/page2";
import Page3 from "./pages/page3";
import Page4 from "./pages/page4";
import Page5 from "./pages/page5";

function WidgetPage({ handleLocalClose, defaultValue, noClose }) {
  const auth = useContext(Authentication);
  const [searchParams] = useSearchParams();
  const { id } = branding;
  const [page, setPage] = useState(0);
  const [form, setForm] = useState(initialState);
  const [loan, setLoan] = useState({ maxLoan: null, monthlyPayment: null, error: "" });
  const [rates, setRates] = useState([
    { id: "second", apr: 0, equity: 0, payment: 0 },
    // {
    //   id: "refi",
    //   apr: 0,
    //   equity: 0,
    //   payment: 0,
    // },
    {
      id: "heloc",
      apr: 0,
      equity: 0,
      payment: 0,
    },
    // {
    //   id: "reno",
    //   apr: 0,
    //   equity: 0,
    //   payment: 0,
    // },
    {
      id: "equity",
      apr: 0,
      equity: 0,
      payment: 0,
    },
    // {
    //   id: "personal",
    //   apr: 0,
    //   equity: 0,
    //   payment: 0,
    // },
  ]);

  useEffect(async () => {
    if (auth.jwt) {
      try {
        const res = await axios.get("/api/v1/advanced-quote");
        setForm(res.data);
      } catch (err) {
        console.log(err);
      }
    }
  }, [auth.jwt]);

  const nextPage = () => {
    setPage(page + 1);
  };

  const updateForm = (field, value) => {
    setForm((e) => ({
      ...e,
      [field]: value,
    }));
  };

  const handleClose = () => {
    if (noClose) {
      return setPage(5);
    }
    if (auth.jwt) {
      parent.postMessage(
        {
          type: "redirect",
          url: "/account/homeowners",
        },
        "*"
      );
      if (handleLocalClose) {
        handleLocalClose();
      }
    } else {
      parent.postMessage(`closeWindow`, "*");
    }
  };

  // update form from local storage upon initialization and initialize with loan value if available
  useEffect(() => {
    setForm(readLocalStorage(`Dwellito${id}-Form`, initialState));
    setPage(readLocalStorage(`Dwellito${id}-Page`, 0));
    if (searchParams.get("initialValue") || defaultValue) {
      const intialLoanValue = searchParams.get("initialValue") || defaultValue;
      setForm((f) => ({
        ...f,
        desiredAmount: Number(intialLoanValue.replace(/\D/g, "") || "").toLocaleString(),
      }));
    }
  }, []);

  // update the loan on changes to the form
  useEffect(() => {
    const loanRes = getFinancing({
      homeValue: (stringToNumber(form.homeValue[0]) + stringToNumber(form.homeValue[1])) / 2,
      currentDebt: stringToNumber(form.currentDebt),
      creditScore: form.creditScore,
      loanAmount: stringToNumber(form.principal),
      loanLength: form.loanTerm,
    });
    setLoan(loanRes);
    setRates(loanRes.optionsArr);
  }, [form.homeValue, form.currentDebt, form.creditScore, form.principal, form.loanTerm]);

  useEffect(() => {
    setForm((f) => ({ ...f, maxLoan: loan.maxLoan }));
  }, [loan]);

  // save form to local storage upon each page transition
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `/${id}page-${page}`, title: `${id}page ${page}` });
    if (page > 0 && auth.jwt) {
      setLocalStorage(`Dwellito${id}-Page`, 0);
    } else if (page > 0 && !auth.jwt) {
      setLocalStorage(`Dwellito${id}-Page`, page);
      setLocalStorage(`Dwellito${id}-Form`, form);
    }
  }, [page]);

  return (
    <MKBox>
      {page !== 4 && page !== 5 && (
        <MKBox>
          <LinearProgress value={((page + 1) / 4) * 100} variant="determinate" color="primary" />
        </MKBox>
      )}
      <MKBox
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pt: 2, px: 2 }}
      >
        {page === 0 || page === 4 || page === 5 ? (
          <MKBox height="24px" />
        ) : (
          <TurnLeft
            fontSize="medium"
            color="info"
            sx={{ cursor: "pointer", "&:hover": { color: "primary.main" } }}
            onClick={() => setPage(page - 1)}
          />
        )}
        {!noClose && (
          <CloseIcon
            fontSize="medium"
            color="info"
            cursor="pointer"
            sx={{ cursor: "pointer", "&:hover": { color: "primary.main" } }}
            onClick={handleClose}
          />
        )}
      </MKBox>
      <MKBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        sx={{ px: 2, overflow: "auto", height: "100%" }}
      >
        {(() => {
          switch (page) {
            case 0:
              return <Page0 nextPage={nextPage} form={form} updateForm={updateForm} loan={loan} />;
            case 1:
              return <Page1 nextPage={nextPage} form={form} updateForm={updateForm} loan={loan} />;
            case 2:
              return (
                <Page2
                  nextPage={nextPage}
                  loan={loan}
                  form={form}
                  rates={rates}
                  updateForm={updateForm}
                />
              );
            case 3:
              return (
                <Page3
                  form={form}
                  updateForm={updateForm}
                  nextPage={nextPage}
                  rates={rates}
                  handleClose={handleClose}
                />
              );
            case 4:
              return <Page4 form={form} updateForm={updateForm} nextPage={handleClose} />;
            case 5:
              return <Page5 form={form} updateForm={updateForm} nextPage={handleClose} />;
            default:
              return <Page0 nextPage={nextPage} form={form} updateForm={updateForm} />;
          }
        })()}
      </MKBox>
    </MKBox>
  );
}
// Typechecking props for the Page3
WidgetPage.propTypes = {
  handleLocalClose: PropTypes.func,
  defaultValue: PropTypes.number,
  noClose: PropTypes.bool,
};
export default WidgetPage;
