import PropTypes from "prop-types";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";

import MKTypography from "components/MKTypography";
import useWindowSize from "Hooks/useWindowSize";

const Timeline = ({ timeline, setTimeline }) => {
  const { width } = useWindowSize();
  return (
    <>
      <Grid container maxWidth="900px" textAlign="center" spacing={2} pb={6}>
        <Grid item xs={12}>
          <MKTypography variant="body2">1 / 3</MKTypography>
          <MKTypography variant="h3" fontWeight="regular">
            What&apos;s your timeline?
          </MKTypography>
        </Grid>
        <Grid item xs={12}>
          <ToggleButtonGroup
            color="primary"
            sx={{
              alignItems: "center",
              mx: 0,
              px: 0,
              "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                borderColor: "rgba(0,0,0,0.12) ",
              },
              "& .Mui-selected": {
                backgroundColor: "#F6F7FF !important",
                borderColor: "#2A2CB1 !important",
              },
            }}
            value={timeline}
            fullWidth
            exclusive
            orientation={width < 768 ? "vertical" : "horizontal"}
            onChange={(_, v) => setTimeline(v)}
          >
            <ToggleButton
              value={"ASAP"}
              name="timeline"
              sx={{
                p: 0.5,
                maxWidth: "300px",
                fontWeight: "normal",
                marginRight: { xs: 0, md: 1 },
                marginBottom: { xs: 1, md: 0 },
                mt: 1,
                width: "100%",
                fontSize: "16px",
                borderColor: "rgba(0,0,0,0.12) !important",
                textTransform: "none !important",
                borderRadius: "5px !important",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                pt: 3,
              }}
            >
              ASAP
              <MKTypography variant="h6" color="primary" mb={2}>
                Ready to break ground
              </MKTypography>
              <img
                src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/60edefc65e33fa18ee738f50_Timeline-This%20Year.svg"
                width="88"
                alt="Timeline This Year"
                style={{ marginBottom: "-60px" }}
              />
              <img
                width="100%"
                src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/6352f1a7ff883235a687cd06_View%202_White_Transparent%20Background.png"
                alt=""
              />
            </ToggleButton>
            <ToggleButton
              value="1-Year"
              name="timeline"
              sx={{
                maxWidth: "300px",
                p: 0.5,
                fontWeight: "normal",
                marginRight: { xs: 0, md: 1 },
                marginBottom: { xs: 1, md: 0 },
                mt: 1,
                fontSize: "16px",
                borderColor: "rgba(0,0,0,0.12) !important",
                textTransform: "none !important",
                borderRadius: "5px !important",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                pt: 3,
              }}
            >
              Within 1 year
              <MKTypography variant="h6" color="primary" mb={2}>
                Getting things setup
              </MKTypography>
              <img
                src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/60edefc65e33fac28b738f4f_Timeline-Next%20Year.svg"
                width="88"
                alt="Timeline next year"
                style={{ marginBottom: "-60px" }}
              />
              <img
                width="100%"
                src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/6352f1a7ff883235a687cd06_View%202_White_Transparent%20Background.png"
                alt=""
              />
            </ToggleButton>
            <ToggleButton
              value="None"
              name="timeline"
              sx={{
                p: 0.5,
                maxWidth: "300px",
                fontWeight: "normal",
                mt: 1,
                fontSize: "16px",
                borderColor: "rgba(0,0,0,0.12) !important",
                textTransform: "none !important",
                borderRadius: "5px !important",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                pt: 3,
              }}
            >
              None
              <MKTypography variant="h6" color="primary" mb={2}>
                Just browsing
              </MKTypography>
              <img
                src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/60edefc65e33fa718e738f4e_Timeline-None.svg"
                width="88"
                alt="Timeline - none"
                style={{ marginBottom: "-60px" }}
              />
              <img
                width="100%"
                src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/6352f1a7ff883235a687cd06_View%202_White_Transparent%20Background.png"
                alt=""
              />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </>
  );
};

// Typechecking props for the Page3
Timeline.propTypes = {
  timeline: PropTypes.number,
  setTimeline: PropTypes.func,
};

export default Timeline;
