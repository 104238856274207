import { useAuth } from "@memberstack/react";
import { useMemberstackModal } from "@memberstack/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";

import MKBox from "components/MKBox";

import { ReactComponent as DwellitoLogo } from "assets/images/logos/dwellito-logo.svg";
import MKButton from "components/MKButton";

const Navbar = () => {
  const navigate = useNavigate();
  const { openModal, hideModal } = useMemberstackModal();
  const { signOut, isLoggedIn } = useAuth();

  const handleSignOut = () => {
    signOut();
    delete axios.defaults.headers.common["Authorization"];
    window.location.replace("https://www.dwellito.com");
  };

  const handleLogo = () => {
    if (!isLoggedIn) {
      openModal({
        type: "LOGIN",
        planId: "pln_test-wvc0xxf",
      }).then(() => {
        hideModal();
      });
    } else {
      navigate("/homeowner");
    }
  };

  return (
    <AppBar position="static">
      <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <DwellitoLogo width="90px" style={{ cursor: "pointer" }} onClick={handleLogo} />
        <MKBox display="flex">
          {isLoggedIn && (
            <>
              {/* <MKButton
                style={{ cursor: "pointer" }}
                variant="text"
                color="info"
                mr={1}
                size="large"
                onClick={() => navigate("/homeowner/onboarding")}
              >
                Settings
              </MKButton> */}

              <MKButton variant="text" color="info" onClick={handleSignOut} size="large">
                Logout
              </MKButton>
            </>
          )}
        </MKBox>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
