export const initialState = {
  desiredAmount: "310000",
  homeValue: [600000, 800000],
  currentDebt: null,
  income: null,
  creditScore: "",
  principal: "",
  maxLoan: null,
  selected: "",
  loanTerm: 360,
  rate: { amount: "", downPercent: "", downAmount: "", apr: "" },
  fullName: "",
  email: "",
  address: {
    formatted: "",
  },
};
