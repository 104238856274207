import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import PropTypes from "prop-types";

// @mui material components
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";

// @mui icons
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKBadge from "components/MKBadge";

import AddressAutoComplete from "components/AdressAutocomple";

import { ReactComponent as DwellitoLogo } from "assets/images/logos/dwellito-logo.svg";

import branding from "../branding";

import { stringToNumber, formatDollars, numberToString } from "helpers/formatters";
import { calculateMonthlyPayment } from "helpers/calculators/loanCalc";

function Page0({ nextPage, form, updateForm }) {
  const [searchParams] = useSearchParams();
  const { name } = branding;
  const [error, setError] = useState(false);
  const [calc, setCalc] = useState({
    paymentLow: 0,
    paymentHigh: 0,
  });

  useEffect(() => {
    if (form.desiredAmount) {
      setCalc({
        paymentLow: calculateMonthlyPayment(stringToNumber(form.desiredAmount), 0.0875),
        paymentHigh: calculateMonthlyPayment(stringToNumber(form.desiredAmount), 0.0975),
      });
    } else {
      setCalc({
        paymentLow: 0,
        paymentHigh: 0,
      });
    }
  }, [form.desiredAmount]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    if (value) {
      const formattedValue = (Number(value.replace(/\D/g, "")) || "").toLocaleString();
      updateForm(name, formattedValue);
    } else {
      updateForm(name, "");
    }
    return null;
  };

  const handleAddress = (v) => {
    updateForm("address", v);
  };

  const handleContinue = async () => {
    if (!form.address || !form.address?.formatted) {
      setError(true);
      return;
    }
    try {
      const res = await axios.post("/api/v1/valuation", { address: form.address?.formatted });
      const { data } = res;
      updateForm("valuation", data);
      updateForm("homeValue", [data.priceRangeLow, data.priceRangeHigh]);
    } catch (err) {
      console.log(err);
    }
    updateForm(
      "sourceURL",
      document.referrer || window.location?.ancestorOrigins[0] || window.location.href
    );
    updateForm("sourceName", searchParams.get("formId") || "Dwellito");
    nextPage();
  };

  return (
    <>
      <MKBox
        px={2}
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <DwellitoLogo />
        <MKTypography mt={2} variant="h4" color="text" fontWeight="bold" textAlign="center">
          Get a loan estimate for your ADU
        </MKTypography>
        <MKTypography variant="body2" mt={1} mb={1}>
          We&apos;ll match you with a {name} loan
        </MKTypography>
        <MKBox display="flex" alignItems="center">
          <MKTypography variant="body3" mr={2} color="text">
            Loan Amount
          </MKTypography>
          <MKInput
            variant="outlined"
            name="desiredAmount"
            sx={{ maxWidth: "140px", my: 1, cursor: "pointer" }}
            type="text"
            value={numberToString(form.desiredAmount)}
            onChange={handleChange}
            onFocus={(event) => event.target.select()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MKTypography fontSize="15px" color="info">
                    $
                  </MKTypography>
                </InputAdornment>
              ),
              inputMode: "decimal",
              sx: {
                boxShadow: "0px 0px 10px 2px rgba(0,0,0,0.1)",
                borderRadius: 15,
              },
              style: { border: "none !important" },
            }}
            // eslint-disable-next-line
            inputProps={{
              inputMode: "numeric",
              sx: { py: 1.5 },
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </MKBox>
        <MKBox width="100%" maxWidth="420px" mt={1}>
          <AddressAutoComplete
            onChange={handleAddress}
            startingValue={form.address}
            error={error && (!form.address || !form.address?.formatted)}
          />
        </MKBox>
        <MKBox
          maxWidth="420px"
          width="100%"
          mt={3}
          mb={1}
          display="flex"
          justifyContent="center"
          sx={{
            backgroundColor: "light.lighter",
            borderRadius: "5px",
            border: "1px solid",
            borderColor: "light.main",
            pt: 1,
            pb: 2,
          }}
        >
          <Grid container maxWidth="340px">
            <Grid item xs={12} textAlign="center">
              <MKTypography variant="caption" fontWeight="bold">
                Estimated Monthly Rate
              </MKTypography>
            </Grid>
            <Grid
              item
              xs={5}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "50px",
              }}
            >
              <>
                <MKTypography variant="body1">
                  {formatDollars(calc.paymentLow)}
                  <span>
                    <MKTypography variant="body4" color="info">
                      {" "}
                      /mo.
                    </MKTypography>
                  </span>
                </MKTypography>
                <MKTypography variant="body4" fontWeight="regular" color="info">
                  30/15, 8.75% APR
                </MKTypography>
              </>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              <MKTypography variant="h6" color="info">
                —
              </MKTypography>
            </Grid>
            <Grid
              item
              xs={5}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MKTypography variant="body1">
                {formatDollars(calc.paymentHigh)}
                <span>
                  <MKTypography variant="body4" color="info">
                    {" "}
                    /mo.
                  </MKTypography>
                </span>
              </MKTypography>
              <MKTypography variant="body4" fontWeight="regular" color="info">
                30/15, 9.75% APR
              </MKTypography>
            </Grid>
            <Grid item xs={12} textAlign="center" mt={1}>
              <MKBadge
                color="white"
                size="xs"
                variant="contained"
                badgeContent="Based on great credit and home equity"
              />
            </Grid>
          </Grid>
        </MKBox>
        <MKButton
          size="large"
          color="primary"
          sx={{ maxWidth: "420px", width: "100%", mt: 2 }}
          fullWidth
          onClick={handleContinue}
        >
          <MKBox color="white" display="flex" alignItems="center">
            <LockOutlinedIcon color="light" sx={{ mr: "10px" }} />
            Personalize my estimate
          </MKBox>
        </MKButton>

        <MKTypography variant="body5" color="info" my={2}>
          This estimate will not affect your credit
        </MKTypography>
      </MKBox>
    </>
  );
}

// Typechecking props for the Page1
Page0.propTypes = {
  nextPage: PropTypes.func.isRequired,
  form: PropTypes.shape({
    desiredAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    address: PropTypes.shape({
      formatted: PropTypes.string.isRequired,
    }),
    valuation: PropTypes.shape({}).isRequired,
  }).isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default Page0;
