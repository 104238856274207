import { Checkbox, FormControlLabel, Switch, Link, InputAdornment, Slider } from "@mui/material";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";
import MKInput from "components/MKInput";

import AddressAutoComplete from "components/AdressAutocomple";
import NumberFormatCustom from "components/NumberFormat";
import Estimate from "Estimate";
import WidgetPage from "Lenders/Default";
import Onboarding from "Users/Onboarding";
import Mapbox from "Map";

import { numShortener } from "helpers/formatters";

const renderer = (arr, form, updateForm, handleCta) => {
  return arr.map((s) => {
    if (s.type === "mapbox") {
      return (
        <MKBox key={s.id} sx={{ height: "300px", width: "100%" }}>
          <Mapbox
            startLat={form?.onboarding?.address?.lat}
            startLng={form?.onboarding?.address?.lon}
          />
        </MKBox>
      );
    } else if (s.type === "onboarding") {
      return (
        <Onboarding
          key={s.id}
          noNavbar
          NoFullHeight
          shortened
          externalForm={form.onboarding}
          bigButton
          externalHandleSubmit={handleCta}
        />
      );
    } else if (s.type === "box") {
      if (s.fullWidth) {
        return (
          <MKBox
            key={s.id}
            sx={{
              position: "relative",
              width: "100vw",
              left: "calc((100% - 100vw) / 2)",
              marginLeft: { xs: 0, lg: "-50px", md: "-100px" },
              background: s.background,
            }}
          >
            {renderer(s.arr, form, updateForm, handleCta)}
          </MKBox>
        );
      } else
        return (
          <MKBox
            display="flex"
            flexDirection="column"
            key={s.id}
            sx={{ background: s.background, padding: s.padding, borderRadius: s.borderRadius || 1 }}
          >
            {renderer(s.arr, form, updateForm, handleCta)}
          </MKBox>
        );
    }
    if (s.type === "typography") {
      return (
        <MKTypography key={s.id} variant={s.subtype} fontWeight={s.fontWeight}>
          {s.content}
        </MKTypography>
      );
    } else if (s.type === "financingCalculator") {
      return <WidgetPage noClose key={s.id} />;
    } else if (s.type === "costCalculator") {
      return (
        <Estimate
          key={s.id}
          zipCode={form?.onboarding?.address?.postcode || ""}
          noRedirect
          noFullHeight
          noTitle
          wideButton
          externalForm={form.cost}
          externalHandleSubmit={handleCta}
        />
      );
    } else if (s.type === "note") {
      return (
        <MKBadge
          key={s.id}
          color="warning"
          size="sm"
          variant="contained"
          badgeContent={s.content}
        />
      );
    } else if (s.type === "ol") {
      return (
        <ol key={s.id} style={{ marginLeft: "20px" }}>
          {s.content.map((c) => {
            return (
              <MKTypography key={c.id || c} variant="body2" fontWeight="regular">
                <li
                  style={{
                    marginTop: "10px",
                    paddingLeft: "5px",
                    // listStyle: "lower-roman",
                  }}
                >
                  {c.location ? (
                    <Link
                      href={c.location}
                      target="_blank"
                      sx={{ color: "info.main", fontWeight: "regular" }}
                    >
                      {c.content}
                    </Link>
                  ) : c.sentence ? (
                    <>
                      <strong>{c.bolded}</strong>
                      <span>{c.sentence}</span>
                      {c.note && (
                        <MKBadge
                          color="warning"
                          size="md"
                          variant="contained"
                          badgeContent={c.note}
                        />
                      )}
                    </>
                  ) : (
                    c
                  )}
                </li>
              </MKTypography>
            );
          })}
        </ol>
      );
    } else if (s.type === "ul") {
      return (
        <ul key={s.id} style={{ marginLeft: "20px" }}>
          {s.content.map((c) => {
            if (c.type === "ul") {
              return (
                <ul key={c} style={{ marginLeft: "20px" }}>
                  {c.content.map((sl) => (
                    <MKTypography key={sl} variant="body2" fontWeight="regular">
                      <li style={{ paddingLeft: "5px" }}>{sl}</li>
                    </MKTypography>
                  ))}
                </ul>
              );
            } else {
              return (
                <MKTypography key={c.id || c} variant="body2" fontWeight="regular">
                  <li
                    style={{
                      marginTop: "10px",
                      listStyle: s.noBullet ? "none" : "initial",
                      paddingLeft: "5px",
                    }}
                  >
                    {c.bolded ? (
                      <>
                        <strong>{c.bolded}</strong>
                        <span>{c.sentence}</span>
                        {c.note && (
                          <MKBadge
                            color="warning"
                            size="md"
                            variant="contained"
                            badgeContent={c.note}
                          />
                        )}
                      </>
                    ) : (
                      c
                    )}
                  </li>
                </MKTypography>
              );
            }
          })}
        </ul>
      );
    } else if (s.type === "spacer") {
      return <MKBox key={s.id} mb={s.space} />;
    } else if (s.type === "img") {
      return (
        <img
          key={s.id}
          src={s.src}
          height={s.height}
          style={{ alignSelf: s.align }}
          alt={s.id}
        ></img>
      );
    } else if (s.type === "link") {
      return (
        <MKTypography key={s.id} variant="caption" fontWeight="light" color="info" lineHeight={1.5}>
          <Link href={s.location} target="_blank">
            {s.content}
          </Link>
        </MKTypography>
      );
    } else if (s.type === "form") {
      if (s.subtype === "AddressAutocomplete") {
        return (
          <AddressAutoComplete
            key={s.id}
            value={form[s.field]}
            onChange={(v) => updateForm(s.field, v)}
            maxWidth={s.width}
          />
        );
      } else if (s.subtype === "NumberInput") {
        return (
          <MKInput
            key={s.id}
            variant="outlined"
            size={s.size || "medium"}
            name={s.field}
            label={s.label}
            sx={{
              maxWidth: s.width || "100%",
              marginLeft: s.indent,
              my: 1,
              display: "inline-block",
            }}
            value={form[s.field]}
            onChange={(e) => updateForm(s.field, e.target.value)}
            InputProps={{
              startAdornment: s.startAdornment && (
                <InputAdornment position="start">
                  <MKTypography fontSize="15px" color="info">
                    {s.startAdornment}
                  </MKTypography>
                </InputAdornment>
              ),
              endAdornment: s.endAdornment && (
                <InputAdornment position="start">
                  <MKTypography fontSize="15px" color="info">
                    {s.endAdornment}
                  </MKTypography>
                </InputAdornment>
              ),
              inputMode: "decimal",
              sx: {
                borderRadius: 2,
              },
              style: {
                border: "none !important",
              },
              inputComponent: NumberFormatCustom,
            }}
            // eslint-disable-next-line
            inputProps={{
              inputMode: "numeric",
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
      } else if (s.subtype === "TextInput") {
        return (
          <MKInput
            key={s.id}
            variant="outlined"
            type={s.textType || "text"}
            size={s.size || "medium"}
            name={s.field}
            label={s.label}
            multiline
            rows={s.rows}
            sx={{
              maxWidth: s.width || "100%",
              marginLeft: s.indent,
              my: 1,
            }}
            value={form[s.field]}
            onChange={(e) => updateForm(s.field, e.target.value)}
            InputProps={{
              startAdornment: s.startAdornment && (
                <InputAdornment position="start">
                  <MKTypography fontSize="15px" color="info">
                    {s.startAdornment}
                  </MKTypography>
                </InputAdornment>
              ),
              endAdornment: s.endAdornment && (
                <InputAdornment position="start">
                  <MKTypography fontSize="15px" color="info">
                    {s.endAdornment}
                  </MKTypography>
                </InputAdornment>
              ),
              sx: {
                borderRadius: 2,
              },
              style: {
                border: "none !important",
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
      } else if (s.subtype === "Slider") {
        return (
          <MKBox key={s.id}>
            <MKTypography
              textTransform="uppercase"
              variant="caption"
              fontWeight="bold"
              color="text"
              fontSize="0.675rem"
            >
              {s.label}
            </MKTypography>
            <Slider
              key={s.id}
              getAriaLabel={() => s.label}
              name={s.field}
              sx={{ maxWidth: s.width || "100%", mt: 2 }}
              onChange={(_, v) => updateForm(s.field, v)}
              value={form[s.field]}
              min={s.min || 0}
              max={s.max}
              step={s.step}
              valueLabelDisplay={s.valueLabelDisplay || "auto"}
              valueLabelFormat={(l) => (l === 1000000 ? `$1M+` : `$${numShortener(l)}`)}
            />
          </MKBox>
        );
      } else if (s.subtype === "Checkbox") {
        return (
          <FormControlLabel
            style={{ marginLeft: s.indent, lineHeight: s.lineHeight, display: "flex" }}
            key={s.id}
            control={
              <Checkbox
                checked={form[s.field]}
                onChange={() => updateForm(s.field, !form[s.field])}
              />
            }
            label={
              <MKTypography variant="body2" fontWeight="regular">
                {s.label}
              </MKTypography>
            }
          />
        );
      } else if (s.subtype === "Switch") {
        return (
          <FormControlLabel
            key={s.id}
            control={
              <Switch
                checked={form[s.field]}
                onChange={() => updateForm(s.field, !form[s.field])}
              />
            }
            label={
              <MKTypography variant="caption" color="text" fontWeight="regular">
                {s.label}
              </MKTypography>
            }
          />
        );
      }
    }
  });
};

export default renderer;
