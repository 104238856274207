import { useState, useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";

// @mui material components
import Grid from "@mui/material/Grid";

import Authentication from "Context/auth";

// @mui icons
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";

import { stringToNumber } from "helpers/formatters";
import AddressAutoComplete from "components/AdressAutocomple";

// fake rates for testing

const formatForm = (form) => {
  let formatted = form;
  formatted.address = form.address;
  formatted.homeValue = form.homeValue;
  formatted.desiredAmount =
    form.desiredAmount === "number" ? form.desiredAmount : stringToNumber(form.desiredAmount);
  formatted.currentDebt =
    typeof form.currentDebt === "number" ? form.currentDebt : stringToNumber(form.currentDebt);
  formatted.income = typeof form.income === "number" ? form.income : stringToNumber(form.income);
  formatted.principal =
    typeof form.principal === "number" ? form.principal : stringToNumber(form.principal);
  formatted.maxLoan =
    typeof form.maxLoan === "number" ? form.maxLoan : stringToNumber(form.maxLoan);
  formatted.rate = {
    cltv: form.rate.cltv,
    id: form.rate.id,
    apr: form.rate.apr,
    down: form.rate.down,
    downPercent: form.rate.downPercent,
    monthly:
      typeof form.rate.monthly === "number" ? form.rate.monthly : stringToNumber(form.rate.monthly),
    details: form?.rate?.details ? form.rate.details : {},
    msg: form.rate.msg,
  };
  return formatted;
};

function Page3({ nextPage, form: form3, updateForm, handleClose }) {
  const auth = useContext(Authentication);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const { value, name } = e.target;
    updateForm(name, value);
  };

  const handleAddress = (v) => {
    updateForm("address", v);
  };

  const handleContinue = async () => {
    setError(false);
    if (auth.jwt) {
      if (!form3.address.formatted) {
        setError(true);
        return;
      }
      await axios.post("/api/v1/advanced-quote-member", formatForm(form3));
      handleClose();
    } else {
      if (!form3.fullName || !form3.email || !form3.address || !form3.address.formatted) {
        setError(true);
        return;
      }
      await axios.post("/api/v1/advanced-quote", formatForm(form3));
      nextPage();
    }
  };

  return (
    <>
      <MKBox py={2} sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        <MKTypography variant="h4" color="text" fontWeight="bold" textAlign="center" mb={2}>
          {auth.jwt ? "Property Address" : "Contact Information"}
        </MKTypography>
        <MKBox sx={{ maxWidth: "450px", width: "100%" }}>
          {!auth.jwt && (
            <Grid container columnSpacing={2}>
              <Grid item xs={12}>
                <MKInput
                  label="Full Name"
                  placeholder="Full Name..."
                  fullWidth
                  size="medium"
                  name="fullName"
                  error={error && !form3.fullName}
                  type="text"
                  variant="outlined"
                  sx={{ my: 1 }}
                  value={form3.fullName}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <MKInput
                  label="Email Address"
                  placeholder="Email..."
                  variant="outlined"
                  name="email"
                  error={error && !form3.email}
                  type="email"
                  fullWidth
                  sx={{ my: 1.5 }}
                  value={form3.email}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          )}
          <AddressAutoComplete
            onChange={handleAddress}
            id="address-autocomplete"
            startingValue={form3.address}
            error={error && (!form3.address || !form3.address?.formatted)}
          />
        </MKBox>
      </MKBox>
      <MKBox display="flex" flexDirection="column" alignItems="center" pt={1} pb={5}>
        <MKButton
          size="large"
          color="primary"
          sx={{ maxWidth: "450px" }}
          fullWidth
          onClick={handleContinue}
        >
          <LockOutlinedIcon color="light" sx={{ mr: "10px" }} />
          {auth.jwt ? "Save" : "Submit Inquiry"}
        </MKButton>
      </MKBox>
    </>
  );
}

// Typechecking props for the Page3
Page3.propTypes = {
  nextPage: PropTypes.func.isRequired,
  form: PropTypes.shape({
    address: PropTypes.shape({
      formatted: PropTypes.string.isRequired,
    }),
    email: PropTypes.string,
    fullName: PropTypes.string,
  }).isRequired,
  updateForm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default Page3;
