import { useState, useEffect } from "react";
import PropTypes from "prop-types";

// @mui material components
import InputAdornment from "@mui/material/InputAdornment";
import Slider from "@mui/material/Slider";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

// @mui icons
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKBadge from "components/MKBadge";
import DistributionSlider from "components/DistributionSlider";

import constants from "../constants";

import { stringToNumber, numberToString, numShortener } from "helpers/formatters";

function Page1({ nextPage, form, updateForm }) {
  const { maxCltv, maxCltvSecondHome, maxCltvInvestment } = constants;
  const [hasSetDebt, setHasSetDebt] = useState(form.currentDebt !== null);
  const [selectedDebt, setSelectedDebt] = useState(
    form.currentDebt === null ? form.homeValue[1] * 0.4 : form.currentDebt
  );
  const [maxLoan, setMaxLoan] = useState(0);
  const [minMaxValue, setMinMaxValue] = useState([0, 2000000]);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (form.valuation.priceRangeLow) {
      if (form.valuation.priceRangeHigh) {
        setMinMaxValue([form.valuation.priceRangeLow, form.valuation.priceRangeHigh]);
      }
    }
  }, []);

  useEffect(() => {
    let appliedCLTV = maxCltv;
    if (form.propertyType === "Second Home") {
      appliedCLTV = maxCltvSecondHome;
    }
    if (form.propertyType === "Investment Property") {
      appliedCLTV = maxCltvInvestment;
    }
    /* 
    (maximum loan + existing debt) / homeValue = maxCLTV
    maximumLoan + existing debt = maxCLTV * homeValue
    maximumLoan = maxCLTV * homeValue - existing debt
    */
    setMaxLoan(
      Math.max(appliedCLTV * ((form.homeValue[0] + form.homeValue[1]) / 2) - selectedDebt, 0)
    );
  }, [form.homeValue, selectedDebt, form.propertyType]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    if (value) {
      const formattedValue = (Number(value.replace(/\D/g, "")) || "").toLocaleString();
      updateForm(name, formattedValue);
    } else {
      updateForm(name, "");
    }
    return null;
  };

  const handleValue = (_, v) => {
    updateForm("homeValue", v);
    if (!hasSetDebt) {
      setSelectedDebt(form.homeValue[1] * 0.4);
    }
  };

  const handleDebt = (_, v) => {
    setHasSetDebt(true);
    setSelectedDebt(stringToNumber(v));
  };

  const handlePropertyType = (_, v) => {
    updateForm("propertyType", v);
  };

  const handleContinue = () => {
    setError(false);
    if (!form.homeValue || !form.mortgagePayment || !form.propertyType) {
      setError(true);
      return;
    }
    if (stringToNumber(form.desiredAmount) > maxLoan) {
      updateForm("desiredAmount", maxLoan);
    }
    updateForm("currentDebt", selectedDebt);
    updateForm("maxLoan", maxLoan);
    nextPage();
  };

  return (
    <>
      <MKBox
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <MKTypography mt={2} variant="h4" color="text" fontWeight="bold" textAlign="center">
          {form.address.address_line1 || "Property Information"}
        </MKTypography>
        <MKTypography variant="body2" mt={1} mb={1} textAlign="center" maxWidth="380px">
          We&apos;ll figure out your existing debt and home equity available to back a new loan.
        </MKTypography>

        <MKBox
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            maxWidth: "420px",
            width: "100%",
            mt: 2,
          }}
        >
          <MKTypography
            textTransform="uppercase"
            variant="caption"
            fontWeight="bold"
            color="text"
            fontSize="0.675rem"
            my={0.5}
            mb={2}
            sx={{ alignSelf: "start" }}
          >
            Estimated Home Value
          </MKTypography>
          <MKBox
            p={0.25}
            mr={{ xs: 2, iframe: 0 }}
            mb={-2.25}
            ml={-6}
            sx={{
              backgroundColor: "#ffffff",
              border: "1px solid",
              borderColor: "light.main",
              borderRadius: "5px",
              alignSelf: "center",
              width: "100px",
              textAlign: "center",
              zIndex: 1000,
            }}
          >
            <MKTypography variant="body1" fontWeight="bold" color="primary" fontSize="60%">
              {`$${numShortener(form.homeValue[0])} - $${numShortener(form.homeValue[1])}`}
            </MKTypography>
          </MKBox>
          <MKBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "420px",
              width: { xs: "95%", iframe: "100%" },
              mb: 2,
            }}
          >
            <DistributionSlider
              label="Home Value"
              value={[stringToNumber(form.homeValue[0]), stringToNumber(form.homeValue[1])]}
              valueLabelDisplay="auto"
              onChange={handleValue}
              valueLabelFormat={(l) =>
                l === minMaxValue[1] ? `$${numShortener(l)}+` : `$${numShortener(l)}`
              }
              color="primary"
              name="homeValue"
              step={5000}
              disableSwap
              min={form.valuation.priceRangeLow * 1.25 || 0}
              max={form.valuation.priceRangeHigh * 0.8 || 2000000}
            />
          </MKBox>

          <MKTypography
            textTransform="uppercase"
            variant="caption"
            fontWeight="bold"
            color="text"
            fontSize="0.675rem"
            sx={{ alignSelf: "start", mb: 2.25 }}
          >
            Remaining Mortgage{" "}
            <span style={{ fontSize: "9px", fontWeight: 400 }}>
              (plus any other debt on the home)
            </span>
          </MKTypography>
          <MKBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "420px",
              width: { xs: "95%", iframe: "100%" },
              mb: 1,
            }}
          >
            <Slider
              getAriaLabel={() => "Remaining Mortgage"}
              name="currentDebt"
              onChange={handleDebt}
              className={`${error && selectedDebt !== null ? "MuiSlider-error" : ""}`}
              value={stringToNumber(selectedDebt) || 0}
              valueLabelDisplay="on"
              min={0}
              max={form.homeValue[1]}
              step={1000}
              valueLabelFormat={(l) =>
                l === form.homeValue[1] ? `$${numShortener(l)}+` : `$${numShortener(l)}`
              }
            />
          </MKBox>

          <MKInput
            label={
              <span>
                Mortgage Payment{" "}
                <span style={{ fontSize: "12px", fontWeight: 400 }}>(per month)</span>
              </span>
            }
            placeholder="Monthly payment..."
            color="primary"
            variant="outlined"
            fullWidth
            name="mortgagePayment"
            type="text"
            error={error && !form.mortgagePayment}
            sx={{ maxWidth: "420px", my: 1.5 }}
            value={numberToString(form.mortgagePayment)}
            onChange={handleChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              inputMode: "decimal",
            }}
            // eslint-disable-next-line
            inputProps={{
              inputMode: "numeric",
            }}
          />
        </MKBox>
        <MKBox
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            maxWidth: "420px",
            width: "100%",
            mb: 2,
          }}
        >
          <MKTypography
            textTransform="uppercase"
            variant="caption"
            fontWeight="bold"
            color="text"
            fontSize="0.675rem"
            my={0.5}
            sx={{ alignSelf: "start" }}
          >
            Property Use
          </MKTypography>
          <ToggleButtonGroup
            color="primary"
            sx={{
              alignItems: "center",
              mx: 0,
              px: 0,
              "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                borderColor: error && !form.propertyType ? "error.main" : "rgba(0,0,0,0.12)",
              },
              "& .Mui-selected": {
                backgroundColor: "#F6F7FF !important",
                borderColor: "#2A2CB1 !important",
              },
            }}
            exclusive
            fullWidth
            value={form.propertyType}
            onChange={handlePropertyType}
          >
            <ToggleButton
              value="Primary Residence"
              sx={{
                fontWeight: "bold",
                borderRadius: "5px !important",
                borderColor:
                  error && !form.propertyType ? "error.main" : "rgba(0,0,0,0.12) !important",
                mr: 1,
                textTransform: "none !important",
              }}
            >
              Primary <br />
              Residence
            </ToggleButton>
            <ToggleButton
              value="Second Home"
              sx={{
                fontWeight: "bold",
                borderRadius: "5px !important",
                borderColor:
                  error && !form.propertyType ? "error.main" : "rgba(0,0,0,0.12) !important",
                mr: 1,
                textTransform: "none !important",
              }}
            >
              Second <br />
              Home
            </ToggleButton>
            <ToggleButton
              value="Investment Property"
              sx={{
                fontWeight: "bold",
                borderColor:
                  error && !form.propertyType ? "error.main" : "rgba(0,0,0,0.12) !important",
                borderRadius: "5px !important",
                ml: 1,
                textTransform: "none !important",
              }}
            >
              Investment <br />
              Property
            </ToggleButton>
          </ToggleButtonGroup>
        </MKBox>
        <MKBox
          sx={{
            height: "25px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {maxLoan > 0 && form.propertyType && (
            <MKTypography variant="body5" color="text">
              🎉 You may be eligible for up to{" "}
              <MKBadge
                color="success"
                variant="contained"
                size="sm"
                badgeContent={`$${numShortener(maxLoan)}`}
              />
            </MKTypography>
          )}
        </MKBox>
        <MKButton
          size="large"
          color="primary"
          sx={{ maxWidth: "420px", mt: 2, mb: 4, width: "100%" }}
          fullWidth
          onClick={handleContinue}
        >
          <MKBox color="white" display="flex" alignItems="center">
            <LockOutlinedIcon color="light" sx={{ mr: "10px" }} />
            Continue
          </MKBox>
        </MKButton>
      </MKBox>
    </>
  );
}

// Typechecking props for the Page1
Page1.propTypes = {
  nextPage: PropTypes.func.isRequired,
  form: PropTypes.shape({
    address: PropTypes.shape({
      address_line1: PropTypes.string,
    }).isRequired,
    desiredAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    homeValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    currentDebt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mortgagePayment: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    propertyType: PropTypes.string,
    valuation: PropTypes.shape({
      price: PropTypes.number,
      priceRangeLow: PropTypes.number,
      priceRangeHigh: PropTypes.number,
      listings: PropTypes.array,
    }),
  }).isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default Page1;
