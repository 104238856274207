import getEquity from "./equityCalc";
import lenders from "../lenders/lenders";
import loanCalc from "Lenders/CalCon/calculator";

const calculateAPR = (cltv, creditScore) => {
  let rates;
  const cltvCutoffs = [0.9, 0.6, 0];
  const cltvBracket = cltvCutoffs.findIndex((n) => cltv >= n);
  switch (true) {
    case creditScore === "bad" || creditScore <= 630:
      // No rate should be returned for bad credit scores
      return -1;
    case creditScore === "fair" || creditScore < 690:
      rates = [0.1125, 0.1025, 0.0925];
      return rates[cltvBracket];
    case creditScore === "good" || creditScore < 720:
      rates = [0.1025, 0.0925, 0.0825];
      return rates[cltvBracket];
    case creditScore === "excellent" || creditScore <= 850:
      rates = [0.0925, 0.0825, 0.0725];
      return rates[cltvBracket];
    default:
      return -1;
  }
};

// const calculatePersonalLoan = (creditScore, loanAmount, months = 120) => {
//   let personalErrors = [];
//   const rates = lenders.personalProviders[0].rows;
//   if (loanAmount > 100000) {
//     personalErrors.push({ aboveMax: "Unavailable over $100k" });
//     loanAmount = 100000;
//   }
//   if (loanAmount < 5000) {
//     loanAmount = 5000;
//   }

//   const rate = rates.find((o) => o.loanAmountMin <= loanAmount && o.loanAmountMax >= loanAmount);
//   if (!rate?.cells) {
//     return { personalErrors: [{ notFound: "Rate not found" }], personalApr: -1 };
//   }

//   const monthlyRates = rate.cells;
//   const monthlyRate = monthlyRates.find((o) => o.monthsMin <= months && o.monthsMax >= months);
//   if (!monthlyRate?.max) {
//     const maxTerm = rate.cells[rate.cells.length - 1].monthsMax;
//     personalErrors.push({ maxTerm: `Unavailable for terms over ${maxTerm} mo` });
//     return {
//       personalErrors,
//       personalApr: -1,
//     };
//   }
//   const spread = monthlyRate.max - monthlyRate.min;
//   switch (true) {
//     case creditScore === "bad" || creditScore < 630:
//       return { personalErrors, personalApr: monthlyRate.max };
//     case creditScore === "fair" || creditScore < 690:
//       return { personalErrors, personalApr: monthlyRate.min + (2 / 3) * spread };
//     case creditScore === "good" || creditScore < 720:
//       return { personalErrors, personalApr: monthlyRate.min + (1 / 3) * spread };
//     case creditScore === "excellent" || creditScore <= 850:
//       return { personalErrors, personalApr: monthlyRate.min };
//     default:
//       personalErrors.push([{ error: "Credit score required" }]);
//       return {
//         personalErrors,
//         personalApr: -1,
//       };
//   }
// };

// const calculateMaxLoan = (homeEquity, creditScore) => {
//   switch (true) {
//     case creditScore === "bad" || creditScore < 630:
//       return 0;
//     case creditScore === "fair" || creditScore < 690:
//       return +(0.8 * homeEquity).toFixed(0);
//     case creditScore === "good" || creditScore < 720:
//       return +(1 * homeEquity).toFixed(0);
//     case creditScore === "excellent" || creditScore <= 850:
//       return +(1.36 * homeEquity).toFixed(0);
//     default:
//       return 0;
//   }
// };

const calculateMaxLoan = (homeValue, currentDebt, creditScore) => {
  switch (true) {
    case creditScore === "bad" || creditScore < 630:
      return +(0.85 * homeValue - currentDebt).toFixed(0);
    case creditScore === "fair" || creditScore < 690:
      return +(0.85 * homeValue - currentDebt).toFixed(0);
    case creditScore === "good" || creditScore < 720:
      return +(0.85 * homeValue - currentDebt).toFixed(0);
    case creditScore === "excellent" || creditScore <= 850:
      return +(0.85 * homeValue - currentDebt).toFixed(0);
    default:
      return 0;
  }
};

const calculateMonthlyPayment = (loanAmount, apr, months = 360, decimals = 0) => {
  const monthlyRate = apr / 12;
  const monthlyPayment =
    (loanAmount * monthlyRate * (1 + monthlyRate) ** months) / ((1 + monthlyRate) ** months - 1);
  return +monthlyPayment.toFixed(decimals);
};

const calculateTotalInterest = (loanAmount, apr, months, decimals = 0) => {
  const monthlyPayment = calculateMonthlyPayment(loanAmount, apr, months, decimals);
  const totalPayment = monthlyPayment * months;
  return totalPayment - loanAmount;
};

// Requires a creditscore (text ["bad", "fair", "good", or "excellent"]), homeValue, remainingMortgage, and optional desired loanAmount)
const getFinancing = ({ creditScore, homeValue, currentDebt, loanAmount, loanLength = 360 }) => {
  let message = "";
  let error = "";
  let maxLoan = calculateMaxLoan(homeValue, currentDebt, creditScore);
  if (maxLoan <= 0) {
    maxLoan = 0;
    message = "Unfortunately, you are not elligible for a loan";
    error = "lowCredit";
  }
  let finalLoan = maxLoan;
  // check if a loan amount was supplied
  if (loanAmount) {
    // if loan amount is above maxLoan, set a message.
    if (loanAmount > maxLoan) {
      message = `The loan amount desired exceeds the maximum loan amount of $${maxLoan.toLocaleString()}.`;
      error = "aboveMax";
    } else {
      // if not, set the finalLoan to the desired loanAmount
      finalLoan = loanAmount;
    }
    // if a loan amount is not supplied use the maximum loan amount
  }
  const cltv = (finalLoan + currentDebt) / homeValue;
  const apr = calculateAPR(cltv, creditScore);
  const monthlyPayment = calculateMonthlyPayment(finalLoan, apr, loanLength);
  const helocTerm = 240;

  let equityCalc;
  const equityTerm = 180;
  let equityMsg;
  let lowestApr = 1;
  for (var i = 0; i < lenders.equityProviders.length; i++) {
    const equityCheck = getEquity(
      homeValue,
      currentDebt,
      loanAmount,
      "California",
      equityTerm / 12,
      0.03,
      1.1,
      "min",
      lenders.equityProviders[i],
      true
    );
    if (
      equityCheck.aprAfterCap <= lowestApr &&
      equityCheck?.errors &&
      !equityCheck.errors.length > 0
    ) {
      equityCalc = equityCheck;
      equityMsg = equityCalc.errors;
    }
  }

  let secondMsg = [];
  let secondApr = 0;
  let secondMonth = 0;
  let secondCltv = 0.85;

  if (loanAmount < 150000) {
    secondMsg = [{ error: `Minimum $150k` }];
  }

  const [lowSecond] = loanCalc(
    creditScore,
    loanAmount,
    currentDebt,
    homeValue,
    "Primary Residence"
  );
  secondApr = lowSecond;
  secondMonth = calculateMonthlyPayment(loanAmount, secondApr, loanLength);

  // if no equity available, run calculations w/
  if (!equityCalc?.aprAfterCap) {
    equityCalc = getEquity(
      homeValue,
      currentDebt,
      loanAmount,
      "California",
      loanLength / 12,
      0.03,
      1.1,
      "min",
      lenders.equityProviders[0],
      true
    );
    equityMsg = equityCalc.errors;
  }

  // const { personalErrors, personalApr } = calculatePersonalLoan(
  //   creditScore,
  //   loanAmount,
  //   loanLength
  // );
  // let personalPayment = 0;
  // if (personalApr > 0) {
  //   personalPayment = calculateMonthlyPayment(loanAmount, personalApr, loanLength);
  // }

  // let refiMsg = [];
  // if (cltv > 0.9) {
  //   refiMsg = [{ error: `Unavailable for your LTV` }];
  // }

  let helocMsg = [];
  if (cltv > 0.85) {
    helocMsg = [{ error: `Unavailable for your LTV` }];
    secondMsg = [{ error: `Unavailable for your LTV` }];
  }
  if (monthlyPayment === 0) {
    helocMsg = [{ error: `Not available for your credit score` }];
  }

  const optionsArr = [
    {
      id: "second",
      down: 0,
      downPercent: 0,
      apr: secondApr,
      cltv: secondCltv,
      monthly: secondMonth,
      term: "30/15 balloon",
      msg: secondMsg,
    },
    // {
    //   id: "refi",
    //   down: 0,
    //   downPercent: 0,
    //   apr,
    //   cltv,
    //   monthly: monthlyPayment,
    //   msg: refiMsg,
    // },
    {
      id: "heloc",
      down: 0,
      downPercent: 0,
      apr,
      cltv,
      monthly: monthlyPayment,
      term: helocTerm,
      msg: helocMsg,
    },
    // {
    //   id: "reno",
    //   down: 0,
    //   downPercent: 0,
    //   apr,
    //   cltv: maxLoan / homeEquity,
    //   monthly: monthlyPayment,
    //   msg: [],
    // },
    {
      id: "equity",
      down: 0,
      downPercent: 0,
      apr: equityCalc.aprAfterCap,
      cltv: equityCalc.ltvRequirement,
      monthly: 0,
      details: equityCalc,
      term: equityTerm,
      msg: equityMsg,
    },
    // {
    //   id: "personal",
    //   down: 0,
    //   downPercent: 0,
    //   apr: personalApr ? personalApr : 0,
    //   cltv: 100000,
    //   monthly: personalPayment,
    //   msg: personalErrors,
    // },
  ];

  return {
    message,
    finalLoan,
    maxLoan,
    apr,
    monthlyPayment: monthlyPayment !== 0 ? monthlyPayment : secondMonth,
    cltv,
    homeValue,
    currentDebt,
    optionsArr,
    error,
  };
};

export { calculateMonthlyPayment, getFinancing, calculateTotalInterest };
