import { numShortener } from "helpers/formatters";

const getEquity = (
  homeValue,
  currentDebt,
  investmentAmount,
  state = "California",
  termLengthYrs = 15,
  appreciationRate = 0.03,
  aduValueMultiple = 1.5,
  originationFeeCalc = "min", // use "min", "max", "average" for calcuating origination fee
  investorObj = false,
  returnLenderInfo = false
) => {
  let returnVal = {
    lenderID: "",
    errors: [],
    ltvRequirement: 0,
    maxInvestmentAmount: 0,
    maxInvestmentShare: 0,
    originationFee: [0, 0],
    appliedOriginationFee: 0,
    totalAppreciationLenderCalc: 0,
    totalAppreciationWithAduLenderCalc: 0,
    annualAppreciationLenderCalc: 0,
    originalInvestmentPayback: 0,
    homeImprovementDeduction: 0,
    aprBeforeCap: 0,
    safetyCapCeiling: 0,
    capped: false,
    appreciationShareLenderCapped: 0,
    aprAfterCap: 0,
    totalAppreciationFeesPayback: 0,
    appreciationShareLender: 0,
    totalPaybackWithAppreciationShare: 0,
    totalPaybackPercentFutureHomeValue: 0,
    totalPaybackPercentFutureAppreciation: 0,
    lenderFeeRiskAdjustment: 0,
    homeownerAppreciationShare: 0,
    homeownerRemainingHomeShare: 0,
  };
  if (!investorObj) {
    returnVal.errors.push({
      noInvestor: "No investor specified",
    });
    return returnVal;
  }

  returnVal.lenderID = investorObj.id;
  returnVal.ltvRequirement = investorObj.oltvCap;

  if (returnLenderInfo) {
    returnVal.lenderDetails = investorObj;
  }

  // start validity checks against investorObj
  if (!investorObj.states.includes(state)) {
    returnVal.errors.push({
      locationUnavailable: `${investorObj.name} is currently not available in ${state}`,
    });
  }

  if (investmentAmount > investorObj.dollarCap) {
    returnVal.errors.push({
      exceedsDollarCap: `Unavailable over $${numShortener(investorObj.dollarCap)}`,
    });
  }

  if (investmentAmount > investorObj.homeValueCap * homeValue) {
    returnVal.errors.push({
      exceedsHomeValueCap: `Unavailable over ${investorObj.homeValueCap * 100}% home value`,
    });
  }

  if (investmentAmount > investorObj.oltvCap * homeValue - currentDebt) {
    returnVal.errors.push({
      exceedsOLTVCap: `Unavailable over ${investorObj.oltvCap * 100}% LTV`,
    });
  }

  if (termLengthYrs > investorObj.maxTermYears) {
    returnVal.errors.push({
      termTooLong: `Unavailable for terms over ${investorObj.maxTermYears} yr`,
    });
  }

  const maxInvestmentAmount = Math.max(
    Math.min(
      investorObj.oltvCap * homeValue - currentDebt,
      investorObj.homeValueCap * homeValue,
      investorObj.dollarCap
    ),
    0
  );

  returnVal.maxInvestmentAmount = maxInvestmentAmount;

  // start assumption calculations
  const aduHomeValueIncrease = investmentAmount * aduValueMultiple;
  const futureHomeValue =
    homeValue * (1 + appreciationRate) ** termLengthYrs + aduHomeValueIncrease;
  const totalAppreciationDollar = futureHomeValue - homeValue;

  const maxInvestmentShare = maxInvestmentAmount / totalAppreciationDollar;
  returnVal.maxInvestmentShare = +maxInvestmentShare.toFixed(4);

  // start loan calculations
  const originationFee = [
    investmentAmount * investorObj.originationFeeMinPercent + investorObj.originationFeeOther,
    investmentAmount * investorObj.originationFeeMaxPercent + investorObj.originationFeeOther,
  ];
  returnVal.originationFee = originationFee;
  let appliedOriginationFee;
  if (originationFeeCalc === "min") {
    appliedOriginationFee = originationFee[0];
  } else if (originationFeeCalc === "max") {
    appliedOriginationFee = originationFee[1];
  } else {
    appliedOriginationFee = (originationFee[0] + originationFee[1]) / 2;
  }

  returnVal.appliedOriginationFee = appliedOriginationFee;

  // calculate home improvementDeduction
  const homeImprovementDeduction = investorObj.homeImprovementDeduction * aduHomeValueIncrease;
  returnVal.homeImprovementDeduction = Math.round(homeImprovementDeduction);

  // calculate pre deduction payback
  const totalAppreciationLenderCalc = futureHomeValue - homeValue * investorObj.initialValueAdj;
  returnVal.totalAppreciationLenderCalc = Math.round(totalAppreciationLenderCalc);
  const totalAppreciationWithAduLenderCalc =
    totalAppreciationLenderCalc - investorObj.homeImprovementDeduction * aduHomeValueIncrease;
  returnVal.totalAppreciationWithAduLenderCalc = Math.round(totalAppreciationWithAduLenderCalc);
  returnVal.annualAppreciationLenderCalc = Math.round(
    totalAppreciationWithAduLenderCalc / termLengthYrs
  );
  let appreciationShareLender = 1;
  const originalInvestmentPayback = investorObj.originalInvestmentPayback * investmentAmount;
  returnVal.originalInvestmentPayback = originalInvestmentPayback;

  appreciationShareLender =
    (totalAppreciationWithAduLenderCalc * investorObj.appreciationShare * investmentAmount) /
      homeValue +
    (futureHomeValue * investorObj.homeShare * investmentAmount) / homeValue;
  returnVal.appreciationShareLender = Math.round(appreciationShareLender);

  // check max APR
  const aprBeforeCap = (appreciationShareLender / investmentAmount + 1) ** (1 / termLengthYrs) - 1;

  returnVal.aprBeforeCap = +aprBeforeCap.toFixed(4);

  const safetyCapCeiling =
    investorObj.aprCapMax === 0
      ? appreciationShareLender
      : investmentAmount * ((1 + investorObj.aprCapMax / 12) ** (termLengthYrs * 12) - 1);
  returnVal.safetyCapCeiling = Math.round(safetyCapCeiling);

  // handle capped APR
  const capped = safetyCapCeiling < appreciationShareLender;
  returnVal.capped = capped;
  returnVal.aprAfterCap = capped ? investorObj.aprCapMax : +aprBeforeCap.toFixed(4);
  const appreciationShareLenderCapped = capped ? safetyCapCeiling : appreciationShareLender;
  returnVal.appreciationShareLenderCapped = Math.round(appreciationShareLenderCapped);

  returnVal.totalAppreciationFeesPayback = Math.round(
    appliedOriginationFee + appreciationShareLenderCapped + originalInvestmentPayback
  );

  const totalPaybackWithAppreciationShare =
    appreciationShareLenderCapped + originalInvestmentPayback;
  returnVal.totalPaybackWithAppreciationShare = Math.round(totalPaybackWithAppreciationShare);

  returnVal.totalPaybackPercentFutureHomeValue = +(
    totalPaybackWithAppreciationShare / futureHomeValue
  ).toFixed(4);

  returnVal.totalPaybackPercentFutureAppreciation = +(
    totalPaybackWithAppreciationShare / totalAppreciationDollar
  ).toFixed(4);

  returnVal.lenderFeeRiskAdjustment = investorObj.averageRiskAdjustment * homeValue;

  returnVal.homeownerAppreciationShare = Math.round(
    totalAppreciationDollar - appreciationShareLenderCapped
  );

  returnVal.homeownerRemainingHomeShare = Math.round(
    futureHomeValue - appreciationShareLenderCapped
  );

  return returnVal;
};

export default getEquity;
