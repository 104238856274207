import { useState, useEffect, useRef, useContext } from "react";
import { useMemberstackModal } from "@memberstack/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import PropTypes from "prop-types";

import { PieChart } from "react-minimal-pie-chart";
import Authentication from "Context/auth";

import Grid from "@mui/material/Unstable_Grid2";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Slider from "@mui/material/Slider";
import InputAdornment from "@mui/material/InputAdornment";
import Chip from "@mui/material/Chip";

import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import VillaIcon from "@mui/icons-material/Villa";

// host these elsewhere in the future
import flat from "assets/images/adu/flat.svg";
import slope from "assets/images/adu/slope.svg";
import steep from "assets/images/adu/steep.svg";
import easy from "assets/images/adu/access_easy.jpeg";
import partial from "assets/images/adu/access_partial.jpeg";
import limited from "assets/images/adu/access_limited.jpeg";
import conversion from "assets/images/adu/conversion.svg";
import modular from "assets/images/adu/prefab.svg";
import siteBuilt from "assets/images/adu/siteBuilt.svg";
import studio from "assets/images/adu/adu_studio.png";
import onebed from "assets/images/adu/adu_1br.png";
import twobed from "assets/images/adu/adu_2br.png";
import threebed from "assets/images/adu/adu_3br.png";
import standard from "assets/images/adu/basic.png";
import upgraded from "assets/images/adu/upgraded.png";
import luxury from "assets/images/adu/luxe.png";
import aframe from "assets/images/adu/adu_aframe.jpeg";
import barn from "assets/images/adu/adu_barn.jpeg";
import classical from "assets/images/adu/adu_classical.jpeg";
import contemporary from "assets/images/adu/adu_contemporary.jpeg";
import craftsman from "assets/images/adu/adu_craftsman.jpeg";
import modern from "assets/images/adu/adu_modern.jpeg";
import southwest from "assets/images/adu/adu_southwest1.jpeg";
import ranch from "assets/images/adu/adu_ranch.jpeg";
import roofdeck from "assets/images/adu/addons_roofdeck.jpeg";
import deck from "assets/images/adu/addons_deck.jpeg";
import solar from "assets/images/adu/addons_solar.jpeg";
import carport from "assets/images/adu/addons_carport.jpeg";
import outdoorkitchen from "assets/images/adu/addons_outdoorkitchen.jpeg";
import sunroom from "assets/images/adu/addons_sunroom.jpeg";
import fireplace from "assets/images/adu/addons_fireplace.jpeg";
import water from "assets/images/adu/hookup_water.jpeg";
import electric from "assets/images/adu/hookup_electric.jpeg";
import gas from "assets/images/adu/hookup_gas.jpeg";

import CostTable from "./Table/index";

import useWindowSize from "Hooks/useWindowSize";

// import { ReactComponent as DwellitoLogo } from "assets/images/logos/dwellito-responsive.svg";

import { initialState } from "./form";
import { initialAssumptions } from "./assumptions";
import { costFactor, zipLookups } from "./zips";
import { calculateCosts } from "./calculator";
import { CircularProgress } from "@mui/material";

const marks = [
  {
    value: 200,
    label: "200",
  },
  {
    value: 450,
    label: "450",
  },
  {
    value: 700,
    label: "700",
  },
  {
    value: 950,
    label: "950",
  },
  {
    value: 1200,
    label: "1.2k",
  },
];

const Estimate = ({
  noRedirect,
  noFullHeight,
  noTitle,
  wideButton,
  externalForm,
  zipCode,
  externalHandleSubmit,
}) => {
  const navigate = useNavigate();
  const { openModal, hideModal } = useMemberstackModal();
  const { height } = useWindowSize();
  const [searchParams] = useSearchParams();
  const { jwt, setJwt } = useContext(Authentication);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const [hidden, setHidden] = useState(false);
  const [form, setForm] = useState(externalForm || initialState);
  const [assumptions, setAssumptions] = useState(initialAssumptions);
  const [emailError, setEmailError] = useState("");
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [costBreakdown, setCostBreakdown] = useState([]);
  const [step, setStep] = useState(0);

  const updateForm = (field, value) => {
    setForm((e) => ({
      ...e,
      [field]: value,
    }));
  };

  const updateAssumptions = (field, value) => {
    setAssumptions((e) => ({
      ...e,
      [field]: value,
    }));
  };

  const parametize = () => {
    if (searchParams.get("viewState")) {
      const viewable = searchParams.get("viewState");
      if (viewable === "hidden") {
        if (!jwt) {
          setHidden(true);
        }
      }
    }
    if (searchParams.get("basePrice")) {
      const basePrice = searchParams.get("basePrice");
      updateAssumptions("basePrice", Number(basePrice.replace(/\D/g, "")));
    }
    if (searchParams.get("sqft")) {
      const sqft = searchParams.get("sqft");
      if (sqft) {
        updateForm("sqFt", sqft);
      }
    }
    if (searchParams.get("turnkey")) {
      const turnkey = searchParams.get("turnkey");
      if (turnkey) {
        updateForm("turnkey", turnkey);
      }
    }
    if (searchParams.get("model")) {
      const model = searchParams.get("model");
      if (model) {
        updateForm("default", 1);
        updateForm("model", model);
        updateForm("buildType", 1);
      }
    }
    if (searchParams.get("bedrooms")) {
      const bedrooms = searchParams.get("bedrooms");
      if (bedrooms) {
        updateForm("bedrooms", Number(bedrooms.replace(/\D/g, "")));
      }
    }
    if (searchParams.get("bathrooms")) {
      const bathrooms = searchParams.get("bathrooms");
      if (bathrooms) {
        updateForm("bathrooms", Number(bathrooms.replace(/\D/g, "")));
      }
    }
    if (searchParams.get("quality")) {
      const quality = searchParams.get("quality");
      if (quality) {
        updateForm("quality", Number(quality.replace(/\D/g, "")));
      }
    }
  };

  useEffect(() => {
    parametize();
    if (zipCode) {
      updateForm("zip", zipCode);
    }
  }, []);

  useEffect(() => {
    if (form.default) {
      parametize();
    }
  }, [form.default]);

  useEffect(async () => {
    if (externalForm) return;
    if (jwt) {
      setHidden(false);
      try {
        const res = await axios.get("/api/v1/cost-member");
        setForm({ ...form, ...res.data });
      } catch (err) {
        console.log(err);
      }
    }
  }, [jwt]);

  useEffect(() => {
    if (form.buildType === null || form.condition === null) {
      setStep(0);
    } else if (
      form.zip &&
      form.zip.length >= 5 &&
      form.buildType !== null &&
      form.quality === null
    ) {
      setStep(1);
    } else if (form.zip && form.buildType !== null && form.quality !== null) {
      setStep(3);
    }
  }, [form.zip, form.buildType, form.quality, form.condition, hidden]);

  useEffect(() => {
    if (form.sqFtInput) return;
    if (form.default)
      if (searchParams.get("sqft")) {
        const sqft = searchParams.get("sqft");
        if (sqft) {
          updateForm("sqFt", sqft);
        }
        return;
      }
    updateForm("sqFt", 400 + 150 * form.bedrooms + 50 * form.bathrooms);
  }, [form.bedrooms, form.bathrooms]);

  useEffect(() => {
    const {
      hardCosts: hard,
      softCosts: soft,
      totalHC,
      totalSC,
      rentEstimate,
      costEstimate,
      costBreakdown: CB,
    } = calculateCosts(
      form.costFactor,
      form.sqFt,
      form.condition,
      form.buildType,
      form.bedrooms,
      form.bathrooms,
      form.quality,
      form.state,
      assumptions,
      form.default,
      form.turnkey
    );
    updateForm("hardCosts", hard);
    updateForm("softCosts", soft);
    updateForm("totalHardCosts", totalHC);
    updateForm("totalSoftCosts", totalSC);
    updateForm("totalCosts", totalHC + totalSC);
    updateForm("estimatedRent", rentEstimate);
    updateForm("estimatedCost", costEstimate);
    updateForm("amountDown", Math.round((totalHC + totalSC) * 0.2));
    updateForm("loanAmount", Math.round((totalHC + totalSC) * 0.8));
    updateForm("costPerSqFt", (totalHC + totalSC) / form.sqFt);
    setCostBreakdown(CB);
  }, [
    form.costFactor,
    form.zip,
    form.condition,
    form.buildType,
    form.sqFt,
    form.bedrooms,
    form.bathrooms,
    form.quality,
    form.default,
  ]);

  useEffect(() => {
    if (form.zip && form.zip.length === 5) {
      const numericZip = parseInt(form.zip);
      const found = zipLookups.find((l) => numericZip >= l.min && numericZip <= l.max);
      if (found) {
        updateForm("state", found.state);
        updateForm("stateName", found.name);
        let costAdjustor = costFactor[found.state];
        updateForm("costFactor", costAdjustor);
      }
    }
  }, [form.zip]);

  const handleToggle = (name, v) => {
    if (v !== null) {
      updateForm(name, v);
    }
  };

  const handleSqFtSlider = (e, v) => {
    updateForm("sqFtInput", true);
    handleSlider(e, v);
  };

  const handleSlider = (e, v) => {
    updateForm(e.target.name, v);
  };

  const handleSqFt = (e) => {
    updateForm("sqFtInput", true);
    handleInput(e);
  };

  const handleInput = (e) => {
    updateForm(e.target.name, e.target.value);
  };

  const handleGetStarted = () => {
    openModal({
      type: "SIGNUP",
      planId: "pln_test-wvc0xxf",
    })
      .then(({ data }) => {
        if (data?.member?.auth?.email) {
          const newJwt = data.tokens.accessToken;
          const member = data.member;
          setJwt(newJwt);
          setHidden(false);
          parent.postMessage(
            {
              type: "jwt",
              jwt: newJwt,
              member,
            },
            "https://www.dwellito.com"
          );
        }
        hideModal();
      })
      .catch(() => {
        hideModal();
      });
  };

  const handleSend = async () => {
    setLoading(true);
    setEmailError("");
    if (externalHandleSubmit) {
      externalHandleSubmit(form);
      return;
    } else {
      try {
        if (jwt) {
          try {
            await axios.post("/api/v1/cost-member", {
              ...form,
              assumptions: { ...assumptions },
            });
            setLoading(false);
            parent.postMessage(
              {
                type: "redirect",
                url: "/account/homeowners",
              },
              "https://www.dwellito.com"
            );
            setEmailSuccess(true);
            if (noRedirect) return;
            navigate("/homeowner");
          } catch (err) {
            console.log(err);
          }
        } else {
          if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(form.email)) {
            setLoading(false);
            setEmailError("A valid email address is required");
            return;
          }
          await axios.post("/api/v1/cost-estimate", {
            ...form,
            assumptions: { ...assumptions },
          });
          setLoading(false);
          setEmailSuccess(true);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
        setEmailError(
          "Something went wrong. Please try again or contact support if this behavior continues."
        );
      }
    }
  };

  return (
    <MKBox
      component="section"
      backgroundColor="#F9FAF9"
      display="flex"
      flexDirection="column"
      minHeight={noFullHeight ? "inherit" : `${height}px`}
      height="100%"
      maxWidth="100%"
      alignItems="center"
    >
      {!noTitle && (
        <MKBox
          xs={12}
          pt={2}
          px={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="start"
          textAlign="center"
        >
          <MKTypography variant="h1" color="text" fontWeight="regular">
            ADU Cost Calculator
          </MKTypography>
          <MKTypography variant="h4" color="info" fontWeight="light">
            The first step to a successful project is knowing your numbers
          </MKTypography>
        </MKBox>
      )}
      <Grid
        container
        display="flex"
        maxWidth="1450px"
        width="100%"
        justifyContent={step === 0 ? "center" : "start"}
        alignItems="start"
        rowSpacing={2}
        sx={{ px: { xs: 0, sm: 2 } }}
        py={2.5}
      >
        <Grid item xs={12} xl={wideButton ? 12 : step === 0 ? 6 : 4} justifySelf="start">
          <MKBox sx={{ px: { xs: 2, md: 1 } }}>
            <MKBox
              style={{
                border: "1px solid #ddd",
                borderRadius: "5px",
                backgroundColor: "#f9f9f9",
              }}
              p={2.5}
              pb={5}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MKTypography variant="h4" textAlign="left">
                    Tell Us About Your Backyard
                  </MKTypography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  mt={-1.5}
                  display="flex"
                  flexDirection="column"
                  alignItems="start"
                >
                  <MKTypography variant="h6" color="text">
                    Zip Code
                  </MKTypography>
                  <MKBox display="flex" alignItems="center" flexDirection="row">
                    <MKInput
                      size="medium"
                      InputProps={{
                        style: {
                          maxWidth: "120px",
                          letterSpacing: "6px",
                          paddingLeft: "12px",
                        },
                      }}
                      name="zip"
                      onChange={handleInput}
                      value={form.zip}
                      error={!form.zip && form.buildType !== null}
                      inputProps={{
                        minLength: "5",
                        maxLength: "5",
                        inputMode: "numeric",
                        style: { fontSize: "15px" },
                      }}
                    />
                    {form.stateName && (
                      <Chip
                        icon={<VillaIcon />}
                        sx={{
                          fontSize: "14px",
                          px: "15px",
                          color: "primary.main",
                          height: "50px",
                          fontWeight: "bold",
                          backgroundColor: "light.main",
                          ml: 2,
                        }}
                        size="medium"
                        color="primary"
                        label={`${form.stateName}`}
                      />
                    )}
                  </MKBox>
                  <MKTypography variant="body2" mt={1}>
                    Building and permitting costs vary by location
                  </MKTypography>
                </Grid>
                <Grid item xs={12} display="flex" flexDirection="column">
                  <MKTypography variant="h6" color="text">
                    What is your desired construction type?
                  </MKTypography>
                  <ToggleButtonGroup
                    disabled={!!form.default}
                    color="primary"
                    sx={{
                      alignItems: "center",
                      mx: 0,
                      height: "100%",
                      px: 0,
                      "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                        borderColor: "rgba(0,0,0,0.12) ",
                      },
                      "& .Mui-selected": {
                        backgroundColor: "#dfe3ff !important",
                        borderColor: "#2A2CB1 !important",
                      },
                    }}
                    value={form.buildType}
                    exclusive
                    onChange={(_, v) => handleToggle("buildType", v)}
                    fullWidth
                  >
                    <ToggleButton
                      value={0}
                      name="buildType"
                      sx={{
                        p: 0.5,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        marginRight: { xs: 0.25, md: 1 },
                        borderRadius: "5px !important",
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "start",
                        pb: 1,
                      }}
                    >
                      <img
                        src={conversion}
                        alt="Conversion Construction"
                        width="100%"
                        style={{ height: "100%", marginBottom: "5px" }}
                      />
                      Conversion
                      <MKTypography variant="caption" display="block" textAlign="center">
                        Convert an existing garage, attic, or basement.
                      </MKTypography>
                    </ToggleButton>
                    <ToggleButton
                      value={1}
                      name="buildType"
                      sx={{
                        p: 0.5,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        marginRight: { xs: 0.25, md: 1 },
                        borderRadius: "5px !important",
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "start",
                        pb: 1,
                      }}
                    >
                      <img
                        src={modular}
                        alt="Modular Construction"
                        width="100%"
                        style={{ height: "100%", marginBottom: "5px" }}
                      />
                      Modular
                      <MKTypography variant="caption" display="block" textAlign="center">
                        Assemble an ADU that is built off-site and shipped to your curb.
                      </MKTypography>
                    </ToggleButton>
                    <ToggleButton
                      value={2}
                      name="buildType"
                      sx={{
                        p: 0.5,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        borderRadius: "5px !important",
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "start",
                        pb: 1,
                      }}
                    >
                      <img
                        src={siteBuilt}
                        alt="Site Built Construction"
                        height="100%"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      Site Built
                      <MKTypography variant="caption" display="block" textAlign="center">
                        Build an ADU from the ground-up on location.
                      </MKTypography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} display="flex" flexDirection="column">
                  <MKTypography variant="h6" color="text">
                    What are your property conditions?
                  </MKTypography>
                  <ToggleButtonGroup
                    color="primary"
                    sx={{
                      alignItems: "center",
                      mx: 0,
                      px: 0,
                      height: "90%",
                      "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                        borderColor: "rgba(0,0,0,0.12) ",
                      },
                      "& .Mui-selected": {
                        backgroundColor: "#dfe3ff !important",
                        borderColor: "#2A2CB1 !important",
                      },
                    }}
                    value={form.condition}
                    exclusive
                    onChange={(_, v) => handleToggle("condition", v)}
                    fullWidth
                  >
                    <ToggleButton
                      value={0}
                      name="condition"
                      sx={{
                        p: 0.5,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        py: 1,
                      }}
                    >
                      <img
                        src={flat}
                        alt="Flat Slope"
                        name="condition"
                        width="100%"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      Flat
                      <MKTypography variant="caption">
                        Site is flat and nearly level and ready for a foundation.
                      </MKTypography>
                    </ToggleButton>
                    <ToggleButton
                      value={1}
                      name="condition"
                      sx={{
                        p: 0.5,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        py: 1,
                      }}
                    >
                      <img
                        src={slope}
                        alt="Gentle Slope"
                        name="condition"
                        width="100%"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      Some Slope
                      <MKTypography variant="caption">
                        Site has minimal slope that can be relatively easily levelled.
                      </MKTypography>
                    </ToggleButton>
                    <ToggleButton
                      value={2}
                      name="condition"
                      sx={{
                        p: 0.5,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        borderRadius: "5px !important",
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        py: 1,
                      }}
                    >
                      <img
                        src={steep}
                        alt="Steep Slope"
                        name="condition"
                        width="100%"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      Large Slope
                      <MKTypography variant="caption">
                        Site has significant slope that requires machinery for leveling.
                      </MKTypography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} display="flex" flexDirection="column">
                  <MKTypography variant="h6" color="text">
                    How easy is it to access the building site with heavy machinery?
                  </MKTypography>
                  <ToggleButtonGroup
                    color="primary"
                    name="access"
                    sx={{
                      alignItems: "center",
                      mx: 0,
                      px: 0,
                      height: "90%",
                      "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                        borderColor: "rgba(0,0,0,0.12) ",
                      },
                      "& .Mui-selected": {
                        backgroundColor: "#dfe3ff !important",
                        borderColor: "#2A2CB1 !important",
                      },
                    }}
                    value={form.access}
                    exclusive
                    onChange={(_, v) => handleToggle("access", v)}
                    fullWidth
                  >
                    <ToggleButton
                      value={0}
                      name="access"
                      sx={{
                        p: 0,
                        pb: 1,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <img
                        src={easy}
                        alt="Easy Access"
                        name="condition"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      Easy Access
                      <MKTypography variant="caption">
                        The building site can be accessed directly via a road/driveway.
                      </MKTypography>
                    </ToggleButton>
                    <ToggleButton
                      value={1}
                      name="access"
                      sx={{
                        p: 0,
                        pb: 1,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <img
                        src={partial}
                        alt="Partial Access"
                        name="condition"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      Partial Access
                      <MKTypography variant="caption">
                        Access is partly limited by landscaping or existing structures.
                      </MKTypography>
                    </ToggleButton>
                    <ToggleButton
                      value={2}
                      name="access"
                      sx={{
                        p: 0,
                        pb: 1,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        borderRadius: "5px !important",
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <img
                        src={limited}
                        alt="Limited Access"
                        name="condition"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      Limited Access
                      <MKTypography variant="caption">
                        Access is fully limited by landscaping or existing structures.
                      </MKTypography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </MKBox>
          </MKBox>
        </Grid>
        <Grid
          item
          xs={12}
          xl={wideButton ? 12 : 4}
          sx={{ display: wideButton ? "block" : step === 0 ? "none" : "block" }}
        >
          <MKBox sx={{ px: { xs: 2, md: 1 } }}>
            <MKBox
              ref={ref1}
              style={{
                border: "1px solid #ddd",
                borderRadius: "5px",
                backgroundColor: "#f9f9f9",
              }}
              p={2.5}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MKTypography variant="h4" textAlign="left">
                    Build Your ADU
                  </MKTypography>
                </Grid>
                {form.model && (
                  <Grid item xs={12}>
                    <ToggleButtonGroup
                      color="primary"
                      sx={{
                        alignItems: "center",
                        mx: 0,
                        px: 0,
                        "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                          borderColor: "rgba(0,0,0,0.12) ",
                        },
                        "& .Mui-selected": {
                          backgroundColor: "#dfe3ff !important",
                          borderColor: "#2A2CB1 !important",
                        },
                      }}
                      value={form.default}
                      exclusive
                      onChange={(_, v) => handleToggle("default", v)}
                      fullWidth
                    >
                      <ToggleButton
                        value={1}
                        name="condition"
                        sx={{
                          p: 0.5,
                          py: 2,
                          fontWeight: "normal",
                          fontSize: "16px",
                          borderColor: "rgba(0,0,0,0.12) !important",
                          borderRadius: "5px !important",
                          marginRight: { xs: 0.25, md: 1 },
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          textTransform: "capitalize !important",
                        }}
                      >
                        {form.model} Default
                      </ToggleButton>
                      <ToggleButton
                        value={0}
                        name="condition"
                        sx={{
                          p: 0.5,
                          py: 2,
                          fontWeight: "normal",
                          fontSize: "16px",
                          borderColor: "rgba(0,0,0,0.12) !important",
                          textTransform: "none !important",
                          borderRadius: "5px !important",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Explore Alternatives
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                )}
                <Grid item xs={12} display="flex" flexDirection="column">
                  <MKBox display="flex" flexDirection="Row" alignItems="center">
                    <MKTypography variant="h6" color="text">
                      Desired Architectural Style
                    </MKTypography>
                  </MKBox>
                  <ToggleButtonGroup
                    disabled={!!form.default}
                    color="primary"
                    sx={{
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "start",
                      mx: 0,
                      px: 0,
                      "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                        borderColor: "rgba(0,0,0,0.12) ",
                      },
                      "& .MuiToggleButtonGroup-grouped:first-of-type": {
                        marginLeft: "-1px !important",
                      },
                      "& .Mui-selected": {
                        backgroundColor: "#dfe3ff !important",
                        border: "1px solid #2A2CB1 !important",
                        marginLeft: "-1px !important",
                      },
                    }}
                    value={form.style}
                    exclusive
                    orientation="horizontal"
                    onChange={(_, v) => handleToggle("style", v)}
                    fullWidth
                  >
                    <ToggleButton
                      value="aframe"
                      name="style"
                      sx={{
                        maxWidth: "135px",
                        width: "100%",
                        p: 0,
                        mb: 0.5,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        textTransform: "none !important",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={aframe}
                        name="style"
                        alt="A-Frame"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      <MKBox
                        height="30px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        A Frame
                      </MKBox>
                    </ToggleButton>
                    <ToggleButton
                      value="barn"
                      name="style"
                      sx={{
                        maxWidth: "135px",
                        width: "100%",
                        p: 0,
                        mb: 0.5,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        borderSize: "1px",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        textTransform: "none !important",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={barn}
                        name="style"
                        alt="Barn"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      <MKBox
                        height="30px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Barn
                      </MKBox>
                    </ToggleButton>
                    <ToggleButton
                      value="classical"
                      name="style"
                      sx={{
                        maxWidth: "135px",
                        width: "100%",
                        p: 0,
                        mb: 0.5,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        borderSize: "1px",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        textTransform: "none !important",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={classical}
                        name="style"
                        alt="Classical"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      <MKBox
                        height="30px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Classical
                      </MKBox>
                    </ToggleButton>
                    <ToggleButton
                      value="contemporary"
                      name="style"
                      sx={{
                        maxWidth: "135px",
                        width: "100%",
                        p: 0,
                        mb: 0.5,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        borderSize: "1px",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        textTransform: "none !important",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={contemporary}
                        name="style"
                        alt="Contemporary"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      <MKBox
                        height="30px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Contemporary
                      </MKBox>
                    </ToggleButton>
                    <ToggleButton
                      value="craftsman"
                      name="style"
                      sx={{
                        maxWidth: "135px",
                        width: "100%",
                        p: 0,
                        mb: 0.5,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        borderSize: "1px",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        textTransform: "none !important",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={craftsman}
                        name="style"
                        alt="Craftsman"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      <MKBox
                        height="30px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Craftsman
                      </MKBox>
                    </ToggleButton>
                    <ToggleButton
                      value="ranch"
                      name="style"
                      sx={{
                        maxWidth: "135px",
                        width: "100%",
                        p: 0,
                        mb: 0.5,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        borderSize: "1px",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        textTransform: "none !important",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={ranch}
                        name="style"
                        alt="Ranch"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      <MKBox
                        height="30px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Ranch
                      </MKBox>
                    </ToggleButton>
                    <ToggleButton
                      value="southwest"
                      name="style"
                      sx={{
                        maxWidth: "135px",
                        width: "100%",
                        p: 0,
                        mb: 0.5,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        borderSize: "1px",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        textTransform: "none !important",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={southwest}
                        name="style"
                        alt="Southwest"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      <MKBox
                        height="30px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Southwest
                      </MKBox>
                    </ToggleButton>
                    <ToggleButton
                      value="modern"
                      name="style"
                      sx={{
                        maxWidth: "135px",
                        width: "100%",
                        p: 0,
                        mb: 0.5,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        borderSize: "1px",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        textTransform: "none !important",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={modern}
                        name="style"
                        alt="Modern"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      <MKBox
                        height="30px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Modern
                      </MKBox>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12} mt={-1.5} display="flex" flexDirection="column">
                  <MKTypography variant="h6" color="text">
                    Bedrooms
                  </MKTypography>

                  <ToggleButtonGroup
                    disabled={!!form.default}
                    color="primary"
                    sx={{
                      alignItems: "center",
                      height: "90%",
                      mx: 0,
                      px: 0,
                      "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                        borderColor: "rgba(0,0,0,0.12) ",
                      },
                      "& .Mui-selected": {
                        backgroundColor: "#dfe3ff !important",
                        borderColor: "#2A2CB1 !important",
                      },
                    }}
                    value={form.bedrooms}
                    exclusive
                    onChange={(_, v) => handleToggle("bedrooms", v)}
                    fullWidth
                  >
                    <ToggleButton
                      value={0}
                      name="bedrooms"
                      sx={{
                        fontWeight: "normal",
                        height: "100%",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        display: "flex",
                        marginRight: { xs: 0.25, md: 1 },
                        flexDirection: "column",
                        borderRadius: "5px !important",
                        p: 0,
                        pb: 0.5,
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "100%",
                      }}
                    >
                      <img
                        src={studio}
                        name="bedrooms"
                        alt="Studio"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      <MKBox
                        height="40px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Studio
                      </MKBox>
                    </ToggleButton>
                    <ToggleButton
                      value={1}
                      name="bedrooms"
                      sx={{
                        fontWeight: "normal",
                        height: "100%",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        display: "flex",
                        marginRight: { xs: 0.25, md: 1 },
                        flexDirection: "column",
                        borderRadius: "5px !important",
                        p: 0,
                        pb: 0.5,
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "100%",
                      }}
                    >
                      <img
                        src={onebed}
                        name="bedrooms"
                        alt="1 Bedroom"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      <MKBox
                        height="40px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        1 Bedroom
                      </MKBox>
                    </ToggleButton>
                    <ToggleButton
                      value={2}
                      name="bedrooms"
                      sx={{
                        fontWeight: "normal",
                        height: "100%",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        display: "flex",
                        marginRight: { xs: 0.25, md: 1 },
                        flexDirection: "column",
                        borderRadius: "5px !important",
                        p: 0,
                        pb: 0.5,
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "100%",
                      }}
                    >
                      <img
                        src={twobed}
                        name="bedrooms"
                        alt="2 Bedroom"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      <MKBox
                        height="40px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        2 Bedroom
                      </MKBox>
                    </ToggleButton>
                    <ToggleButton
                      value={3}
                      name="bedrooms"
                      sx={{
                        fontWeight: "normal",
                        height: "100%",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "5px !important",
                        p: 0,
                        pb: 0.5,
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "100%",
                      }}
                    >
                      <img
                        src={threebed}
                        name="bedrooms"
                        alt="3 Bedroom"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      <MKBox
                        height="40px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        3 Bedroom
                      </MKBox>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} display="flex" flexDirection="column">
                  <MKTypography variant="h6" color="text">
                    Bathrooms
                  </MKTypography>
                  <ToggleButtonGroup
                    disabled={!!form.default}
                    color="primary"
                    sx={{
                      alignItems: "center",
                      height: "90%",
                      mx: 0,
                      px: 0,
                      "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                        borderColor: "rgba(0,0,0,0.12) ",
                      },
                      "& .Mui-selected": {
                        backgroundColor: "#dfe3ff !important",
                        borderColor: "#2A2CB1 !important",
                      },
                    }}
                    value={form.bathrooms}
                    exclusive
                    onChange={(_, v) => handleToggle("bathrooms", v)}
                    fullWidth
                  >
                    <ToggleButton
                      value={0}
                      name="bathrooms"
                      sx={{
                        fontWeight: "normal",
                        height: "100%",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        display: "flex",
                        marginRight: { xs: 0.25, md: 1 },
                        borderRadius: "5px !important",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      0 Bath
                    </ToggleButton>
                    <ToggleButton
                      value={1}
                      name="bathrooms"
                      sx={{
                        fontWeight: "normal",
                        height: "100%",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      1 Bath
                    </ToggleButton>
                    <ToggleButton
                      value={2}
                      name="bathrooms"
                      sx={{
                        fontWeight: "normal",
                        height: "100%",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        display: "flex",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      2 Bath
                    </ToggleButton>
                    <ToggleButton
                      value={3}
                      name="bathrooms"
                      sx={{
                        fontWeight: "normal",
                        height: "100%",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        display: "flex",
                        borderRadius: "5px !important",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      3 Bath
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} display="flex" flexDirection="column">
                  <MKTypography variant="h6" color="text">
                    Kitchen
                  </MKTypography>
                  <ToggleButtonGroup
                    disabled={!!form.default}
                    color="primary"
                    sx={{
                      alignItems: "center",
                      height: "90%",
                      mx: 0,
                      px: 0,
                      "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                        borderColor: "rgba(0,0,0,0.12) ",
                      },
                      "& .Mui-selected": {
                        backgroundColor: "#dfe3ff !important",
                        borderColor: "#2A2CB1 !important",
                      },
                    }}
                    value={form.kitchen}
                    exclusive
                    onChange={(_, v) => handleToggle("kitchen", v)}
                    fullWidth
                  >
                    <ToggleButton
                      value={0}
                      name="kitchen"
                      sx={{
                        fontWeight: "normal",
                        height: "100%",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        display: "flex",
                        marginRight: { xs: 0.25, md: 1 },
                        borderRadius: "5px !important",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      None
                    </ToggleButton>
                    <ToggleButton
                      value={1}
                      name="kitchen"
                      sx={{
                        fontWeight: "normal",
                        height: "100%",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      Kitchenette
                    </ToggleButton>
                    <ToggleButton
                      value={2}
                      name="kitchen"
                      sx={{
                        fontWeight: "normal",
                        height: "100%",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        display: "flex",
                        borderRadius: "5px !important",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      Full Kitchen
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12}>
                  <MKTypography variant="h6" color="text">
                    Square Footage
                  </MKTypography>
                  <MKBox display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                    <MKInput
                      disabled={!!form.default}
                      value={form.sqFt}
                      name="sqFt"
                      size="small"
                      onChange={handleSqFt}
                      sx={{ mr: 3 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <MKTypography fontSize="15px" color="info">
                              ft²
                            </MKTypography>
                          </InputAdornment>
                        ),
                        inputMode: "numeric",
                        style: { width: "100px" },
                      }}
                      inputProps={{ style: { fontSize: "15px" } }}
                    />
                    <Slider
                      disabled={!!form.default}
                      getAriaLabel={() => "Sq Footage"}
                      name="sqFt"
                      sx={{
                        maxWidth: "400px",
                        minWidth: "100px",
                        mb: 1.5,
                        "& .MuiSlider-track": { height: "6px" },
                      }}
                      onChange={handleSqFtSlider}
                      marks={marks}
                      value={form.sqFt}
                      min={200}
                      max={1200}
                      step={25}
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12}>
                  <MKTypography variant="h6" color="text">
                    Build Quality
                  </MKTypography>
                  <ToggleButtonGroup
                    disabled={!!form.default}
                    color="primary"
                    sx={{
                      alignItems: "center",
                      mx: 0,
                      px: 0,
                      "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                        borderColor: "rgba(0,0,0,0.12) ",
                      },
                      "& .Mui-selected": {
                        backgroundColor: "#dfe3ff !important",
                        borderColor: "#2A2CB1 !important",
                      },
                    }}
                    value={form.quality}
                    exclusive
                    onChange={(_, v) => handleToggle("quality", v)}
                    fullWidth
                  >
                    <ToggleButton
                      value={0}
                      name="quality"
                      sx={{
                        p: 0,
                        pb: 1,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        textTransform: "none !important",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={standard}
                        name="quality"
                        alt="Standard"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      Standard
                      <MKTypography variant="caption">
                        Limited glass, mid-range bathroom & kitchen finishes.
                      </MKTypography>
                    </ToggleButton>
                    <ToggleButton
                      value={1}
                      name="quality"
                      sx={{
                        p: 0,
                        pb: 1,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        textTransform: "none !important",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={upgraded}
                        name="quality"
                        alt="Upgraded"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      Upgraded
                      <MKTypography variant="caption">
                        Long spans, more glass, high-end materials & finishes.
                      </MKTypography>
                    </ToggleButton>
                    <ToggleButton
                      value={2}
                      name="quality"
                      sx={{
                        p: 0,
                        pb: 1,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        borderRadius: "5px !important",
                        textTransform: "none !important",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={luxury}
                        name="quality"
                        alt="Luxury"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      Luxury
                      <MKTypography variant="caption">
                        Large glass expanses, custom high-end details & finishes.
                      </MKTypography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} display="flex" flexDirection="column">
                  <MKBox display="flex" flexDirection="Row" alignItems="center">
                    <MKTypography variant="h6" color="text">
                      Popular Add-Ons
                    </MKTypography>
                    <MKTypography variant="caption" ml={1}>
                      (Select as many as desired)
                    </MKTypography>
                  </MKBox>
                  <ToggleButtonGroup
                    disabled={!!form.default}
                    color="primary"
                    sx={{
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "start",
                      mx: 0,
                      marginLeft: "-1px !important",
                      px: 0,
                      "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                        borderColor: "rgba(0,0,0,0.12) ",
                      },
                      "& .MuiToggleButtonGroup-grouped:first-of-type": {
                        marginLeft: "-1px !important",
                      },
                      "& .Mui-selected": {
                        backgroundColor: "#dfe3ff !important",
                        border: "1px solid #2A2CB1 !important",
                        marginLeft: "-1px !important",
                      },
                    }}
                    value={form.addons}
                    orientation="horizontal"
                    onChange={(_, v) => handleToggle("addons", v)}
                    fullWidth
                  >
                    <ToggleButton
                      value="carport"
                      name="addons"
                      sx={{
                        maxWidth: "135px",
                        width: "100%",
                        p: 0,
                        mb: 0.5,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        borderSize: "1px !important",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        textTransform: "none !important",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={carport}
                        name="addons"
                        alt="Addons Carport"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      <MKBox
                        height="40px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Carport / Garage
                      </MKBox>
                    </ToggleButton>
                    <ToggleButton
                      value="deck"
                      name="addons"
                      sx={{
                        maxWidth: "135px",
                        width: "100%",
                        p: 0,
                        mb: 0.5,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        borderSize: "1px !important",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        textTransform: "none !important",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={deck}
                        name="addons"
                        alt="Addons Deck"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      <MKBox
                        height="40px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Deck
                      </MKBox>
                    </ToggleButton>
                    <ToggleButton
                      value="fireplace"
                      name="addons"
                      sx={{
                        maxWidth: "135px",
                        width: "100%",
                        p: 0,
                        mb: 0.5,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        borderSize: "1px",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        textTransform: "none !important",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={fireplace}
                        name="addons"
                        alt="Addons Fireplace"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      <MKBox
                        height="40px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Fireplace
                      </MKBox>
                    </ToggleButton>
                    <ToggleButton
                      value="outdoor_kitchen"
                      name="addons"
                      sx={{
                        maxWidth: "135px",
                        width: "100%",
                        p: 0,
                        mb: 0.5,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        borderSize: "1px !important",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        textTransform: "none !important",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={outdoorkitchen}
                        name="addons"
                        alt="Addons Outdoor Kitchen"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      <MKBox
                        height="40px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Outdoor Kitchen
                      </MKBox>
                    </ToggleButton>
                    <ToggleButton
                      value="roof_deck"
                      name="addons"
                      sx={{
                        maxWidth: "135px",
                        width: "100%",
                        p: 0,
                        mb: 0.5,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        borderSize: "1px",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        textTransform: "none !important",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={roofdeck}
                        name="addons"
                        alt="Addons Roofdeck"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      <MKBox
                        height="40px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Roof Deck
                      </MKBox>
                    </ToggleButton>
                    <ToggleButton
                      value="solar"
                      name="addons"
                      sx={{
                        maxWidth: "135px",
                        width: "100%",
                        p: 0,
                        mb: 0.5,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        borderSize: "1px",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        textTransform: "none !important",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={solar}
                        name="addons"
                        alt="Addons Solar"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      <MKBox
                        height="40px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Solar Panels
                      </MKBox>
                    </ToggleButton>
                    <ToggleButton
                      value="sunroom"
                      name="addons"
                      sx={{
                        maxWidth: "135px",
                        width: "100%",
                        p: 0,
                        mb: 0.5,
                        fontWeight: "normal",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        borderSize: "1px",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        textTransform: "none !important",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={sunroom}
                        name="addons"
                        alt="Addons Sunroom"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      <MKBox
                        height="40px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Sunroom
                      </MKBox>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12} display="flex" flexDirection="column">
                  <MKBox display="flex" flexDirection="row" alignItems="center">
                    <MKTypography variant="h6" color="text">
                      Utility Hookups
                    </MKTypography>
                    <MKTypography variant="caption" ml={1}>
                      (Select all that apply)
                    </MKTypography>
                  </MKBox>

                  <ToggleButtonGroup
                    disabled={!!form.default}
                    color="primary"
                    sx={{
                      alignItems: "center",
                      height: "90%",
                      mx: 0,
                      px: 0,
                      "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                        borderColor: "rgba(0,0,0,0.12) ",
                      },
                      "& .MuiToggleButtonGroup-grouped:first-of-type": {
                        marginLeft: "-1px !important",
                      },
                      "& .Mui-selected": {
                        backgroundColor: "#dfe3ff !important",
                        border: "1px solid #2A2CB1 !important",
                        marginLeft: "-1px !important",
                      },
                    }}
                    value={form.utilities}
                    onChange={(_, v) => handleToggle("utilities", v)}
                    fullWidth
                  >
                    <ToggleButton
                      value="electric"
                      name="utilities"
                      sx={{
                        fontWeight: "normal",
                        height: "100%",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        p: 0,
                        pb: 0.5,
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "100%",
                      }}
                    >
                      <img
                        src={electric}
                        name="addons"
                        alt="Hookup Electricity"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      <MKBox
                        height="40px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Electricy
                      </MKBox>
                    </ToggleButton>
                    <ToggleButton
                      value="water_sewer"
                      name="utilities"
                      sx={{
                        fontWeight: "normal",
                        height: "100%",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "5px !important",
                        marginRight: { xs: 0.25, md: 1 },
                        p: 0,
                        pb: 0.5,
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "100%",
                      }}
                    >
                      <img
                        src={water}
                        name="addons"
                        alt="Hookup Water & Sewer"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      <MKBox
                        height="40px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Water & Sewer
                      </MKBox>
                    </ToggleButton>

                    <ToggleButton
                      value="gas"
                      name="utilities"
                      sx={{
                        fontWeight: "normal",
                        height: "100%",
                        fontSize: "16px",
                        borderColor: "rgba(0,0,0,0.12) !important",
                        textTransform: "none !important",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "5px !important",
                        p: 0,
                        pb: 0.5,
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "100%",
                      }}
                    >
                      <img
                        src={gas}
                        name="addons"
                        alt="Hookup Natural Gas"
                        style={{ maxWidth: "100%", marginBottom: "5px" }}
                      />
                      <MKBox
                        height="40px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Natural Gas
                      </MKBox>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </MKBox>
          </MKBox>
        </Grid>
        <Grid
          item
          xs={12}
          xl={wideButton ? 12 : 4}
          sx={{ display: wideButton ? "block" : step === 0 ? "none" : "block" }}
        >
          <MKBox sx={{ px: { xs: 2, md: 1 } }}>
            <MKBox
              ref={ref2}
              style={{
                border: "1px solid #ddd",
                borderRadius: "5px",
                backgroundColor: "#f9f9f9",
                position: "relative",
              }}
              p={2.5}
            >
              {hidden && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 100,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backdropFilter: "blur(5px)",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <MKBox
                    sx={{
                      p: 3,
                      width: "85%",
                      maxWidth: "320px",
                      textAlign: "center",
                      backgroundColor: "#fafafa",
                      borderRadius: 2,
                      border: "1px solid",
                      borderColor: "light.main",
                      boxShadow: 3,
                    }}
                  >
                    <MKTypography variant="h3">Free Instant Access</MKTypography>
                    <img src={luxury} name="luxury" alt="luxury ADU" style={{ maxWidth: "60%" }} />
                    <MKTypography variant="body2">
                      Sign up for a Dwellito account to view and save ADU cost estimates and get
                      free access to other great tools
                    </MKTypography>
                    <MKButton
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      onClick={handleGetStarted}
                      sx={{ height: "50px", fontSize: "18px", textTransform: "uppercase", mt: 1 }}
                      fullWidth
                    >
                      Sign Up Free
                      {loading && "..."}
                    </MKButton>
                  </MKBox>
                </div>
              )}
              <Grid container spacing={1}>
                <Grid item xs={12} alignItems="start" display="flex" flexDirection="column">
                  <MKTypography variant="h4" textAlign="left" style={{ zIndex: 1000 }}>
                    Your ADU Cost Estimate
                  </MKTypography>
                </Grid>

                <Grid item xs={12} style={{ position: "relative" }}>
                  <CostTable
                    hardCosts={form.hardCosts}
                    softCosts={form.softCosts}
                    totalHC={form.totalHardCosts}
                    totalSC={form.totalSoftCosts}
                    totalCosts={form.totalCosts}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKBox
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    mt={1.25}
                    mb={1}
                  >
                    <MKTypography variant="h6" color="text" mr={1}>
                      Cost per ft²:
                    </MKTypography>
                    <Chip
                      sx={{
                        fontSize: "1rem",
                        px: "0px",
                        width: "140px",
                        color: "text.main",
                        height: "45px",
                        fontWeight: "bold",
                        backgroundColor: "primary.light",
                        border: "1px solid",
                        borderColor: "light.main",
                      }}
                      size="medium"
                      color="info"
                      label={`$${((form.totalCosts * 0.85) / form.sqFt).toFixed(0)} ― $${(
                        (form.totalCosts * 1.15) /
                        form.sqFt
                      ).toFixed(0)}`}
                    />
                  </MKBox>
                </Grid>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  flexDirection="column"
                  mt={1}
                  sx={{
                    mx: 0.5,
                    p: 2,
                    pb: "2px",
                    border: "1px solid",
                    borderColor: "light.main",
                    borderRadius: "5px",
                    backgroundColor: "light.lighter",
                  }}
                >
                  <MKBox
                    pt={1}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <MKBox height="75px">
                      <PieChart
                        data={costBreakdown}
                        startAngle={181}
                        lengthAngle={181}
                        viewBoxSize={[100, 50]}
                        lineWidth={25}
                        radius={50}
                      />
                    </MKBox>
                    <MKBox ml={1.5} width="110px">
                      {costBreakdown.map((c) => (
                        <MKBox
                          key={c.title}
                          width="100%"
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <div
                            style={{
                              height: "12px",
                              width: "12px",
                              backgroundColor: c.color,
                            }}
                          />
                          <MKTypography variant="body2" fontSize="12px" ml={0.25}>{`${
                            c.title
                          }: $${c.value.toLocaleString("en", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}`}</MKTypography>
                        </MKBox>
                      ))}
                    </MKBox>
                  </MKBox>
                  <MKBox
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    mb={-1}
                    mt={1}
                  >
                    <MKTypography variant="h5" color="primary" mr={2}>
                      Finance for as low as:
                    </MKTypography>
                    <MKTypography variant="h5" fontWeight="regular" width="100px">
                      {`$${form.estimatedCost.toLocaleString("en", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}`}
                      /mo.
                    </MKTypography>
                  </MKBox>
                  <MKBox alignSelf="center" width="300px">
                    <MKTypography variant="body2" textAlign="center" fontSize="10px" mt={1}>
                      *Assumes a 30-year mortgage at 6.5% APR and a 20% down payment
                    </MKTypography>
                  </MKBox>
                </Grid>
              </Grid>
            </MKBox>
          </MKBox>
        </Grid>
        {wideButton ? (
          <MKBox
            display="flex"
            flexDirection="column"
            alignItems="end"
            width="100%"
            pt={3}
            px={1}
            sx={{ display: step === 0 ? "none" : "block" }}
          >
            <MKButton
              fullWidth
              size="large"
              variant="outlined"
              color="primary"
              onClick={handleSend}
              disabled={loading}
            >
              {loading ? <CircularProgress color="white" size="18px" /> : "Mark Complete"}
            </MKButton>
            <MKBox height="10px" alignSelf="center">
              <MKTypography variant="caption" color={emailSuccess ? "info" : "error"} mt={1}>
                {emailSuccess && "Success! Your cost estimate has been saved"}
              </MKTypography>
            </MKBox>
          </MKBox>
        ) : (
          !hidden && (
            <Grid item xs={12} sx={{ display: step === 0 ? "none" : "block" }}>
              <MKBox px={2} width="100%">
                {jwt ? (
                  <MKBox display="flex" flexDirection="column" alignItems="en d" py={2}>
                    <MKButton
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      onClick={handleSend}
                      style={{
                        fontSize: "14px",
                        width: "200px",
                      }}
                    >
                      Save Cost Estimate
                      {loading && "..."}
                    </MKButton>
                    <MKTypography
                      variant="caption"
                      color={emailSuccess ? "info" : "error"}
                      mt={0.25}
                    >
                      {emailSuccess && "Success! Your cost estimate has been saved"}
                    </MKTypography>
                  </MKBox>
                ) : (
                  <MKBox
                    style={{
                      border: "1px solid #ddd",
                      borderRadius: "5px",
                      backgroundColor: "#f9f9f9",
                    }}
                    width="100%"
                    px={2}
                    pb={1}
                    pt={2.5}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={8}
                        flexDirection="column"
                        display="flex"
                        alignItems="center"
                        sx={{ pr: { xs: 0, md: 3 } }}
                        justifyContent="center"
                        textAlign="center"
                      >
                        <MKTypography variant="h4">
                          Next Step: Get A Free Rental ROI Report
                        </MKTypography>
                        <MKTypography variant="body2">
                          Refine your numbers by analyzing the rents of comparable ADUs and
                          apartments in your neighborhood
                        </MKTypography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        display="flex"
                        flexDirection="column"
                        justifyContent="start"
                        alignItems="center"
                      >
                        <MKBox display="flex" width="100%">
                          <MKInput
                            style={{ maxWidth: "300px", width: "100%" }}
                            name="email"
                            type="email"
                            error={!!emailError}
                            value={form.email}
                            disabled={emailSuccess}
                            placeholder="Email Address"
                            onChange={handleInput}
                            InputProps={{
                              sx: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
                            }}
                          />
                          <MKButton
                            variant="contained"
                            color="primary"
                            disabled={emailSuccess || loading}
                            onClick={handleSend}
                            style={{
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              fontSize: "14px",
                              minWidth: "130px",
                              height: "51px",
                            }}
                          >
                            Send Report
                            {loading && "..."}
                          </MKButton>
                        </MKBox>
                        <MKTypography
                          alignSelf="start"
                          variant="caption"
                          color={emailSuccess ? "info" : "error"}
                          mt={0.25}
                        >
                          {emailError}
                          {emailSuccess && "Success! Check your inbox for your personalized report"}
                        </MKTypography>
                      </Grid>
                    </Grid>
                  </MKBox>
                )}
              </MKBox>
            </Grid>
          )
        )}
      </Grid>
    </MKBox>
  );
};

// Typechecking props for the Page3
Estimate.propTypes = {
  noRedirect: PropTypes.bool,
  noFullHeight: PropTypes.bool,
  noTitle: PropTypes.bool,
  wideButton: PropTypes.bool,
  zipCode: PropTypes.number,
  externalForm: PropTypes.shape({}),
  externalHandleSubmit: PropTypes.func,
};

export default Estimate;
