import { useState } from "react";
import PropTypes from "prop-types";
import { Dialog, IconButton, ToggleButton, ToggleButtonGroup } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";

import flat from "assets/images/adu/flat.svg";
import slope from "assets/images/adu/slope.svg";
import steep from "assets/images/adu/steep.svg";

import useWindowSize from "Hooks/useWindowSize";
import { Close } from "@mui/icons-material";
import MKButton from "components/MKButton";

import classic_adu from "assets/images/adu/classic_adu.png";

const ProjectQuoteModal = ({ open, handleClose, model }) => {
  const [step, setStep] = useState(0);
  const { width } = useWindowSize();

  console.log(model);
  const handleSubmit = () => {
    setStep(1);
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      fullScreen={width < 768}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <IconButton onClick={handleClose} sx={{ display: "flex", alignSelf: "end" }}>
        <Close />
      </IconButton>
      <MKBox
        pb={4}
        px={1}
        display="flex"
        alignSelf="center"
        flexDirection="column"
        maxWidth="600px"
      >
        {step === 0 ? (
          <>
            <MKTypography variant="h4" textAlign="center">
              Get a personalized project estimate
            </MKTypography>
            <MKTypography variant="body2" textAlign="center" mb={2}>
              To get a quote, we&apos;ll need to know a bit about your property
            </MKTypography>
            <MKTypography
              variant="h5"
              fontWeight="bold"
              textAlign={width < 768 ? "center" : "left"}
            >
              What kind of conditions are on your property?
            </MKTypography>
            <ToggleButtonGroup
              color="primary"
              sx={{
                alignItems: "center",
                mx: 0,
                px: 0,
                "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                  borderColor: "rgba(0,0,0,0.12) ",
                },
                "& .Mui-selected": {
                  backgroundColor: "#F6F7FF !important",
                  borderColor: "#2A2CB1 !important",
                },
              }}
              value={"flat"}
              exclusive
              orientation={width < 768 ? "vertical" : "horizontal"}
              // onChange={(_, v) => handleToggle("condition", v)}
              fullWidth
            >
              <ToggleButton
                value={0}
                name="condition"
                sx={{
                  p: 0.5,
                  maxWidth: "200px",
                  fontWeight: "normal",
                  marginRight: { xs: 0, md: 1 },
                  marginBottom: { xs: 1, md: 0 },
                  mt: 1,
                  width: "100%",
                  fontSize: "16px",
                  borderColor: "rgba(0,0,0,0.12) !important",
                  textTransform: "none !important",
                  borderRadius: "5px !important",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  pt: 3,
                }}
              >
                <img
                  src={flat}
                  alt="Flat Slope"
                  name="condition"
                  width="100%"
                  style={{ maxWidth: "100%", marginBottom: "5px" }}
                />
                Flat
                <MKTypography variant="caption">No noticeable slope</MKTypography>
              </ToggleButton>
              <ToggleButton
                value={1}
                name="condition"
                sx={{
                  p: 0.5,
                  maxWidth: "200px",
                  fontWeight: "normal",
                  marginRight: { xs: 0, md: 1 },
                  marginBottom: { xs: 1, md: 0 },
                  mt: 1,
                  width: "100%",
                  fontSize: "16px",
                  borderColor: "rgba(0,0,0,0.12) !important",
                  textTransform: "none !important",
                  borderRadius: "5px !important",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  pt: 3,
                }}
              >
                <img
                  src={slope}
                  alt="Gentle Slope"
                  name="condition"
                  width="100%"
                  style={{ maxWidth: "100%", marginBottom: "5px" }}
                />
                Some Slope
                <MKTypography variant="caption">Not much noticeable slope</MKTypography>
              </ToggleButton>
              <ToggleButton
                value={2}
                name="condition"
                sx={{
                  p: 0.5,
                  maxWidth: "200px",
                  fontWeight: "normal",
                  marginRight: { xs: 0, md: 1 },
                  marginBottom: { xs: 1, md: 0 },
                  mt: 1,
                  width: "100%",
                  fontSize: "16px",
                  borderColor: "rgba(0,0,0,0.12) !important",
                  textTransform: "none !important",
                  borderRadius: "5px !important",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  pt: 3,
                }}
              >
                <img
                  src={steep}
                  alt="Steep Slope"
                  name="condition"
                  width="100%"
                  style={{ maxWidth: "100%", marginBottom: "5px" }}
                />
                Sloped
                <MKTypography variant="caption">Noticeable slope</MKTypography>
              </ToggleButton>
            </ToggleButtonGroup>
            <MKTypography
              variant="h5"
              fontWeight="bold"
              textAlign={width < 768 ? "center" : "left"}
              mt={3}
            >
              Access to the backyard
            </MKTypography>
            <MKTypography variant="body2" textAlign={width < 768 ? "center" : "left"}>
              How easy is it to get to the backyard?
            </MKTypography>
            <ToggleButtonGroup
              color="primary"
              sx={{
                alignItems: "center",
                mx: 0,
                px: 0,
                "& .Mui-selected": {
                  backgroundColor: "#F6F7FF !important",
                  opacity: "1 !important",
                  borderColor: "#2A2CB1 !important",
                  border: "1px solid !important",
                },
              }}
              value={0}
              exclusive
              // onChange={(_, v) => handleToggle("condition", v)}
            >
              <ToggleButton
                value={0}
                name="access"
                sx={{
                  p: 1.5,
                  fontWeight: "normal",
                  marginRight: 1,
                  marginBottom: { xs: 1, md: 0 },
                  mt: 1,
                  px: 3,
                  fontSize: "16px",
                  borderColor: "rgba(0,0,0,0.12) !important",
                  textTransform: "none !important",
                  borderRadius: "5px !important",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Alley Access
              </ToggleButton>
              <ToggleButton
                value={1}
                name="access"
                sx={{
                  p: 1.5,
                  fontWeight: "normal",
                  marginRight: 1,
                  marginBottom: { xs: 1, md: 0 },
                  mt: 1,
                  px: 3,
                  fontSize: "16px",
                  borderColor: "rgba(0,0,0,0.12) !important",
                  textTransform: "none !important",
                  borderRadius: "5px !important",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Large Gate (RV Gate)
              </ToggleButton>
              <ToggleButton
                value={2}
                name="access"
                sx={{
                  p: 1.5,
                  fontWeight: "normal",
                  marginBottom: { xs: 1, md: 0 },
                  mt: 1,
                  px: 3,
                  fontSize: "16px",
                  borderColor: "rgba(0,0,0,0.12) !important",
                  textTransform: "none !important",
                  borderRadius: "5px !important",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Small Gate
              </ToggleButton>
            </ToggleButtonGroup>
            <MKButton fullWidth size="large" color="primary" sx={{ mt: 3 }} onClick={handleSubmit}>
              Continue
            </MKButton>
          </>
        ) : (
          <>
            <MKBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              maxWidth="350px"
              minHeight="500px"
            >
              <img width="350px" style={{ marginBottom: "-10px" }} src={classic_adu} alt="" />
              <MKTypography variant="h4" textAlign="center">
                Received!
              </MKTypography>
              <MKTypography variant="body2" textAlign="center" mb={2}>
                We&apos;ll get to it. Be expecting an email in the next week with details of your
                quote.
              </MKTypography>
              <MKButton fullWidth size="large" color="primary" sx={{ mt: 3 }} onClick={handleClose}>
                Continue
              </MKButton>
            </MKBox>
          </>
        )}
      </MKBox>
    </Dialog>
  );
};

ProjectQuoteModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  model: PropTypes.string,
};

export default ProjectQuoteModal;
