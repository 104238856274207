import PropTypes from "prop-types";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

const getMinMax = (v, p = 0.15, r = 50) => {
  if (v === 0) {
    return "$0";
  }
  const min = Math.round((v * (1 - p)) / r) * r;
  const max = Math.round((v * (1 + p)) / r) * r;

  return `$${min.toLocaleString("en", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })} ― $${max.toLocaleString("en", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })}`;
};

const CostTable = ({ hardCosts, totalHC, softCosts, totalSC, totalCosts }) => {
  return (
    <TableContainer sx={{ px: 1, pt: "10px" }}>
      <Table sx={{ minWidth: 200 }} aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row" sx={{ py: "0px" }}>
              Hard Costs
            </TableCell>
          </TableRow>
          {hardCosts.map((row) => (
            <TableRow key={row.name}>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  fontSize: "14px",
                  pl: "30px",
                  pt: "0px",
                  pb: "2px",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontSize: "14px",
                  pt: "0px",
                  pb: "2px",
                }}
              >
                {getMinMax(row.value)}
              </TableCell>
            </TableRow>
          ))}
          <TableRow
            sx={{
              borderTop: "1px solid",
              borderBottom: "1px solid",
              borderColor: "light.main",
            }}
          >
            <TableCell component="th" scope="row" sx={{ pt: "4px", pb: "2px", fontWeight: "bold" }}>
              Total Hard Costs
            </TableCell>
            <TableCell align="right" sx={{ pt: "4px", pb: "2px", fontWeight: "bold" }}>
              {getMinMax(totalHC)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row" sx={{ pt: "8px", pb: 0 }}>
              Soft Costs
            </TableCell>
          </TableRow>
          {softCosts.map((row) => (
            <TableRow key={row.name}>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  fontSize: "14px",
                  pl: "30px",
                  pt: "0px",
                  pb: "2px",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontSize: "14px",
                  pt: "0px",
                  pb: "2px",
                }}
              >
                {getMinMax(row.value)}
              </TableCell>
            </TableRow>
          ))}
          <TableRow
            sx={{
              borderTop: "1px solid",
              borderBottom: "1px solid",
              borderColor: "light.main",
            }}
          >
            <TableCell component="th" scope="row" sx={{ pt: "4px", pb: "2px", fontWeight: "bold" }}>
              Total Soft Costs
            </TableCell>
            <TableCell align="right" sx={{ pt: "4px", pb: "2px", fontWeight: "bold" }}>
              {getMinMax(totalSC)}
            </TableCell>
          </TableRow>
          <TableRow style={{ borderTop: "1px solid #000" }}>
            <TableCell
              component="th"
              scope="row"
              style={{ py: "0px", fontWeight: "bold", color: "#2A2CB1", fontSize: "18px" }}
            >
              Total Costs
            </TableCell>
            <TableCell
              align="right"
              style={{ py: "0px", fontWeight: "bold", color: "#2A2CB1", fontSize: "18px" }}
            >
              {getMinMax(totalCosts)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// Typechecking props for the MKInput
CostTable.propTypes = {
  hardCosts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  softCosts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  totalHC: PropTypes.number,
  totalSC: PropTypes.number,
  totalCosts: PropTypes.number,
};

export default CostTable;
