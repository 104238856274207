import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";

import LinearProgress from "@mui/material/LinearProgress";

// @mui icons
import TurnLeft from "@mui/icons-material/TurnLeft";
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// branding
import branding from "./branding";

// form initialization
import { initialState } from "./form/initialState";

// helper for loan calculation
import { setLocalStorage, readLocalStorage } from "helpers/localStorage";

import Page0 from "./pages/page0";
import Page1 from "./pages/page1";
import Page2 from "./pages/page2";
import Page3 from "./pages/page3";
import Page4 from "./pages/page4";
import Page5 from "./pages/page5";

const WidgetPage = () => {
  const [searchParams] = useSearchParams();
  const { id } = branding;
  const [page, setPage] = useState(0);
  const [form, setForm] = useState(initialState);

  const nextPage = () => {
    setPage(page + 1);
  };

  const updateForm = (field, value) => {
    setForm((e) => ({
      ...e,
      [field]: value,
    }));
  };

  const handleClose = () => {
    parent.postMessage(`closeWindow`, "*");
  };

  // update form from local storage upon initialization and initialize with loan value if available
  useEffect(() => {
    setForm(readLocalStorage(`Dwellito${id}-Form`, initialState));
    setPage(readLocalStorage(`Dwellito${id}-Page`, 0));
    if (searchParams.get("initialValue")) {
      const intialLoanValue = searchParams.get("initialValue");
      setForm((f) => ({
        ...f,
        desiredAmount: Number(intialLoanValue.replace(/\D/g, "") || "").toLocaleString(),
      }));
    }
  }, []);

  // save form to local storage upon each page transition
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `/${id}page-${page}`, title: `${id}page ${page}` });
    if (page > 0) {
      setLocalStorage(`Dwellito${id}-Page`, 0);
      setLocalStorage(`Dwellito${id}-Form`, form);
    }
    setLocalStorage(`Dwellito${id}-Page`, page);
  }, [page]);

  return (
    <MKBox>
      {page !== 5 && (
        <MKBox>
          <LinearProgress value={((page + 1) / 5) * 100} variant="determinate" color="primary" />
        </MKBox>
      )}

      <MKBox
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pt: 2, px: 2 }}
      >
        {page === 0 || page === 5 ? (
          <MKBox height="24px" />
        ) : (
          <TurnLeft
            fontSize="medium"
            color="info"
            sx={{ cursor: "pointer", "&:hover": { color: "primary.main" } }}
            onClick={() => setPage(page - 1)}
          />
        )}
        <CloseIcon
          fontSize="medium"
          color="info"
          cursor="pointer"
          sx={{ cursor: "pointer", "&:hover": { color: "primary.main" } }}
          onClick={handleClose}
        />
      </MKBox>
      <MKBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        sx={{ px: 2, overflow: "auto", height: "100%" }}
      >
        {(() => {
          switch (page) {
            case 0:
              return <Page0 nextPage={nextPage} form={form} updateForm={updateForm} />;
            case 1:
              return <Page1 nextPage={nextPage} form={form} updateForm={updateForm} />;
            case 2:
              return <Page2 nextPage={nextPage} form={form} updateForm={updateForm} />;
            case 3:
              return <Page3 nextPage={nextPage} form={form} updateForm={updateForm} />;
            case 4:
              return <Page4 form={form} nextPage={nextPage} updateForm={updateForm} />;
            case 5:
              return <Page5 form={form} nextPage={handleClose} updateForm={updateForm} />;
            default:
              return <Page0 nextPage={nextPage} form={form} updateForm={updateForm} />;
          }
        })()}
      </MKBox>
    </MKBox>
  );
};

WidgetPage.propTypes = {
  closeHandler: PropTypes.func,
  PerosnalInfo: PropTypes.bool,
};

export default WidgetPage;
