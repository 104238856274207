import { useState } from "react";
import PropTypes from "prop-types";
import { Dialog, LinearProgress } from "@mui/material";

import useWindowSize from "Hooks/useWindowSize";
import MKBox from "components/MKBox";
import { Close, TurnLeft } from "@mui/icons-material";

// import Page0 from "./Pages/Page0";
import Page2 from "./Pages/Page2";

import { initialState } from "./form";

const FinancingQuoteModal = ({ open, handleClose, model }) => {
  const [form, setForm] = useState(initialState);
  const [page, setPage] = useState(0);
  const { width } = useWindowSize();
  console.log(model);

  const updateForm = (field, value) => {
    setForm((e) => ({
      ...e,
      [field]: value,
    }));
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      fullScreen={width < 768}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MKBox display="flex" flexDirection="column">
        <LinearProgress value={((page + 1) / 4) * 100} variant="determinate" color="primary" />
        <MKBox
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pt: 2, px: 2 }}
        >
          {page === 0 || page === 4 ? (
            <MKBox height="24px" />
          ) : (
            <TurnLeft
              fontSize="medium"
              color="info"
              sx={{ cursor: "pointer", "&:hover": { color: "primary.main" } }}
              onClick={() => setPage(page - 1)}
            />
          )}
          <Close
            fontSize="medium"
            color="info"
            cursor="pointer"
            sx={{ cursor: "pointer", "&:hover": { color: "primary.main" } }}
            onClick={handleClose}
          />
        </MKBox>
        {/* <Page0 form={form} updateForm={updateForm} /> */}
        <Page2 form={form} updateForm={updateForm} />
      </MKBox>
    </Dialog>
  );
};

FinancingQuoteModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  model: PropTypes.string,
};

export default FinancingQuoteModal;
