import PropTypes from "prop-types";
import Slider from "@mui/material/Slider";

import { ReactComponent as NormalDist } from "assets/images/shapes/normal-distribution.svg";

const DistributionSlider = ({ value, label, color, onChange, ...rest }) => {
  return (
    <>
      <NormalDist width="100%" height="50px" />
      <Slider
        getAriaLabel={() => label}
        value={value}
        valueLabelDisplay="auto"
        color={color}
        onChange={onChange}
        sx={{
          mt: { xs: "-14px" },
          "& .MuiSlider-markLabel": {
            fontSize: "12px",
            mt: -0.5,
          },
        }}
        {...rest}
      />
    </>
  );
};

// Typechecking props for the MKInput
DistributionSlider.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
};

export default DistributionSlider;
