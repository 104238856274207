import { useEffect, useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import InputAdornment from "@mui/material/InputAdornment";
import NumberFormatCustom from "components/NumberFormat";

// @mui icons
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import { stringToNumber, formatDollars } from "helpers/formatters";
import { calculateMonthlyPayment } from "helpers/calculators/loanCalc";

function Page1({ nextPage, form: form, updateForm: setForm }) {
  const [calc, setCalc] = useState({
    rent: 0,
    payment: 0,
  });

  useEffect(() => {
    if (form.desiredAmount) {
      setCalc({
        payment: calculateMonthlyPayment(stringToNumber(form.desiredAmount), 0.0725),
        rent: stringToNumber(form.desiredAmount) * 0.01,
      });
    } else {
      setCalc({
        payment: 0,
        rent: 0,
      });
    }
  }, [form.desiredAmount]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm(name, value);
  };

  const handleContinue = () => {
    nextPage();
  };

  return (
    <>
      <MKBox
        py={2}
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <MKTypography variant="h4" color="text" fontWeight="bold" textAlign="center">
          Explore a loan that works for you, Caleb!
        </MKTypography>
        <MKTypography variant="body2" mt={1} mb={2}>
          Make low monthly payments, customized to fit you
        </MKTypography>
        <MKInput
          variant="outlined"
          name="desiredAmount"
          sx={{ maxWidth: "190px", my: 1.5, cursor: "pointer" }}
          value={form.desiredAmount}
          onChange={handleChange}
          onFocus={(event) => event.target.select()}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <EditOutlinedIcon color="light" fontSize="15px" sx={{ mr: "5px" }} />
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <MKTypography fontSize="15px" color="info">
                  $
                </MKTypography>
              </InputAdornment>
            ),
            inputMode: "decimal",
            sx: { boxShadow: "0px 0px 10px 2px rgba(0,0,0,0.1)", borderRadius: 15 },
            style: { border: "none !important" },
            inputComponent: NumberFormatCustom,
          }}
          // eslint-disable-next-line
          inputProps={{
            inputMode: "numeric",
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Grid container maxWidth="400px" my={3}>
          <Grid
            item
            xs={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MKTypography variant="caption" fontWeight="bold">
              Est. monthly cost
            </MKTypography>
            <MKTypography variant="h4">{formatDollars(calc.payment)}</MKTypography>
            <MKTypography variant="caption" fontWeight="regular" color="info">
              as low as $0/mo
            </MKTypography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <Divider orientation="vertical" />
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MKTypography variant="caption" fontWeight="bold">
              Est. rental income
            </MKTypography>
            <MKTypography variant="h4">{formatDollars(calc.rent)}</MKTypography>
            <MKTypography variant="caption" fontWeight="regular" color="info">
              or more!
            </MKTypography>
          </Grid>
        </Grid>
        <MKTypography variant="caption" color="info" fontWeight="bold" my={3}>
          You could profit{" "}
          <span
            style={{
              textDecoration: "underline",
              textDecorationSkipInk: "none",
              color: "#344767",
              fontSize: "115%",
            }}
          >
            {`${formatDollars(calc.rent - calc.payment)} - ${formatDollars(calc.rent)}`}
          </span>{" "}
          per month!
        </MKTypography>
      </MKBox>
      <MKBox display="flex" flexDirection="column" alignItems="center" pb={5}>
        <MKButton
          size="large"
          color="primary"
          sx={{ maxWidth: "450px" }}
          fullWidth
          onClick={handleContinue}
        >
          <LockOutlinedIcon color="light" sx={{ mr: "10px" }} />
          Continue
        </MKButton>
      </MKBox>
    </>
  );
}

// Typechecking props for the Page1
Page1.propTypes = {
  nextPage: PropTypes.func.isRequired,
  form: PropTypes.shape({
    desiredAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default Page1;
