export const initialState = {
  desiredAmount: "310,000",
  homeValue: [600000, 800000],
  currentDebt: null,
  mortgagePayment: "",
  propertyType: "",
  individualIncomeScaled: 0,
  individualIncome: 0,
  householdIncomeScaled: 0,
  householdIncome: 0,
  creditScore: "",
  maxLoan: 2000000,
  includeGrant: false,
  monthlyPayment: 0,
  totalInterest: 0,
  calHfaEligible: false,
  sourceURL: "",
  sourceName: "",
  loanTerm: "30/15 Balloon",
  potentialRates: [],
  fullName: "",
  email: "",
  phone: "",
  address: {
    formatted: "",
    county: "",
  },
  valuation: {
    price: null,
    priceRangeLow: null,
    priceRangeHigh: null,
    listings: [],
  },
};
