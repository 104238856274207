import { useEffect, useContext } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { useMemberstackModal } from "@memberstack/react";
import MKBox from "components/MKBox";

import Authentication from "Context/auth";

export default function Signup() {
  const navigate = useNavigate();
  const { setJwt } = useContext(Authentication);
  const { openModal, hideModal } = useMemberstackModal();

  useEffect(async () => {
    openModal({
      type: "SIGNUP",
      planId: "pln_test-wvc0xxf",
    }).then(({ data }) => {
      if (data?.tokens?.accessToken) {
        axios.defaults.headers.common["Authorization"] = data.tokens.accessToken;
        setJwt(data.tokens.accessToken);
        document.cookie = `_ms-mid=${data.tokens.accessToken}; expires=${data.tokens.expires}; path=/; secure; samesite=none`;
      }
      if (data?.member?.auth?.email) {
        navigate("/homeowner/onboarding");
        hideModal();
      }
    });
  }, []);

  return <MKBox height="100vh" width="100vw" sx={{ filter: "blur(5px)" }}></MKBox>;
}
