import { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useAuth } from "@memberstack/react";

// import { MemberstackProtected } from "@memberstack/react";
import { Grid, Container, Link } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";

import Authentication from "Context/auth";

// import Login from "Users/Auth/Login";
import Navbar from "Users/Components/Navbar";
import QuoteTable from "./Table";

import useWindowSize from "Hooks/useWindowSize";
import MKButton from "components/MKButton";

const exampleData = [
  {
    business: {
      name: "Levi Design & Build",
      address: "6520 Platt Ave #115, West Hills, CA 91307",
      logo: "https://st.hzcdn.com/simgs/5833852a064a5c78_3-2677/_.jpg",
      website: "https://leviconstruction.com",
      yearFounded: 1999,
    },
    quote: {
      id: 0,
      images: {
        floorplan:
          "https://uploads-ssl.webflow.com/60edefc65e33fa6df4738c0b/60edefc65e33fa427573948a_Navajo960.jpeg",
      },
      "Construction Type": "Sitebuilt",
      "Square Footage": "1,000",
      Bedrooms: 2,
      Bathrooms: 1,
      Kitchen: "Full",
      Notes:
        "Schedule a site visit to go over final ADU plans and options. Price is best-guess estimate based on design considerations.",
      "Cost per ft²": "$273",
      "Total Cost": "$273,000",
    },
  },
  {
    business: {
      name: "Dwellito.com",
      address: "301 Example St, San Franciso, CA 94016",
      logo: "https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/60edefc65e33fa61a4738f23_LogoBlue.svg",
      website: "https://dwellito.com",
      yearFounded: 2018,
    },
    quote: {
      id: 1,
      images: {
        floorplan:
          "https://static1.squarespace.com/static/55c51450e4b0f380d65323c8/5756f4c28259b5740d320d50/576c55d91b631b120f49e9b1/1471297294552/res4-prefab-dwell-home-first-floor-plan-drawing.jpg?format=2500w",
      },
      "Construction Type": "Modular",
      "Square Footage": "540",
      Bedrooms: 1,
      Bathrooms: 1,
      Kitchen: "Full",
      Notes: "",
      "Cost per ft²": "$720",
      "Total Cost": "$389,000",
    },
  },
  {
    business: {
      name: "Dwellito.com",
      address: "301 Example St, San Franciso, CA 94016",
      logo: "https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/60edefc65e33fa61a4738f23_LogoBlue.svg",
      website: "https://dwellito.com",
      yearFounded: 2018,
    },
    quote: {
      id: 2,
      images: {
        floorplan:
          "https://static1.squarespace.com/static/55c51450e4b0f380d65323c8/5756f4c28259b5740d320d50/576c55d91b631b120f49e9b1/1471297294552/res4-prefab-dwell-home-first-floor-plan-drawing.jpg?format=2500w",
      },
      "Construction Type": "Modular",
      "Square Footage": "540",
      Bedrooms: 1,
      Bathrooms: 1,
      Kitchen: "Full",
      Notes: "",
      "Cost per ft²": "$720",
      "Total Cost": "$389,000",
    },
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <MKBox
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </MKBox>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Quote = () => {
  const { jwt } = useContext(Authentication);
  const { height } = useWindowSize();
  const { isLoggedIn } = useAuth();
  const [loading, setLoading] = useState(true);
  const [currentYear] = useState(new Date().getFullYear());
  const [tab, setTab] = useState(0);

  const handleChange = (_, newValue) => {
    setTab(newValue);
  };

  console.log(loading);

  useEffect(async () => {
    if (isLoggedIn && jwt) {
      try {
        console.log("loggedIn");
      } catch {
        console.log("not found");
      }
      setLoading(false);
    }
  }, [isLoggedIn, jwt]);

  return (
    <>
      <Container
        disableGutters
        maxWidth="xl"
        sx={{
          minHeight: `${height}px`,
          pb: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Navbar />
        {/* <MemberstackProtected onUnauthorized={<Login />}> */}
        <Grid
          container
          maxWidth="xxl"
          columns={15}
          sx={{ mt: { xs: "0px", lg: "-20px" } }}
          spacing={2}
          pb={3}
        >
          <Grid
            item
            xs={15}
            xl={3}
            sx={{
              textAlign: { xs: "center", md: "left" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "center",
              maxHeight: `${height - 80}px`,
            }}
          >
            <MKTypography variant="h2" fontWeight="light">
              Welcome to your ADU Planner
            </MKTypography>
            <MKBox
              style={{
                position: "relative",
                marginTop: "10px",
                marginBottom: "10px",
                alignSelf: "center",
              }}
            >
              <img
                src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/6352f1a7ff883235a687cd06_View%202_White_Transparent%20Background.png"
                loading="lazy"
                width="200px"
                style={{ marginTop: "-30px", marginBottom: "-30px" }}
                sizes="(max-width: 420px) 100vw, 300px"
                alt=""
              />
              <MKTypography
                variant="body2"
                style={{ position: "absolute", top: "48px", left: "50px" }}
              ></MKTypography>
            </MKBox>
            <MKTypography variant="body2" fontWeight="light">
              Compare personalized proposals and quotes from ADU builders with no obligation. If and
              when you want to move forward with a builder, click &quot;Get Started&quot; to start
              the pre-qualification process.
            </MKTypography>
          </Grid>
          <Grid item xs={15} xl={12}>
            <MKBox mb={1}>
              <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Card View" {...a11yProps(0)} />
                <Tab label="Table View" {...a11yProps(1)} />
              </Tabs>
            </MKBox>
            <TabPanel value={tab} index={0}>
              <Grid container spacing={2}>
                {exampleData.map((b) => {
                  return (
                    <Grid key={b.business.name} item xs={12} md={6} xl={4}>
                      <MKBox
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        sx={{
                          border: "1px solid #ddd",
                          borderRadius: 2,
                          boxShadow: 2,
                          minHeight: "500px",
                        }}
                      >
                        <MKBox
                          sx={{
                            backgroundColor: "#fff",
                            width: "100%",
                            height: "60px",
                            borderTopLeftRadius: 5,
                            borderTopRightRadius: 5,
                            boxShadow: 1,
                          }}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <img
                            src={b.business.logo}
                            alt={b.business.name}
                            height="80%"
                            style={{ maxWidth: "100%" }}
                          />
                        </MKBox>
                        <MKBox
                          px={2}
                          pb={1}
                          pt={1}
                          width="100%"
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          style={{ borderRadius: 5 }}
                        >
                          <Link href={b.business.website}>
                            <MKTypography
                              variant="h5"
                              mt={1}
                              fontWeight="regular"
                              style={{ textDecoration: "underline" }}
                            >
                              {b.business.name}
                            </MKTypography>
                          </Link>
                          <MKTypography variant="caption" mt={0} display="flex">
                            <BusinessOutlinedIcon style={{ marginRight: 5 }} /> {b.business.address}
                          </MKTypography>
                          <MKTypography variant="caption" mt={0} display="flex">
                            <WorkHistoryOutlinedIcon style={{ marginRight: 5 }} />{" "}
                            {currentYear - b.business.yearFounded} years in business
                          </MKTypography>
                          <MKBox my={1} display="flex" position="relative">
                            <img
                              src={b.quote.images.floorplan}
                              loading="lazy"
                              style={{
                                height: "160px",
                                maxWidth: "100%",
                                display: "block",
                                position: "relative",
                              }}
                              alt="Floorplan"
                            />
                            <IconButton
                              size="small"
                              color="text"
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                backgroundColor: "#ffffffaa",
                              }}
                            >
                              <OpenInFullIcon />
                            </IconButton>
                          </MKBox>

                          {Object.entries(b.quote).map((e) => {
                            if (e[0] === "images" || e[0] === "id") return;
                            return (
                              <MKBox
                                key={b.quote.id + e[0]}
                                display="flex"
                                flexDirection="row"
                                alignItems="start"
                                justifyContent="space-between"
                                width="100%"
                                my={1}
                                style={{
                                  borderBottom: "1px solid #ddd",
                                }}
                              >
                                <MKTypography
                                  variant="body2"
                                  fontWeight="bold"
                                  mr={2}
                                  minWidth="60px"
                                >
                                  {e[0]}
                                </MKTypography>
                                <MKTypography
                                  variant="body2"
                                  ml={2}
                                  textAlign="justify"
                                  sx={{
                                    height: e[0] === "Notes" ? "110px" : "inherit",
                                    overflow: "scroll",
                                    lineHeight: 1.15,
                                  }}
                                >
                                  {e[1]}
                                </MKTypography>
                              </MKBox>
                            );
                          })}
                          <MKBox
                            sx={{
                              width: "100%",
                              my: 1,
                              mb: 1.5,
                              textAlign: "center",
                            }}
                          >
                            <MKTypography variant="body2" mb={0}>
                              View PDF of Quote
                            </MKTypography>
                          </MKBox>
                          <MKButton color="primary" size="medium" fullWidth>
                            Get Started
                          </MKButton>
                        </MKBox>
                      </MKBox>
                    </Grid>
                  );
                })}
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <QuoteTable quotes={exampleData} />
            </TabPanel>
          </Grid>
        </Grid>
        {/* </MemberstackProtected> */}
      </Container>
    </>
  );
};

export default Quote;
