import { useState, useEffect } from "react";
import PropTypes from "prop-types";

// @mui material components
import Slider from "@mui/material/Slider";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import ToggleButton from "@mui/material/ToggleButton";

// @mui icons
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import HourglassTopOutlinedIcon from "@mui/icons-material/HourglassTopOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import branding from "../branding";
import { calculateMonthlyPayment, calculateTotalInterest } from "helpers/calculators/loanCalc";
import { numShortener, stringToNumber, formatDollars } from "helpers/formatters";
import loanCalc from "../calculator";

function Page3({ nextPage, form, updateForm }) {
  const { name } = branding;
  const [aprArr, setAprArr] = useState([]);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => {
    let calculatedAmount = stringToNumber(form.desiredAmount);
    if (form.includeGrant) {
      calculatedAmount = calculatedAmount - 40000;
    }
    const potentialAprArr = loanCalc(
      form.creditScore,
      stringToNumber(form.desiredAmount),
      stringToNumber(form.currentDebt),
      (form.homeValue[0] + form.homeValue[1]) / 2,
      form.propertyType
    );
    setAprArr(potentialAprArr);
    const monthlyPaymentCalc = calculateMonthlyPayment(calculatedAmount, potentialAprArr[0], 360);
    setMonthlyPayment(monthlyPaymentCalc);
    const totalInterestCalc = calculateTotalInterest(calculatedAmount, potentialAprArr[0], 180);
    setTotalInterest(totalInterestCalc);
  }, [form.desiredAmount, form.includeGrant]);

  const handleContinue = () => {
    setError(false);
    if (!form.desiredAmount) {
      setError(true);
      return;
    }
    updateForm("monthlyPayment", monthlyPayment);
    updateForm("totalInterest", totalInterest);
    updateForm("potentialRates", aprArr);
    nextPage();
  };

  return (
    <>
      <MKBox
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          px: 1,
        }}
      >
        <MKTypography
          mt={2}
          variant="h4"
          color="text"
          fontWeight="bold"
          textAlign="center"
          maxWidth="420px"
        >
          Congrats! You are matched with CalCon Mutual for this loan{" "}
        </MKTypography>
        <MKTypography variant="body2" mt={1} mb={1} textAlign="center" maxWidth="420px">
          Amount and terms will be quoted after submission
        </MKTypography>
        <MKBox
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            maxWidth: "420px",
            width: "100%",
          }}
        >
          <MKBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "420px",
              width: "95%",
            }}
          >
            <Slider
              getAriaLabel={() => "Desired Amount"}
              name="desiredAmount"
              onChange={(_, v) => updateForm("desiredAmount", v)}
              valueLabelDisplay="off"
              className={`${error && !form.desiredAmount ? "MuiSlider-error" : ""}`}
              value={stringToNumber(form.desiredAmount) || 0}
              min={0}
              max={stringToNumber(form.maxLoan) || 2000000}
              step={1000}
              valueLabelFormat={(l) => (l === 1000000 ? `$1M+` : `$${numShortener(l)}`)}
            />
            <MKBox
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <MKBox display="flex" flexDirection="column">
                <MKTypography variant="body3" color="primary">
                  Planning To Spend
                </MKTypography>
                <MKTypography variant="body2" color="primary">
                  {formatDollars(form.desiredAmount)}
                </MKTypography>
              </MKBox>
              <MKBox display="flex" flexDirection="column" alignItems="end">
                <MKTypography variant="body3">Available Amount</MKTypography>
                <MKTypography variant="body2">{formatDollars(form.maxLoan)}</MKTypography>
              </MKBox>
            </MKBox>
          </MKBox>
          {form.calHfaEligible ? (
            <MKBox
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                maxWidth: "420px",
                width: "100%",
                mb: 1,
              }}
            >
              <Checkbox
                checked={form.includeGrant}
                onChange={(e) => updateForm("includeGrant", e.target.checked)}
              />
              <MKTypography variant="body4" color="info" sx={{ cursor: "pointer" }}>
                Include $40k CalHFA ADU Grant in monthly payments?
              </MKTypography>
            </MKBox>
          ) : (
            <MKBox mb={2} />
          )}
          <Grid container rowSpacing={1.5} sx={{ maxWidth: "420px", width: "100%" }}>
            <Grid item xs={12} width="100%">
              <ToggleButton
                value="calcon"
                color="primary"
                selected={false}
                disabled
                sx={{
                  display: "block",
                  cursor: "pointer",
                  flex: 1,
                  width: "100%",
                  height: "100%",
                  minHeight: "90px",
                  pt: 2,
                  pb: 2,
                  px: 2,
                  backgroundColor: "#FFF",
                  boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.05)",
                  "&:hover:not(:disabled)": {
                    borderColor: "primary.main",
                    opacity: 1,
                  },
                  "& .Mui-selected": {
                    backgroundColor: "#F6F7FF !important",
                    borderColor: "#2A2CB1 !important",
                  },
                  "&:active": {
                    backgroundColor: "#FFF",
                  },
                  // "&:disabled": {
                  //   backgroundColor: "#F5F5F5",
                  //   opacity: 0.5,
                  //   pointerEvents: "auto",
                  // },
                }}
              >
                <MKBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <MKBox sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <MKTypography variant="body1">
                      {formatDollars(monthlyPayment)}
                      <span>
                        <MKTypography variant="body4" color="info">
                          {" "}
                          /mo.
                        </MKTypography>
                      </span>
                    </MKTypography>
                  </MKBox>
                  <Chip
                    label="2nd Mortgage"
                    size="small"
                    sx={{
                      backgroundColor: "primary.light",
                      color: "text.main",
                      fontWeight: "bold",
                      textTransform: "none",
                      fontSize: "12px",
                      px: 0.5,
                    }}
                  />
                </MKBox>
                <MKBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "start",
                    mt: 1,
                  }}
                ></MKBox>
                <MKBox
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", iframe: "row" },
                    justifyContent: "space-between",
                    alignItems: { xs: "start", iframe: "center" },
                    mt: 1,
                  }}
                >
                  <MKBox display="flex" flexDirection="row" alignItems="center" mr={"16px"}>
                    <PercentOutlinedIcon
                      color="info"
                      sx={{
                        fontSize: "13px !important",
                        padding: 0.15,
                        mr: 0.35,
                        borderRadius: "50%",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: "info.main",
                        color: "info.main",
                      }}
                    />
                    <MKTypography variant="body1" color="info" fontWeight="regular" fontSize="85%">
                      {(aprArr[0] * 100).toFixed(2)}% APR
                    </MKTypography>
                  </MKBox>
                  <MKBox display="flex" flexDirection="row" alignItems="center" mr={"16px"}>
                    <HourglassTopOutlinedIcon
                      color="info"
                      sx={{
                        fontSize: "13px !important",
                        padding: 0.15,
                        mr: 0.35,
                        borderRadius: "50%",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: "info.main",
                        color: "info.main",
                      }}
                    />
                    <MKTypography variant="body1" color="info" fontWeight="regular" fontSize="85%">
                      30/15
                    </MKTypography>
                  </MKBox>
                  <MKBox display="flex" flexDirection="row" alignItems="center">
                    <HomeOutlinedIcon
                      color="info"
                      sx={{
                        fontSize: "14px !important",
                        padding: 0.15,
                        mr: 0.25,
                        borderRadius: "50%",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: "info.main",
                        color: "info.main",
                      }}
                    />
                    <MKTypography
                      variant="body1"
                      color="info"
                      fontWeight="regular"
                      fontSize="85%"
                      mr={1}
                    >
                      {`$${numShortener(totalInterest)} Lifetime Interest`}
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </ToggleButton>
            </Grid>
          </Grid>
          <MKButton
            size="large"
            color="primary"
            sx={{ maxWidth: "420px", width: "100%", mt: 2 }}
            fullWidth
            onClick={handleContinue}
          >
            <LockOutlinedIcon color="light" sx={{ mr: "10px" }} />
            Continue
          </MKButton>
          <MKTypography variant="body5" color="info" mt={2} mb={2}>
            Get a free loan estimate from {name}
          </MKTypography>
        </MKBox>
      </MKBox>
    </>
  );
}

// Typechecking props for the Page1
Page3.propTypes = {
  nextPage: PropTypes.func.isRequired,
  form: PropTypes.shape({
    creditScore: PropTypes.string,
    homeValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    desiredAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    propertyType: PropTypes.string,
    currentDebt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    calHfaEligible: PropTypes.bool,
    includeGrant: PropTypes.bool,
    monthlyPayment: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    potentialRates: PropTypes.arrayOf(PropTypes.number),
    maxLoan: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedLoan: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  }).isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default Page3;
