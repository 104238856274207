const lenders = {
  loanProviders: [
    {
      name: "OneTrust Homeloans",
      cltvCutoffs: [0.9, 0.6, 0],
      badRates: false,
      fairRates: [0.1125, 0.1025, 0.0925],
      goodRates: [0.1025, 0.0925, 0.0825],
      excellentRates: [0.0925, 0.0825, 0.0725],
    },
  ],
  equityProviders: [
    {
      name: "Splitero",
      id: "splitero",
      logo: "https://uploads-ssl.webflow.com/60edefc65e33fa6df4738c0b/62ba3ef76485b6f2279ab6a3_Logo_Splitero.png",
      states: ["California"],
      originationFeeMinPercent: 0.0199,
      originationFeeMaxPercent: 0.0399,
      originationFeeOther: 1050,
      maxTermYears: 30,
      homeValueCap: 0.3,
      oltvCap: 0.8,
      dollarCap: 600000,
      appreciationCap: 0.1699,
      homeImprovementDeduction: 1,
      originalInvestmentPayback: 1,
      appreciationShare: 3,
      homeShare: 0,
      initialValueAdj: 0.85,
      aprCapMin: 0.1299,
      aprCapMax: 0.1699,
      shareCap: 0.8,
      averageRiskAdjustment: 0.15,
    },
    {
      name: "Unison Home Ownership Investors",
      id: "unison",
      logo: "https://uploads-ssl.webflow.com/60edefc65e33fa6df4738c0b/62ba3f099f0ed07db35b7d2a_logo_Unison.png",
      states: [
        "Arizona",
        "California",
        "Colorado",
        "Delaware",
        "Florida",
        "Illinois",
        "Indiana",
        "Kansas",
        "Kentucky",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Missouri",
        "Nevada",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "Ohio",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "Tennessee",
        "Utah",
        "Virginia",
        "Washington",
        "Washington D.C.",
        "Wisconsin",
      ],
      originationFeeMinPercent: 0.03,
      originationFeeMaxPercent: 0.03,
      originationFeeOther: 1050,
      maxTermYears: 30,
      homeValueCap: 0.175,
      oltvCap: 0.75,
      dollarCap: 500000,
      aduShareDeduction: true,
      appreciationCap: false,
      homeImprovementDeduction: 1,
      originalInvestmentPayback: 1,
      appreciationShare: 4,
      homeShare: 0,
      initialValueAdj: 0.975,
      aprCapMin: 0.15,
      aprCapMax: 0.2,
      shareCap: 0.7,
      averageRiskAdjustment: 0.025,
    },
    {
      name: "Unlock",
      id: "unlock",
      logo: "https://uploads-ssl.webflow.com/60edefc65e33fa6df4738c0b/62ba3eef1d68e2949a6b1479_Logo_Unlock.png",
      states: [
        "Arizona",
        "California",
        "Colorado",
        "Florida",
        "Michigan",
        "Minnesota",
        "North Carolina",
        "New Jersey",
        "Nevada",
        "Oregon",
        "South Carolina",
        "Tennessee",
        "Utah",
        "Virginia",
        "Washington",
      ],
      originationFeeMinPercent: 0.03,
      originationFeeMaxPercent: 0.03,
      originationFeeOther: 1050,
      maxTermYears: 10,
      homeValueCap: 0.4375,
      oltvCap: 0.8,
      dollarCap: 500000,
      appreciationCap: false,
      homeImprovementDeduction: 1,
      originalInvestmentPayback: 0,
      appreciationShare: 0,
      homeShare: 1.65,
      initialValueAdj: 1,
      aprCapMin: false,
      aprCapMax: 0,
      shareCap: 0.7,
      averageRiskAdjustment: 0,
    },
    {
      name: "Point Home Equity",
      id: "point",
      logo: "https://uploads-ssl.webflow.com/60edefc65e33fa6df4738c0b/62ba3f01b8d40c5a8a6ffb06_Logo_Point.png",
      states: [
        "Arizona",
        "California",
        "Colorado",
        "Florida",
        "Illinois",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "New Jersey",
        "New York",
        "North Carolina",
        "Ohio",
        "Oregon",
        "Pennsylvania",
        "Virginia",
        "Washington",
        "Washington D.C.",
      ],
      originationFeeMinPercent: 0.03,
      originationFeeMaxPercent: 0.05,
      originationFeeOther: 1050,
      maxTermYears: 30,
      homeValueCap: 0.225,
      oltvCap: 0.8,
      dollarCap: 500000,
      appreciationCap: 0.2,
      homeImprovementDeduction: 0,
      originalInvestmentPayback: 1,
      appreciationShare: 3,
      homeShare: 0,
      initialValueAdj: 0.775,
      aprCapMin: 0.15,
      aprCapMax: 0.2,
      shareCap: 0.8,
      averageRiskAdjustment: 0.225,
    },
    {
      name: "Hometap",
      id: "hometap",
      logo: "https://uploads-ssl.webflow.com/60edefc65e33fa6df4738c0b/62ba3ee7fd847a47de0edf65_Logo_Hometap.png",
      states: [
        "Arizona",
        "California",
        "Florida",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Nevada",
        "New Jersey",
        "New York",
        "North Carolina",
        "Ohio",
        "Oregon",
        "Pennsylvania",
        "South Carolina",
        "Utah",
        "Virginia",
        "Washington",
      ],
      originationFeeMinPercent: 0.03,
      originationFeeMaxPercent: 0.03,
      originationFeeOther: 1050,
      maxTermYears: 10,
      homeValueCap: 0.3,
      oltvCap: 0.75,
      dollarCap: 600000,
      appreciationCap: 0.2,
      homeImprovementDeduction: 0,
      originalInvestmentPayback: 0,
      appreciationShare: 0,
      homeShare: 1.6,
      initialValueAdj: 0.84,
      aprCapMin: 0.15,
      aprCapMax: 0.2,
      shareCap: 0.49,
      averageRiskAdjustment: 0.16,
    },
  ],
  personalProviders: [
    {
      name: "Lightstream",
      rows: [
        {
          loanAmountMin: 5000,
          loanAmountMax: 9999.99,
          cells: [
            {
              monthsMin: 24,
              monthsMax: 36,
              min: 0.0749,
              max: 0.1549,
            },
            {
              monthsMin: 37,
              monthsMax: 48,
              min: 0.0809,
              max: 0.1609,
            },
            {
              monthsMin: 49,
              monthsMax: 60,
              min: 0.0849,
              max: 0.1649,
            },
            {
              monthsMin: 61,
              monthsMax: 72,
              min: 0.0879,
              max: 0.1699,
            },
          ],
        },
        {
          loanAmountMin: 10000,
          loanAmountMax: 24999.99,
          cells: [
            {
              monthsMin: 24,
              monthsMax: 36,
              min: 0.0549,
              max: 0.1449,
            },
            {
              monthsMin: 37,
              monthsMax: 48,
              min: 0.0609,
              max: 0.1509,
            },
            {
              monthsMin: 49,
              monthsMax: 60,
              min: 0.0649,
              max: 0.1549,
            },
            {
              monthsMin: 61,
              monthsMax: 72,
              min: 0.0679,
              max: 0.1599,
            },
            {
              monthsMin: 73,
              monthsMax: 84,
              min: 0.0699,
              max: 0.1629,
            },
          ],
        },
        {
          loanAmountMin: 25000,
          loanAmountMax: 49999.99,
          cells: [
            {
              monthsMin: 24,
              monthsMax: 36,
              min: 0.0499,
              max: 0.1449,
            },
            {
              monthsMin: 37,
              monthsMax: 48,
              min: 0.0599,
              max: 0.1509,
            },
            {
              monthsMin: 49,
              monthsMax: 60,
              min: 0.0629,
              max: 0.1549,
            },
            {
              monthsMin: 61,
              monthsMax: 72,
              min: 0.0634,
              max: 0.1599,
            },
            {
              monthsMin: 73,
              monthsMax: 84,
              min: 0.0639,
              max: 0.1629,
            },
            {
              monthsMin: 85,
              monthsMax: 144,
              min: 0.0644,
              max: 0.1649,
            },
          ],
        },
        {
          loanAmountMin: 50000,
          loanAmountMax: 100000,
          cells: [
            {
              monthsMin: 24,
              monthsMax: 36,
              min: 0.0499,
              max: 0.1449,
            },
            {
              monthsMin: 37,
              monthsMax: 48,
              min: 0.0599,
              max: 0.1509,
            },
            {
              monthsMin: 49,
              monthsMax: 60,
              min: 0.0629,
              max: 0.1549,
            },
            {
              monthsMin: 61,
              monthsMax: 72,
              min: 0.0634,
              max: 0.1599,
            },
            {
              monthsMin: 73,
              monthsMax: 84,
              min: 0.0639,
              max: 0.1629,
            },
            {
              monthsMin: 85,
              monthsMax: 144,
              min: 0.0644,
              max: 0.1649,
            },
          ],
        },
      ],
    },
  ],
};

export default lenders;
