import { ReactComponent as Progress } from "assets/images/shapes/progress.svg";
import PropTypes from "prop-types";
import Lottie from "lottie-react";
import checkmarkAnimation from "assets/animations/checkmark.json";

// @mui material components

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

function Page5({ nextPage }) {
  return (
    <>
      <MKBox display="flex" alignItems="center" justifyContent="center">
        <Lottie
          animationData={checkmarkAnimation}
          loop={false}
          style={{ height: 160, marginTop: -20, marginBottom: -90 }}
        />
      </MKBox>
      <MKBox py={2} sx={{ pt: 2, display: "flex", alignItems: "center", flexDirection: "column" }}>
        <MKTypography
          mt={2}
          variant="h4"
          color="text"
          fontWeight="bold"
          textAlign="center"
          maxWidth="420px"
        >
          We&apos;ll be in touch shortly
        </MKTypography>
        <MKTypography variant="body2" mt={1} mb={1} textAlign="center" maxWidth="420px">
          Look out for an email within 24 hours
        </MKTypography>
        <MKBox sx={{ maxWidth: "420px" }}>
          <MKTypography pl="24.5%" variant="body3" color="info" mb={0.5}>
            You are here!
          </MKTypography>
          <Progress style={{ width: "100%" }} />
          <MKBox
            maxWidth="80%"
            pl="10%"
            ml="-10%"
            display="flex"
            alignItems="center"
            textAlign="center"
            flexDirection="column"
          >
            <MKTypography color="primary" variant="body1" mb={0.25}>
              Loan Estimate
            </MKTypography>
            <MKTypography variant="body2" color="info" fontSize="14px">
              Get an estimate for your ADU loan.
            </MKTypography>
          </MKBox>
        </MKBox>
        <MKButton
          size="large"
          color="primary"
          sx={{ maxWidth: "420px", width: "100%", my: 2 }}
          fullWidth
          onClick={nextPage}
        >
          Close
        </MKButton>
      </MKBox>
    </>
  );
}

// Typechecking props for the Page3
Page5.propTypes = {
  nextPage: PropTypes.func.isRequired,
};

export default Page5;
