const stringToNumber = (input) => {
  return parseFloat(String(input).replace(/,/g, ""));
};

const numberToString = (input, decimals = 0) => {
  let formattedValue;
  if (typeof input === "number") {
    formattedValue = input.toLocaleString("en", {
      maximumFractionDigits: decimals,
    });
  } else {
    formattedValue = (Number(input.replace(/\D/g, "")) || "").toLocaleString("en", {
      maximumFractionDigits: decimals,
    });
  }
  return formattedValue;
};

const numShortener = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(2).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(0).replace(/\.0$/, "") + "k";
  }
  return num;
};

function formatDollars(value, decimals = 0) {
  let formattedValue;
  if (typeof value === "number") {
    formattedValue = `$${value.toLocaleString("en", {
      maximumFractionDigits: decimals,
    })}`;
  } else {
    formattedValue = `$${(Number(value.replace(/\D/g, "")) || "").toLocaleString("en", {
      maximumFractionDigits: decimals,
    })}`;
  }
  return formattedValue;
}

export { stringToNumber, numShortener, formatDollars, numberToString };
