// Material Kit 2 React components
import MKBox from "components/MKBox";
import PropTypes from "prop-types";

// Widget routes
import Default from "Lenders/Default";
import CalCon from "Lenders/CalCon";

const renderSwitch = (formId) => {
  switch (formId) {
    case "calcon":
      return <CalCon />;
    default:
      return <Default />;
  }
};

const WidgetPage = ({ formId }) => {
  return (
    <MKBox component="section">
      <MKBox
        display="flex"
        flexDirection="column"
        borderRadius="lg"
        bgColor="white"
        maxWidth="550px"
        width="100%"
      >
        {renderSwitch(formId)}
      </MKBox>
    </MKBox>
  );
};

// Typechecking props for the Page1
WidgetPage.propTypes = {
  formId: PropTypes.string,
};

export default WidgetPage;
