import PropTypes from "prop-types";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";

import MKTypography from "components/MKTypography";
import useWindowSize from "Hooks/useWindowSize";

const Type = ({ type, setType }) => {
  const { width } = useWindowSize();
  return (
    <>
      <Grid container maxWidth="900px" textAlign="center" spacing={2} pb={4}>
        <Grid item xs={12}>
          <MKTypography variant="body2">2 / 3</MKTypography>
          <MKTypography variant="h3" fontWeight="regular">
            What type of home are you looking for?
          </MKTypography>
        </Grid>
        <Grid item xs={12}>
          <ToggleButtonGroup
            color="primary"
            sx={{
              alignItems: "center",
              mx: 0,
              px: 0,
              mb: 4,
              "& .Mui-selected": {
                backgroundColor: "#F6F7FF !important",
                opacity: "1 !important",
                borderColor: "#2A2CB1 !important",
                border: "1px solid !important",
              },
            }}
            value={type}
            exclusive
            fullWidth
            orientation={width < 768 ? "vertical" : "horizontal"}
            onChange={(_, v) => setType(v)}
          >
            <ToggleButton
              value="Office"
              name="project"
              sx={{
                p: 0.5,
                fontWeight: "normal",
                fontSize: "16px",
                textTransform: "none !important",
                borderRadius: "5px !important",
                marginRight: { xs: 0, md: 1 },
                marginBottom: { xs: 1, md: 0 },
                height: "250px",
                maxWidth: "300px",
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
                backgroundImage:
                  "linear-gradient(#00000080, #00000080),url(https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/60edefc65e33fa0cb5738f2f_thumbnail-HomeOffice.jpg)",
                backgroundSize: "cover",
                justifyContent: "end",
                opacity: 0.5,
                "&:hover": {
                  opacity: 1,
                },
                "& .Mui-selected": {
                  backgroundColor: "#F6F7FF !important",
                  opacity: "1 !important",
                },
              }}
            >
              <MKTypography variant="h4" color="white" mb={2}>
                Studio Office
              </MKTypography>
            </ToggleButton>
            <ToggleButton
              value="ADU"
              name="project"
              sx={{
                p: 0.5,
                fontWeight: "normal",
                fontSize: "16px",
                textTransform: "none !important",
                borderRadius: "5px !important",
                marginRight: { xs: 0, md: 1 },
                marginBottom: { xs: 1, md: 0 },
                height: "250px",
                maxWidth: "300px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "end",
                width: "100%",
                backgroundImage:
                  "linear-gradient(#00000080, #00000080),url(https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/60edefc65e33fa4f6e738f2e_thumbnail-BackyardHome.jpg)",
                backgroundSize: "cover",
                opacity: 0.5,
                "&:hover": {
                  opacity: 1,
                },
              }}
            >
              <MKTypography variant="h4" color="white" mb={2}>
                ADU (guesthouse)
              </MKTypography>
            </ToggleButton>
            <ToggleButton
              value="Primary Home"
              name="project"
              sx={{
                p: 0.5,
                fontWeight: "normal",
                fontSize: "16px",
                textTransform: "none !important",
                borderRadius: "5px !important",
                height: "250px",
                maxWidth: "300px",
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "end",
                backgroundImage:
                  "linear-gradient(#00000080, #00000080), url(https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/60edefc65e33fa727d738f30_thumbnail-PrimaryHome.jpg)",
                backgroundSize: "cover",
                opacity: 0.5,
                "&:hover": {
                  opacity: 1,
                },
              }}
            >
              <MKTypography variant="h4" color="white" mb={2}>
                Primary Home
              </MKTypography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </>
  );
};

// Typechecking props for the Page3
Type.propTypes = {
  type: PropTypes.number,
  setType: PropTypes.func,
};

export default Type;
