import rates from "../loanGrids/constructionSecond/rates.json";
import adjustors from "../loanGrids/constructionSecond/adjustors.json";

// returns a min and max apr if available or the highest available APR if unavailable
const loanCalc = (creditScore, principal, currentDebt, homeValue, propertyType) => {
  // translate credit score into max and min
  let numericCreditScore;
  switch (creditScore) {
    case "bad":
      numericCreditScore = [300, 629];
      break;
    case "fair":
      numericCreditScore = [630, 689];
      break;
    case "good":
      numericCreditScore = [690, 719];
      break;
    case "excellent":
      numericCreditScore = [720, 850];
      break;
    default:
      numericCreditScore = [300, 850];
  }
  const cltv = (principal + currentDebt) / homeValue;
  const cltvRates = rates.rows.find((r) => cltv >= r.cltvMin && cltv <= r.cltvMax);
  let lowApr = cltvRates?.cells.find((r) => numericCreditScore[1] >= r.creditScoreMin)?.apr;
  let highApr = cltvRates?.cells.find((r) => numericCreditScore[0] >= r.creditScoreMin)?.apr;
  let adjusterAduStandalone = adjustors.aduStandalone.find(
    (a) => cltv >= a.cltvMin && cltv <= a.cltvMax
  );
  const aduRateAdjustment = adjusterAduStandalone?.rateAdjust || 0;
  let adjusterPropertyType = adjustors[propertyType]?.rateAdd || 0;
  lowApr = parseFloat((lowApr + aduRateAdjustment + adjusterPropertyType).toFixed(4));
  highApr = parseFloat((highApr + aduRateAdjustment + adjusterPropertyType).toFixed(4));
  return [lowApr || 0.1325, highApr || 0.1325];
};

export default loanCalc;
