import { Chip, Divider, Grid } from "@mui/material";

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import { ReactComponent as Loan } from "assets/images/shapes/loan.svg";

const Design = () => {
  return (
    <MKBox
      width="100%"
      sx={{
        backgroundColor: "#fff",
        px: { xs: 0, md: 5 },
        py: { xs: 2, md: 5 },
        borderRadius: 2,
        boxShadow: "0px 0px 10px 2px rgba(0,0,0,0.1)",
      }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        container
        maxWidth="720px"
        width="100%"
        textAlign="center"
        columnSpacing={3}
        rowSpacing={1}
        mt={0}
        columns={20}
        sx={{ px: 2 }}
      >
        <Grid item xs={20} display="flex" justifyContent="center" mb={4}>
          <MKBox maxWidth="350px">
            <MKTypography variant="h4" mb={1}>
              Reserve your dream home
            </MKTypography>
            <MKTypography variant="body2" lineHeight={1}>
              The last step to getting your project started is assembling your team and locking in
              production
            </MKTypography>
          </MKBox>
        </Grid>
        <Grid item xs={20} lg={7} display="flex" justifyContent="center">
          <MKBox height="140px">
            <img
              src="https://uploads-ssl.webflow.com/60edefc65e33fa6df4738c0b/60edefc65e33fa30ae7393c3_Connect-2-exterior-garden.jpeg"
              style={{ width: "100%", borderRadius: "8px", height: "100%", objectFit: "cover" }}
            />
          </MKBox>
        </Grid>
        <Grid
          item
          xs={14}
          lg={8}
          textAlign="left"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <MKTypography variant="h4" fontWeight="regular" mb={0.25}>
            Reserve your builder
          </MKTypography>
          <MKTypography variant="body2" fontSize="13px" mb={1}>
            Est: $159,000 - $200,999
          </MKTypography>
          <MKBox display="inline-block">
            <Chip
              label="Connect Homes"
              sx={{
                borderRadius: 0,
                backgroundColor: "light.lighter",
              }}
            />
          </MKBox>
        </Grid>
        <Grid
          item
          xs={6}
          lg={5}
          display="flex"
          alignItems="center"
          sx={{ justifyContent: { xs: "end", lg: "end" } }}
        >
          <MKButton
            color="primary"
            size="large"
            sx={{
              minHeight: 0,
              lineHeight: 1,
              py: 1.75,
              px: 5,
              borderColor: "grey.400",
              width: "140px",
            }}
            variant="outlined"
            disabled
          >
            Reserve
          </MKButton>
        </Grid>
        <Grid item xs={20}>
          <Divider fullWidth sx={{ my: 2 }} />
        </Grid>
        <Grid item xs={20} lg={7} display="flex" justifyContent="start" alignItems="center">
          <MKBox
            height="140px"
            sx={{ border: "1px solid", borderColor: "grey.300" }}
            display="flex"
            alignItems="center"
            px={1}
            width="100%"
            borderRadius="8px"
          >
            <Loan sx={{ width: "100%" }} />
          </MKBox>
        </Grid>
        <Grid
          item
          xs={14}
          lg={8}
          textAlign="left"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <MKTypography variant="h4" fontWeight="regular" mb={0.25}>
            Qualify for a loan
          </MKTypography>
          <MKTypography variant="body2" fontSize="13px" mb={1}>
            Est: $1340 / month
          </MKTypography>
          <MKBox display="inline-block">
            <Chip
              label="CalCon Mutual"
              sx={{
                borderRadius: 0,
                backgroundColor: "light.lighter",
              }}
            />
          </MKBox>
        </Grid>
        <Grid
          item
          xs={6}
          lg={5}
          display="flex"
          alignItems="center"
          sx={{ justifyContent: { xs: "end", lg: "end" } }}
        >
          <MKButton
            color="primary"
            size="large"
            sx={{
              minHeight: 0,
              lineHeight: 1,
              py: 1.75,
              px: 5,
              borderColor: "grey.400",
              width: "140px",
            }}
            variant="outlined"
            disabled
          >
            Start
          </MKButton>
        </Grid>
      </Grid>
    </MKBox>
  );
};

export default Design;
