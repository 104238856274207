import { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useAuth } from "@memberstack/react";

import { Grid, Container, Tabs, Tab } from "@mui/material";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { Check } from "@mui/icons-material";

import Authentication from "Context/auth";

import Navbar from "Users/Components/Navbar";

import useWindowSize from "Hooks/useWindowSize";

import Zoning from "./Zoning";
import Design from "./Design";
import Reserve from "./Reserve";

const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <MKBox sx={{ py: 3 }}>{children}</MKBox>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Dashboard = () => {
  const [tab, setTab] = useState(0);
  const { height } = useWindowSize();
  const { jwt } = useContext(Authentication);
  const { isLoggedIn } = useAuth();

  useEffect(async () => {
    if (isLoggedIn && jwt) {
      console.log("logged in");
    }
  }, [isLoggedIn, jwt]);

  const handleTab = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <Container
        sx={{
          minHeight: `${height}px`,
          pb: 3,
          pt: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Navbar />
        <Grid container maxWidth="xl" sx={{ pt: 2, alignItems: "start" }} spacing={6}>
          <Grid
            item
            xs={12}
            lg={3}
            sx={{
              display: { xs: "none", lg: "flex" },
              flexDirection: "column",
              position: { sx: "inherit", md: "sticky" },
              top: "0",
            }}
          >
            <MKBox mb={3}>
              <MKTypography variant="h6" fontWeight="regular">
                Hi Wade!
              </MKTypography>
              <MKTypography variant="h3" fontWeight="regular" lineHeight={1.25} my={1}>
                Welcome to your Project Planner
              </MKTypography>
              <MKTypography variant="h5" fontWeight="regular">
                Next, let&apos;s find a design that works for you
              </MKTypography>
            </MKBox>
            <MKBox display="flex" flexDirection="row" alignItems="center" mb={1}>
              <Check
                sx={{
                  fontSize: "20px !important",
                  color: "grey.100",
                  backgroundColor: "#646A96",
                  padding: 0.15,
                  mr: 0.75,
                  borderRadius: "50%",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: "#646A96",
                  mt: -0.35,
                }}
              />
              <MKTypography
                variant="body2"
                fontWeight="regular"
                sx={{ color: "#646A96", textDecoration: "line-through" }}
              >
                Select a design
              </MKTypography>
            </MKBox>
            <MKBox display="flex" flexDirection="row" alignItems="center" mb={1}>
              <Check
                sx={{
                  fontSize: "20px !important",
                  color: "grey.100",
                  backgroundColor: "#646A96",
                  padding: 0.15,
                  mr: 0.75,
                  borderRadius: "50%",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: "#646A96",
                  mt: -0.35,
                }}
              />
              <MKTypography
                variant="body2"
                fontWeight="regular"
                sx={{ color: "#646A96", textDecoration: "line-through" }}
              >
                Zoning feasibility
              </MKTypography>
            </MKBox>
            <MKBox display="flex" flexDirection="row" alignItems="center" mb={1}>
              <Check
                color="primary"
                sx={{
                  fontSize: "20px !important",
                  backgroundColor: "#E6EAFF",
                  padding: 0.15,
                  mr: 0.75,
                  borderRadius: "50%",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: "primary.main",
                  mt: -0.35,
                }}
              />
              <MKTypography variant="body2" fontWeight="bold" color="primary">
                Designs & bids
              </MKTypography>
            </MKBox>
            <MKBox display="flex" flexDirection="row" alignItems="center" mb={1}>
              <Check
                sx={{
                  fontSize: "20px !important",
                  color: "grey.500",
                  padding: 0.15,
                  mr: 0.75,
                  borderRadius: "50%",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: "grey.500",
                  mt: -0.35,
                }}
              />
              <MKTypography variant="body2" fontWeight="regular" sx={{ color: "#646A96" }}>
                Reserve & build
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={9}>
            <Tabs
              value={tab}
              onChange={handleTab}
              indicatorColor="primary"
              variant="fullWidth"
              textColor="primary"
              sx={{ backgroundColor: "grey.300", display: "flex" }}
            >
              <Tab label="Zoning" sx={{ flex: 1 }} />
              <Tab label="Design & Bids" style={{ flex: 1 }} />
              <Tab label="Reserve & Production" style={{ flex: 1 }} />
            </Tabs>
            <TabPanel value={tab} index={0}>
              <Zoning />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <MKBox display="flex" flexDirection="column" alignItems="center">
                <MKTypography alignSelf="start" variant="h6" style={{ marginBottom: "10px" }}>
                  Your selected homes
                </MKTypography>
                <Design />
                <MKButton
                  variant="text"
                  color="info"
                  size="large"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    textDecoration: "underline",
                    mt: 3,
                    py: 0,
                    minHeight: "1rem",
                  }}
                >
                  + Add more designs
                </MKButton>
                <MKTypography variant="body2" color="info">
                  You can compare multiple designs
                </MKTypography>
              </MKBox>
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <Reserve />
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Dashboard;
