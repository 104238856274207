// Material Kit 2 React base styles
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";

const { size } = typography;
const { text, transparent } = colors;

export default {
  styleOverrides: {
    root: {
      boxShadow: "none",
      padding: 0,
      marginTop: "0px !important",
      fontSize: size.sm,
      color: text.main,
      border: "none",
      backgroundColor: transparent.main,
      "&:before": {
        display: "none",
      },
      "& .MuiAccordionSummary-root": {
        height: "25px",
        marginTop: 0,
        justifyContent: "start",
        padding: 0,
        minHeight: "0 !important",
      },
      "& .MuiAccordionSummary-content": {
        flexGrow: 0,
        margin: "0px !important",
        paddding: "0px 5px",
        justifySelf: "start",
      },
      "& .MuiAccordionSummary-expandIconWrapper": {
        padding: "5px 10px",
      },
      "& .MuiAccordionDetails-root": {
        padding: 0,
      },
    },
  },
};
