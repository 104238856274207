import { useState, useEffect } from "react";
import PropTypes from "prop-types";

// @mui material components
import Slider from "@mui/material/Slider";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

// @mui icons
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

// Material Kit 2 React components
import MKBadge from "components/MKBadge";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import calHFALimits from "helpers/calHFALimts";
import { numShortener, stringToNumber } from "helpers/formatters";
import loanCalc from "../calculator";

const followersMarks = [
  {
    value: 0,
    scaledValue: 1000,
    label: "1k",
  },
  {
    value: 25,
    scaledValue: 5000,
    label: "5k",
  },
  {
    value: 50,
    scaledValue: 10000,
    label: "10k",
  },
  {
    value: 75,
    scaledValue: 25000,
    label: "25k",
  },
  {
    value: 100,
    scaledValue: 50000,
    label: "50k",
  },
  {
    value: 125,
    scaledValue: 100000,
    label: "100k",
  },
  {
    value: 150,
    scaledValue: 250000,
    label: "250k",
  },
  {
    value: 175,
    scaledValue: 500000,
    label: "500k",
  },
  {
    value: 200,
    scaledValue: 1000000,
    label: "1M+",
  },
];

function Page2({ nextPage, form, updateForm }) {
  const [calHFAEligible, setCalHFAEligible] = useState(false);
  const [selectedStep, setSelectedStep] = useState(0);
  const [indIncomeDescaled, setIndIncomeDescaled] = useState(0);
  const [hsIncomeDescaled, setHsIncomeDescaled] = useState(0);
  const [aprArr, setAprArr] = useState([]);
  const [calHFAIncomeRequirements, setCalHFAIncomeRequirements] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (form.address.state_code === "CA" && form.propertyType === "Primary Residence") {
      setCalHFAEligible(true);
    }
  }, []);

  useEffect(() => {
    const potentialAprArr = loanCalc(
      form.creditScore,
      stringToNumber(form.desiredAmount),
      stringToNumber(form.currentDebt),
      (form.homeValue[0] + form.homeValue[1]) / 2,
      form.propertyType
    );
    setAprArr(potentialAprArr);
    updateForm("potentialRates", potentialAprArr);
  }, [form.creditScore]);

  useEffect(() => {
    // lowercase and remove "county" from address.county for comparison with hfa limits
    let cleanedCounty = form.address.county;
    cleanedCounty = cleanedCounty
      .toLowerCase()
      .replace(/county/g, "")
      .trim();
    if (calHFALimits[cleanedCounty]) {
      setCalHFAIncomeRequirements(calHFALimits[cleanedCounty]);
    } else {
      // if county isn't matched, set to the lowest income requirement of $159,000
      setCalHFAIncomeRequirements(159000);
    }
  }, [form.address.county]);

  const scale = (value) => {
    if (typeof value !== "number") {
      return 0;
    }
    const previousMarkIndex = Math.floor(value / 25);
    const previousMark = followersMarks[previousMarkIndex];
    const remainder = value % 25;
    if (remainder === 0) {
      return previousMark.scaledValue;
    }
    const nextMark = followersMarks[previousMarkIndex + 1];
    const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
    const calc = remainder * increment + previousMark.scaledValue;
    return calc;
  };

  const scale2 = (value) => {
    if (typeof value !== "number") {
      return 0;
    }
    const previousMarkIndex = Math.floor(value / 25);
    const previousMark = followersMarks[previousMarkIndex];
    const remainder = value % 25;
    if (remainder === 0) {
      return previousMark.scaledValue;
    }
    const nextMark = followersMarks[previousMarkIndex + 1];
    const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
    const calc = remainder * increment + previousMark.scaledValue;
    return calc;
  };

  useEffect(() => {
    setSelectedStep(form.individualIncomeScaled);
    setIndIncomeDescaled(scale(form.individualIncomeScaled));
  }, [form.individualIncomeScaled]);

  useEffect(() => {
    setHsIncomeDescaled(scale2(form.householdIncomeScaled));
  }, [form.householdIncomeScaled]);

  const handleCreditScore = (_, v) => {
    updateForm("creditScore", v);
  };

  const handleContinue = () => {
    setError(false);

    if (!form.individualIncomeScaled || !form.creditScore) {
      setError(true);
      return;
    }
    // set whether calhfa eligible
    if (calHFAEligible && form.individualIncome <= calHFAIncomeRequirements) {
      updateForm("calHfaEligible", true);
    }
    // if household income has not been set, set it to individual income
    if (!form.householdIncomeScaled && form.individualIncomeScaled) {
      updateForm("householdIncome", indIncomeDescaled);
    } else {
      updateForm("householdIncome", hsIncomeDescaled);
    }
    updateForm("individualIncome", indIncomeDescaled);
    nextPage();
  };

  return (
    <>
      <MKBox
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          px: 1,
        }}
      >
        <MKTypography mt={2} variant="h4" color="text" fontWeight="bold" textAlign="center">
          {calHFAEligible
            ? `Your ${form.address.city} property may qualify for a $40,000 grant`
            : "Financial Information"}
        </MKTypography>
        <MKTypography variant="body2" mt={1} mb={1} textAlign="center" maxWidth="420px">
          {calHFAEligible
            ? "For California homeowners, CalHFA has created a grant to ease the burden of upfront ADU costs."
            : "Your income and credit score are important for loan estimates."}
        </MKTypography>
        <MKBox
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            maxWidth: "420px",
            width: "100%",
            mt: 2,
          }}
        >
          <MKTypography
            textTransform="uppercase"
            variant="caption"
            fontWeight="bold"
            color="text"
            fontSize="0.675rem"
            sx={{ alignSelf: "start", mb: 2.25 }}
          >
            Individual Income <span style={{ fontSize: "9px", fontWeight: 400 }}>(per year)</span>
          </MKTypography>
          <MKBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "420px",
              width: { xs: "95%", iframe: "100%" },
            }}
          >
            <Slider
              getAriaLabel={() => "Individual Income"}
              name="individualIncome"
              onChange={(_, v) => updateForm("individualIncomeScaled", v)}
              valueLabelDisplay="on"
              marks={followersMarks}
              scale={scale}
              className={`${error && !form.individualIncomeScaled ? "MuiSlider-error" : ""}`}
              value={form.individualIncomeScaled}
              min={0}
              max={200}
              step={1}
              valueLabelFormat={(l) => (l === 1000000 ? `$1M+` : `$${numShortener(l)}`)}
            />
          </MKBox>
        </MKBox>
        <MKBox
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            maxWidth: "420px",
            width: "100%",
            mt: 2,
          }}
        >
          <MKTypography
            textTransform="uppercase"
            variant="caption"
            fontWeight="bold"
            color="text"
            fontSize="0.675rem"
            sx={{ alignSelf: "start", mb: 2.25 }}
          >
            Household Income <span style={{ fontSize: "9px", fontWeight: 400 }}>(per year)</span>
          </MKTypography>
          <MKBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "420px",
              width: { xs: "95%", iframe: "100%" },
              mb: 1,
            }}
          >
            <Slider
              sx={{ maxWidth: "100%" }}
              getAriaLabel={() => "Household Income"}
              name="householdIncome"
              onChange={(_, v) => updateForm("householdIncomeScaled", v)}
              valueLabelDisplay="on"
              marks={followersMarks.filter((s) => s.value >= selectedStep)}
              scale={scale2}
              className={`${error && !form.householdIncomeScaled ? "MuiSlider-error" : ""}`}
              value={form.householdIncomeScaled || 0}
              min={selectedStep}
              max={200}
              step={1}
              valueLabelFormat={(l) => (l === 1000000 ? `$1M+` : `$${numShortener(l)}`)}
            />
          </MKBox>
        </MKBox>

        <MKBox
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            maxWidth: "420px",
            width: "100%",
          }}
        >
          <MKTypography
            textTransform="uppercase"
            variant="caption"
            fontWeight="bold"
            color="text"
            fontSize="0.675rem"
            my={0.5}
            sx={{ alignSelf: "start" }}
          >
            Credit Score
          </MKTypography>
          <ToggleButtonGroup
            color="primary"
            sx={{
              alignItems: "center",
              mx: 0,
              px: 0,
              "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                borderColor: error && !form.creditScore ? "error.main" : "rgba(0,0,0,0.12) ",
              },
              "& .Mui-selected": {
                backgroundColor: "#F6F7FF !important",
                borderColor: "#2A2CB1 !important",
              },
            }}
            exclusive
            fullWidth
            value={form.creditScore}
            onChange={handleCreditScore}
          >
            <ToggleButton
              value="bad"
              sx={{
                fontWeight: "bold",
                borderRadius: "5px !important",
                borderColor:
                  error && !form.creditScore ? "error.main" : "rgba(0,0,0,0.12) !important",
                marginRight: { xs: 0.5, iframe: 1 },
                textTransform: "none !important",
              }}
            >
              300-629
            </ToggleButton>
            <ToggleButton
              value="fair"
              sx={{
                fontWeight: "bold",
                borderColor:
                  error && !form.creditScore ? "error.main" : "rgba(0,0,0,0.12) !important",
                borderRadius: "5px !important",
                marginRight: { xs: 0.5, iframe: 1 },
                textTransform: "none !important",
              }}
            >
              630-689
            </ToggleButton>
            <ToggleButton
              value="good"
              sx={{
                fontWeight: "bold",
                borderColor:
                  error && !form.creditScore ? "error.main" : "rgba(0,0,0,0.12) !important",
                borderRadius: "5px !important",
                marginRight: { xs: 0.5, iframe: 1 },
                textTransform: "none !important",
              }}
            >
              690-719
            </ToggleButton>
            <ToggleButton
              value="excellent"
              sx={{
                fontWeight: "bold",
                borderColor:
                  error && !form.creditScore ? "error.main" : "rgba(0,0,0,0.12) !important",
                borderRadius: "5px !important",
                marginLeft: { xs: 0.5, iframe: 1 },
                textTransform: "none !important",
              }}
            >
              720+
            </ToggleButton>
          </ToggleButtonGroup>
        </MKBox>
        <MKBox
          sx={{
            height: "25px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mt: 2,
          }}
        >
          {calHFAEligible && form.creditScore && indIncomeDescaled <= calHFAIncomeRequirements && (
            <MKTypography variant="body5" color="text" lineHeight="0.5">
              💰 Yep! You may qualify for up to{" "}
              <MKBadge color="success" variant="contained" size="sm" badgeContent="$40k" /> of grant
              money!
            </MKTypography>
          )}
          {!calHFAEligible &&
            (!!form.individualIncomeScaled || !!form.householdIncomeScaled) &&
            form.creditScore && (
              <MKTypography variant="body5" color="text">
                🎉 Good news, you may qualify for a rate as low as{" "}
                <MKBadge
                  color="success"
                  variant="contained"
                  size="sm"
                  badgeContent={`${(aprArr[0] * 100).toFixed(2)}%`}
                />
              </MKTypography>
            )}
        </MKBox>
        <MKButton
          size="large"
          color="primary"
          sx={{ maxWidth: "420px", width: "100%", mt: 2, mb: 4 }}
          fullWidth
          onClick={handleContinue}
        >
          <LockOutlinedIcon color="light" sx={{ mr: "10px" }} />
          Continue
        </MKButton>
      </MKBox>
    </>
  );
}

// Typechecking props for the Page1
Page2.propTypes = {
  nextPage: PropTypes.func.isRequired,
  form: PropTypes.shape({
    homeValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    desiredAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currentDebt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    propertyType: PropTypes.string,
    address: PropTypes.shape({
      state_code: PropTypes.string,
      county: PropTypes.string,
      city: PropTypes.string,
    }),
    individualIncome: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    individualIncomeScaled: PropTypes.number,
    householdIncome: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    householdIncomeScaled: PropTypes.number,
    creditScore: PropTypes.string,
  }).isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default Page2;
