import { useState } from "react";

import { Chip, Divider, Grid, Stack } from "@mui/material";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { QuestionMark } from "@mui/icons-material";

import ProjectQuoteModal from "Users/Project/Modals/ProjectQuote";
import FinancingQuoteModal from "Users/Project/Modals/FinancingQuote";

const labels = ["1 Bed", "1 Bath", "12' x 18'", "480sf"];

const Design = () => {
  const [modal, setModal] = useState({ projectQuote: false, financingQuote: false });

  return (
    <>
      <ProjectQuoteModal
        open={modal.projectQuote}
        handleClose={() => setModal({ ...modal, projectQuote: false })}
      />
      <FinancingQuoteModal
        open={modal.financingQuote}
        handleClose={() => setModal({ ...modal, financingQuote: false })}
      />
      <MKBox
        width="100%"
        sx={{
          backgroundColor: "#fff",
          p: 0,
          pb: 6,
          borderRadius: 2,
          boxShadow: "0px 0px 10px 2px rgba(0,0,0,0.1)",
        }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <img
          src="https://uploads-ssl.webflow.com/60edefc65e33fa6df4738c0b/60edefc65e33fa30ae7393c3_Connect-2-exterior-garden.jpeg"
          style={{ width: "100%", borderRadius: "8px", height: "300px", objectFit: "cover" }}
        />
        <Grid
          container
          maxWidth="100%"
          width="100%"
          textAlign="center"
          spacing={2}
          mt={0}
          sx={{ px: 2 }}
        >
          <Grid item xs={12}></Grid>
          <Grid item xs={12} lg={5} textAlign="left">
            <MKTypography variant="h3" fontWeight="regular">
              Connect 2
            </MKTypography>
            <MKTypography variant="body2" mt={-0.5} mb={1}>
              by Connect Homes
            </MKTypography>
            <Stack direction="row" spacing={1}>
              {labels.map((l) => (
                <Chip
                  key={l}
                  label={l}
                  sx={{ borderRadius: 0, backgroundColor: "light.lighter" }}
                />
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12} lg={7} textAlign="left" mt={1} display="flex" flexDirection="column">
            <MKBox display="flex" flexDirection="row">
              <MKBox>
                <MKTypography variant="body2">Project Estimate</MKTypography>
                <MKButton
                  variant="text"
                  color="primary"
                  size="large"
                  sx={{ p: 0, fontSize: "22px", fontWeight: "regular", minHeight: "1rem" }}
                  onClick={() => setModal({ ...modal, projectQuote: true })}
                >
                  Get bid <ArrowOutwardIcon />
                </MKButton>
              </MKBox>
              <Divider
                orientation="vertical"
                sx={{ mx: { xs: "20px", lg: "50px" }, maxHeight: "50px", mt: 0.5 }}
              />
              <MKBox>
                <MKTypography variant="body2">Financing</MKTypography>
                <MKButton
                  variant="text"
                  color="primary"
                  size="large"
                  sx={{ p: 0, fontSize: "22px", fontWeight: "regular", minHeight: "1rem" }}
                  onClick={() => setModal({ ...modal, financingQuote: true })}
                >
                  Get quote <ArrowOutwardIcon />
                </MKButton>
              </MKBox>
            </MKBox>
            <MKBox
              display="flex"
              flexDirection="row"
              alignItems="start"
              mt={2}
              sx={{ mr: { xs: 0, lg: 5 } }}
            >
              <QuestionMark
                sx={{
                  fontSize: "20px !important",
                  color: "#646A96",
                  padding: 0.15,
                  mr: 1,
                  borderRadius: "50%",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: "#646A96",
                  mt: 0.35,
                }}
              />
              <MKBox>
                <MKTypography variant="h6">Get started with a bid</MKTypography>
                <MKTypography variant="body2">
                  You can select multiple designs to get a bid. Select &quot;Get bid&quot; to start.
                </MKTypography>
              </MKBox>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={5}></Grid>
        </Grid>
      </MKBox>
    </>
  );
};

export default Design;
