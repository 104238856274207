const initialForm = {
  stepCount: 0,
  checked: [],
  address: {},
  homeValue: 100000,
  textInput: "",
  checkbox: false,
  switch: false,
  conversionCheck: false,
  onboarding: {
    fname: "",
    lname: "",
    address: { formatted: "" },
    timeline: "",
    project: "",
    quality: "",
  },
  detachedCheck: false,
  attachedCheck: false,
  duplexCheck: false,
  maxSizeNum: undefined,
  setbacksSideNum: undefined,
  setbacksRearNum: undefined,
  setbacksDwellingNum: undefined,
  maxHeightNum: undefined,
  unitsNum: undefined,
  accessText: "",
  parkingNum: undefined,
  aestheticText: "",
  hoaText: "",
  cost: {
    model: "",
    access: 0,
    default: 0,
    sqFtInput: false,
    turnkey: 0,
    zip: "",
    state: "",
    stateName: "",
    costFactor: 1,
    condition: 0,
    buildType: 0,
    bedrooms: 0,
    bathrooms: 0,
    quality: 0,
    sqFt: 400,
    costPerSqFt: 0,
    hardCosts: [],
    softCosts: [],
    totalHardCosts: 0,
    totalSoftCosts: 0,
    totalCosts: 0,
    amountDown: 0,
    loanAmount: 0,
    estimatedRent: 0,
    estimatedCost: 0,
    email: "",
  },
  bidCheck: false,
  contractCheck: false,
  lenderCheck: false,
  depositCheck: false,
};

export default initialForm;
