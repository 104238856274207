const initialSteps = [
  {
    id: "intro",
    chapter: "Intro",
    title: "Intro",
    goal: "Together, we'll build a complete ADU project, including a budget, floor plan, code requirements, financing, and a top-tier ADU builder.",
    time: "1 minute",
    img: {
      local: true,
      src: "intro.png",
      alt: "Completed ADU",
      style: { marginBottom: "20px" },
    },
    components: [
      { type: "spacer", id: "955881", space: 1.5 },
      {
        type: "typography",
        subtype: "h4",
        fontWeight: "regular",
        content: "Launching a new ADU Project is now easy peasy.",
        id: "complex",
      },
      { type: "spacer", id: "s1009", space: 1.5 },
      {
        type: "typography",
        subtype: "h5",
        fontWeight: "regular",
        content:
          "Dwellito's AutoAdvisor is a tailored guide to get you from concept to move-in ready ADU.",
        id: "complex1",
      },
      { type: "spacer", id: "s0", space: 1.5 },
      { type: "typography", subtype: "h6", content: "Steps in this guide:", id: "aduOverview" },
      {
        type: "ol",
        id: "intro2",
        content: [
          {
            id: "350103",
            bolded: "Zoning and building codes: ",
            sentence:
              "Different cities and counties have different rules and regulations regarding ADUs. We’ll help you breeze through these laws and requirements before starting the process.",
          },
          {
            id: "686566",
            bolded: "Determining the budget: ",
            sentence:
              "The first step in buying an ADU is to determine how much you can afford to spend. We’ll help you get an estimate at the lowest rates possible.",
          },
          {
            id: "358962",
            bolded: "Find a reputable contractor: ",
            sentence:
              "Finding a reputable contractor is crucial to the success of your ADU project. We’ll help you get one with a great track record and a variety of design options to match your project.",
          },
          {
            id: "237088",
            bolded: "Design and floor plan: ",
            sentence:
              "There are many different design options available for ADUs, from prefabricated to custom-built. We’ll help you compare what works best for your space and budget.",
          },
          {
            id: "400235",
            bolded: "Secure financing: ",
            sentence:
              "Once you have a clear idea of your budget and the type of ADU you want, we’ll help you secure financing. We’ll help you compare loan options and choose one that works best for you.",
          },
        ],
      },
      { type: "spacer", id: "s3", space: 1.5 },
      {
        type: "typography",
        subtype: "h6",
        content: "What type can AutoAdvisor help me with?",
        id: "autoAdvisorHelp",
      },
      {
        type: "typography",
        subtype: "body2",
        fontWeight: "regular",
        content:
          "Dwellito's AutoAdvisor is tailored to make ADU projects dead simple. Our interactive guide will help you get a complete project packaged. Plan out your ADU the smart way with AutoAdvisor.",
        id: "autoAdvisorHelp1",
      },
      { type: "spacer", id: "s4", space: 1.5 },
    ],
    cta: "Mark Complete",
    nextUp: "Basic Info",
    validations: [],
  },
  {
    id: "basic",
    chapter: "Basic Info",
    title: "Basic Info",
    goal: "Let's start with the basics. We'll go over preferences and ideal design considerations.",
    time: "1 minute",
    img: "",
    components: [
      { type: "spacer", id: "218755", space: 1.5 },
      { type: "onboarding", id: "146392" },
    ],
    cta: "Continue to Intro",
    nextUp: "Intro",
    validations: [],
  },
  {
    id: "land",
    img: {
      local: true,
      src: "adu-types.svg",
      alt: "ADU Types",
    },
    chapter: "Land Use",
    title: "Lot Planning Use",
    goal: "Find out what you can build before spending a dime. In this section, you will get ADU requirements specific to your property.",
    time: "9 minutes",
    components: [
      { type: "spacer", id: "771219", space: 1.5 },
      {
        type: "typography",
        subtype: "h4",
        fontWeight: "regular",
        content: "Get ADU approved! Know what works for your property.",
        id: "lotplan1",
      },
      { type: "spacer", id: "s5", space: 1.5 },
      {
        type: "typography",
        subtype: "h5",
        fontWeight: "regular",
        content:
          "Our zoning and lot planning guide will answer the big questions about your ADU sizing and other lot requirements. ",
        id: "lotplan2",
      },
      { type: "spacer", id: "s6", space: 1.5 },
      { type: "typography", subtype: "h6", content: "What can I build?", id: "lotplan3" },
      {
        type: "typography",
        subtype: "body2",
        fontWeight: "regular",
        content: "You have options. Here are types of ADUs you could build in your backyard:",
        id: "lotplan4",
      },
      { type: "spacer", id: "s8", space: 1 },
      {
        type: "ul",
        id: "lotplan6",
        content: [
          {
            id: "4312314436",
            bolded: "A duplex ",
            sentence:
              "is a building that contains two separate units, typically with one unit on the first floor and one unit on the second floor.",
          },
          {
            id: "4312314432",
            bolded: "An ADU",
            sentence:
              ", or accessory dwelling unit, is a small secondary dwelling on the same property as a single-family home, usually sized between 500 and 1200 square feet. It can be attached or detached from the main home.",
          },
          {
            id: "2321321321",
            bolded: "An ADU garage conversion ",
            sentence: "is the process of converting an existing garage into a living space or ADU.",
          },
          {
            id: "4312314431",
            bolded: "A detached ADU ",
            sentence:
              "is an ADU that is separate from the main home, with its own entrance and outdoor space.",
          },
          {
            id: "784872",
            bolded: "An ADU basement conversion ",
            sentence:
              "is the process of converting an existing basement into a living space or ADU.",
          },
          {
            id: "939224",
            bolded: "An ADU attic conversion ",
            sentence: "is the process of converting an existing attic into a living space or ADU.",
          },
          {
            id: "826438",
            bolded: "An attached ADU ",
            sentence:
              "is an ADU that is attached to the main home, either through a shared wall or a separate entrance.",
          },
          {
            id: "122760",
            bolded: "A JADU",
            sentence:
              ", or junior accessory dwelling unit, is a small ADU built within an existing home, typically in a basement or attic.",
          },
          {
            id: "553088",
            bolded: "A tiny home on wheels ",
            sentence:
              "is a small, portable dwelling that is built on a trailer and can be moved from one location to another.",
          },
        ],
      },
      { type: "spacer", id: "s9", space: 1.5 },
      {
        type: "typography",
        subtype: "h5",
        content: "How do I find out what is allowed?",
        id: "lotplan8",
      },
      {
        type: "typography",
        subtype: "body2",
        fontWeight: "regular",
        content:
          "Grab a cup of coffee and carve out 15-30 minutes of your schedule. You’ll need to do some research on your local building laws:",
        id: "lotplan9",
      },
      { type: "spacer", id: "s11", space: 1.5 },
      {
        type: "ol",
        id: "lotplan11",
        content: [
          {
            id: "680545",
            sentence:
              "Contact your local building department: The first step in finding out the zoning requirements for an ADU is to contact your local building department. They will be able to provide you with information on the specific zoning rules and regulations in your area.",
          },
          {
            id: "313323",
            sentence:
              "Research online: Many cities and counties have their zoning codes available online (see Cupertino¹). You can search for your city or county's website and look for information on ADUs.",
          },
          {
            id: "716580",
            sentence:
              "Contact your homeowner's association: If you live in a community with a homeowner's association (HOA), they may have additional requirements or restrictions on ADUs.",
          },
          {
            id: "649029",
            sentence:
              "Talk to a real estate agent: A real estate agent may be able to provide you with additional information on zoning requirements for ADUs in your area.",
          },
        ],
      },
      { type: "spacer", id: "289146", space: 1.5 },
      {
        type: "link",
        id: "441208",
        content: "¹Cupertino, CA - Example ADU Zoning",
        location: "https://www.cupertino.org/home/showpublisheddocument/29567/637614267944000000",
      },
      { type: "spacer", id: "s13", space: 2.5 },
      {
        type: "box",
        id: "d1",
        background: "#FAFAFA",
        padding: 2,
        arr: [
          {
            type: "typography",
            subtype: "h6",
            content: "Zoning Tool",
            id: "026962",
          },
          {
            type: "typography",
            subtype: "body2",
            fontWeight: "regular",
            content:
              "Zoning doesn’t have to be time consuming so we’ve created an interactive form to make things fast and simple when talking to your city.",
            id: "933063",
          },
          { type: "spacer", id: "498549", space: 2.5 },
          {
            type: "typography",
            subtype: "body2",
            fontWeight: "regular",
            content: "Allowed ADU Types",
            id: "lotplan143",
          },
          { type: "spacer", id: "s013", space: 0.5 },
          {
            type: "form",
            subtype: "Checkbox",
            label: "Conversion",
            id: "conversionCheck",
            field: "conversionCheck",
          },
          {
            type: "form",
            subtype: "Checkbox",
            label: "Detached",
            id: "detachedCheck",
            field: "detachedCheck",
          },
          {
            type: "form",
            subtype: "Checkbox",
            label: "Attached",
            id: "attachedCheck",
            field: "attachedCheck",
          },
          {
            type: "form",
            subtype: "Checkbox",
            label: "Duplex",
            id: "duplexCheck",
            field: "duplexCheck",
          },
          { type: "spacer", id: "s012", space: 1.5 },
          {
            type: "form",
            subtype: "NumberInput",
            width: "150px",
            label: "Max Size",
            endAdornment: "sq ft",
            id: "maxSizeNum",
            field: "maxSizeNum",
          },
          {
            type: "form",
            subtype: "NumberInput",
            width: "150px",
            label: "Max Height",
            endAdornment: "ft",
            id: "maxHeightNum",
            field: "maxHeightNum",
          },
          {
            type: "form",
            subtype: "NumberInput",
            width: "150px",
            label: "# Units Allowed",
            endAdornment: "unit(s)",
            id: "unitsNum",
            field: "unitsNum",
          },
          { type: "spacer", id: "s0013", space: 1.5 },
          {
            type: "typography",
            subtype: "body2",
            fontWeight: "regular",
            content: "Minimum ADU Setbacks",
            id: "lotplan1232",
          },
          { type: "spacer", id: "s014", space: 0.5 },
          {
            type: "form",
            subtype: "NumberInput",
            width: "150px",
            label: "Setback (Sides)",
            endAdornment: "ft",
            id: "setbacksSideNum",
            field: "setbacksSideNum",
          },
          {
            type: "form",
            subtype: "NumberInput",
            width: "150px",
            label: "Setback (Rear)",
            endAdornment: "ft",
            id: "setbacksRearNum",
            field: "setbacksRearNum",
          },
          {
            type: "form",
            subtype: "NumberInput",
            width: "150px",
            label: "Setback (Dwelling)",
            endAdornment: "ft",
            id: "setbacksDwellingNum",
            field: "setbacksDwellingNum",
          },
          {
            type: "form",
            subtype: "TextInput",
            width: "100%",
            label: "Access Requirements (Notes)",
            rows: 3,
            id: "accessText",
            field: "accessText",
          },
          {
            type: "form",
            subtype: "TextInput",
            width: "150px",
            label: "Parking Requirements",
            endAdornment: "spot(s)",
            id: "parkingNum",
            field: "parkingNum",
          },
          {
            type: "form",
            subtype: "TextInput",
            width: "100%",
            label: "Aesthetic Requirements (Notes)",
            rows: 3,
            id: "aestheticText",
            field: "aestheticText",
          },
          {
            type: "form",
            subtype: "TextInput",
            width: "100%",
            label: "HOA Requirements (Notes)",
            rows: 3,
            id: "hoaText",
            field: "hoaText",
          },
        ],
      },
      { type: "spacer", id: "s15", space: 1.5 },
    ],
    cta: "Continue to Zoning",
    nextUp: "All-in Costs",
    validations: [],
  },
  {
    id: "cost",
    chapter: "All-in Costs",
    img: {
      local: true,
      src: "hero-Cost.png",
      alt: "Cost Calculator",
      style: { marginBottom: "20px" },
    },
    title: "All-in Costs",
    goal: "For this section, we’ll help you get a total cost estimate for your ADU project so you can secure financing.",
    time: "9 minutes",
    components: [
      { type: "spacer", id: "282157", space: 1.5 },
      {
        type: "typography",
        subtype: "h4",
        fontWeight: "regular",
        content: "Get a price estimate for your ADU.",
        id: "allIn1",
      },
      { type: "spacer", id: "s19", space: 1.5 },
      {
        type: "typography",
        subtype: "h5",
        fontWeight: "regular",
        content: "Budget out your ADU with our interactive calculator.",
        id: "allIn2",
      },
      { type: "spacer", id: "s20", space: 1.5 },
      { type: "typography", subtype: "h6", content: "How much do ADUs cost?", id: "allIn3" },
      {
        type: "typography",
        subtype: "body2",
        fontWeight: "regular",
        content:
          "The cost of an ADU varies drastically due to factors relating to your site, local labor, and materials. Here are some costs in cities with ADUs:",
        id: "allIn4",
      },
      { type: "spacer", id: "s21", space: 0.5 },
      {
        type: "ul",
        id: "allIn5",
        noBullet: true,
        content: [
          {
            id: "5435435435",
            bolded: "SF / Bay Area: ",
            sentence: "$149k - $400k",
          },
          {
            id: "76576",
            bolded: "Southern CA: ",
            sentence: "$95k - $330k",
          },
          {
            id: "8318181",
            bolded: "Austin, TX: ",
            sentence: "$125k - $300k",
          },
          {
            id: "32131888",
            bolded: "Portland, OR: ",
            sentence: "$142k - $217k",
          },
        ],
      },
      { type: "spacer", id: "s22", space: 1.5 },
      { type: "typography", subtype: "h6", content: "Is an ADU a good investment?", id: "allIn6" },
      {
        type: "typography",
        subtype: "body2",
        fontWeight: "regular",
        content: "We think so but here are some reasons:",
        id: "allIn7",
      },
      { type: "spacer", id: "s23", space: 0.5 },
      {
        type: "ul",
        id: "allIn8",
        content: [
          "The average two-bedroom ADU rents for $33,600 / year¹",
          "Home value can increase with ADUs, up to 35% in some cities²",
          "It’s (often) cheaper to build an ADU than to buy a new home³",
          "There’s a increase in multi-generational living where parents or children are moving in⁴",
          "Rentals are growing 7.5% year-over-year⁵",
          "52% of young American adults (18-29) are living with parents and need a detached living space⁶",
        ],
      },
      { type: "spacer", id: "219710", space: 1.5 },
      {
        type: "link",
        content: "¹SFGate - Should an ADU unit rent for the same price as a regular unit in SF?",
        location:
          "https://www.sfgate.com/realestate/article/how-to-price-ADU-unit-rental-sf-ca-16534151.php",
        id: "allIn9",
      },
      {
        type: "link",
        content:
          "²Porch - How Much Value Do Granny Flats and other Accessory Dwelling Units Add to a Home?",
        location: "https://porch.com/advice/state-of-adu-market-2021",
        id: "allIn10",
      },
      {
        type: "link",
        content: "³US Bank - Is it Cheaper to Build or Buy a House?",
        location:
          "https://www.usbank.com/financialiq/manage-your-household/home-ownership/is-it-cheaper-to-build-or-buy-a-house.html",
        id: "allIn11",
      },
      {
        type: "link",
        content: "⁴NYTimes - The Family That Buys Together Stays Together",
        location: "https://www.nytimes.com/2021/07/09/realestate/multigenerational-living.html",
        id: "926314",
      },
      {
        type: "link",
        content: "⁵Inman - Rent prices rise at highest level since 2005",
        location:
          "https://www.inman.com/2021/08/17/rent-prices-rise-at-highest-level-since-2005-corelogic/",
        id: "078783",
      },
      {
        type: "link",
        content:
          "⁶Pew Research - A majority of young adults in the U.S. live with their parents for the first time since the Great Depression",
        location:
          "https://www.pewresearch.org/fact-tank/2020/09/04/a-majority-of-young-adults-in-the-u-s-live-with-their-parents-for-the-first-time-since-the-great-depression/",
        id: "275882",
      },
      { type: "spacer", id: "s024", space: 2.5 },
      {
        type: "box",
        id: "d3",
        arr: [{ type: "costCalculator", id: "allIn14" }],
      },
      { type: "spacer", id: "431659", space: 2.5 },
    ],
    nextUp: "Financing",
    validations: [],
  },
  {
    id: "finance",
    chapter: "Financing",
    img: {
      local: true,
      src: "hero-financing03.png",
      alt: "Financing Calculator",
      style: { marginBottom: "20px" },
    },
    title: "Financing",
    time: "4 minutes",
    goal: "Get the amount available of financing, see how much it costs per month, and get approved with a lender.",
    components: [
      { type: "spacer", id: "815766", space: 1.5 },
      {
        type: "typography",
        subtype: "h4",
        fontWeight: "regular",
        content: "Secure the best financing for your ADU.",
        id: "754900",
      },
      { type: "spacer", id: "675638", space: 1.5 },
      {
        type: "typography",
        subtype: "h5",
        fontWeight: "regular",
        content:
          "Get the best ADU financing deal - no stress, no hassle, just great rates and flexible options!",
        id: "356339",
      },
      { type: "spacer", id: "s20", space: 1.5 },
      {
        type: "typography",
        subtype: "body2",
        fontWeight: "regular",
        content: "There are several financing options available for ADUs:",
        id: "financing1",
      },
      { type: "spacer", id: "s26", space: 1 },
      {
        type: "ul",
        id: "643620",
        content: [
          {
            id: "091663",
            bolded: "Home equity loans: ",
            sentence:
              "These are loans that allow homeowners to borrow against the equity in their home.",
          },
          {
            id: "812397",
            bolded: "Home improvement loans: ",
            sentence:
              "These loans are specifically designed for home improvement projects, including building an ADU.",
          },
          {
            id: "150810",
            bolded: "FHA 203(k) loans: ",
            sentence:
              "These loans are designed to help homeowners finance home repairs and improvements, including the construction of an ADU.",
          },
          {
            id: "231805",
            bolded: "Personal loans: ",
            sentence:
              "These loans can be used for a variety of purposes, including financing an ADU.",
          },
          {
            id: "134699",
            bolded: "Construction loans: ",
            sentence:
              "These loans are specifically designed for the construction of a new home or building, including an ADU.",
          },
          {
            id: "294544",
            bolded: "Private financing: ",
            sentence:
              "Some homeowners may be able to secure financing from private investors or crowdfunding platforms.",
          },
          {
            id: "293621",
            bolded: "Government grants or incentives: ",
            sentence:
              "Some local or state governments offer grants or incentives for homeowners who build ADUs, which can help cover some or all of the construction costs.",
          },
          {
            id: "738012",
            bolded: "Rent-to-Own: ",
            sentence:
              "Some ADU companies offer rent-to-own financing options, allowing you to pay a monthly fee to rent the ADU while also building equity towards ownership.",
          },
        ],
      },
      { type: "spacer", id: "290658", space: 1.5 },
      {
        type: "typography",
        subtype: "body2",
        fontWeight: "regular",
        content:
          "Use our financing calculator to determine how much financing is available for your ADU:",
        id: "740474",
      },
      { type: "spacer", id: "497710", space: 1.5 },
      {
        type: "box",
        id: "405949",
        background: "#FAFAFA",
        borderRadius: 2,
        arr: [{ type: "financingCalculator", id: "311123" }],
      },
      { type: "spacer", id: "s29", space: 1.5 },
    ],
    nextUp: "Designs / Floorplans",
    validations: [],
  },
  {
    id: "design",
    chapter: "Designs / Floorplans",
    img: {
      local: true,
      src: "floorplans.png",
      alt: "Example Floorplans",
      style: { marginBottom: "-60px" },
    },
    title: "Designs / Floorplans",
    goal: "Get to know the process of selecting a design that fits your backyard, your design preferences, and meets the requirements of your local jurisdiction or HOA.",
    time: "5 minutes",
    components: [
      { type: "spacer", id: "705675", space: 1.5 },
      {
        type: "typography",
        subtype: "h4",
        fontWeight: "regular",
        content: "Find Your Dream ADU Floor Plan",
        id: "628300",
      },
      { type: "spacer", id: "038367", space: 1.5 },
      {
        type: "typography",
        subtype: "h5",
        fontWeight: "regular",
        content: "Get creative, get spacious, set the best space for your needs!",
        id: "984966",
      },
      { type: "spacer", id: "104190", space: 1.5 },
      {
        type: "typography",
        subtype: "body2",
        fontWeight: "regular",
        content:
          "During the zoning process, you determined the maximum size that your ADU can be. It is generally a good idea to build your ADU as large as possible within these limitations, as it can increase the value and usability of the unit. However, it is important to also consider your budget and the overall aesthetic of your property when deciding on the size of your ADU.",
        id: "251661",
      },
      { type: "spacer", id: "153902", space: 1.5 },
      {
        type: "typography",
        subtype: "h6",
        content: "Find floor plans for a backyard ADU:",
        id: "469977",
      },
      {
        type: "ol",
        id: "855221",
        content: [
          "Search online for “backyard ADU floor plans” or “granny flat floor plans” to find a variety of options.",
          "Contact an architect or designer who specializes in small or accessory dwelling units to create custom plans.",
          "Check with your city or county building department to see if they have any approved or recommended floor plans available.",
          "Look for prefabricated or modular ADU companies who offer a range of floor plans to choose from.",
          "Consider using a design software or app such as SketchUp or Sweet Home 3D to create your own custom floor plan.",
          "Consider consulting with a real estate agent or property appraiser to ensure that your floor plan aligns with local zoning and building codes.",
          {
            type: "link",
            location: "https://www.dwellito.com/browse/adu",
            content: "Browse our ADU marketplace",
          },
        ],
      },
      { type: "spacer", id: "096903", space: 1.5 },
      {
        type: "typography",
        subtype: "h5",
        content: "Common Questions",
        id: "204968",
      },
      { type: "spacer", id: "099914", space: 1.5 },
      {
        type: "typography",
        subtype: "h6",
        content: "Stick-build or Prefab construction?",
        id: "066756",
      },
      {
        type: "typography",
        subtype: "body2",
        fontWeight: "regular",
        content:
          "The costs of site-built and prefabricated ADUs are often similar, with the main difference being the location of the construction. Prefabricated ADUs are built in a factory and then delivered and assembled on site, while site-built ADUs are constructed entirely on the property. While prefabricated ADUs may offer more limited customization options, they often come with high-end architectural floor plans and are built using modern construction methods and precise details. On the other hand, site-built ADUs offer more flexibility in terms of customization, but may require a contractor with specialized skills and experience in modern construction methods. Ultimately, the decision between site-built and prefabricated ADUs will depend on your personal preferences and budget.",
        id: "763651",
      },
      { type: "spacer", id: "559661", space: 1.5 },
      {
        type: "typography",
        subtype: "h6",
        content: "Go Big or Small?",
        id: "924128",
      },
      {
        type: "typography",
        subtype: "body2",
        fontWeight: "regular",
        content:
          "Contrary to popular belief, it is often more cost-effective to build a larger ADU rather than a smaller one. This is because the majority of the costs involved in building an ADU are related to the infrastructure, such as plumbing, electrical, and foundation work, rather than the size of the walls. In addition, some appraisers may consider the square footage of the ADU when evaluating a home, which can increase the overall value of the property. Therefore, it is often wise to consider building a larger ADU to take advantage of cost savings and potentially increase the value of your home.",
        id: "153388",
      },
      { type: "spacer", id: "666499", space: 1.5 },
    ],
    nextUp: "Labor",
    validations: [],
  },
  {
    id: "labor",
    chapter: "Labor",
    img: {
      local: true,
      src: "hero-labor-02.png",
      alt: "Labor Build ADU",
      style: { marginBottom: "20px" },
    },
    title: "Labor",
    time: "8 minutes",
    goal: "Secure the top rated ADU builder at the best price point.",
    components: [
      { type: "spacer", id: "706222", space: 1.5 },
      {
        type: "typography",
        subtype: "h4",
        fontWeight: "regular",
        content: "Get an ADU contractor that will wow you",
        id: "150606",
      },
      { type: "spacer", id: "531846", space: 1.5 },
      {
        type: "typography",
        subtype: "h5",
        fontWeight: "regular",
        content:
          "Save time and eliminate headaches by finding an ADU builder that will meet your deadlines and budget.",
        id: "931878",
      },
      { type: "spacer", id: "128921", space: 1.5 },
      {
        type: "typography",
        subtype: "h6",
        content: "You have several options to consider for construction:",
        id: "077479",
      },
      {
        type: "ul",
        id: "984768",
        content: [
          {
            id: "068998",
            bolded: "Turn-key prefab builders: ",
            sentence:
              "These companies handle everything from design to construction, but may come with higher costs.",
          },
          {
            id: "524438",
            bolded: "Kit-of-parts homes: ",
            sentence:
              "With this option, you will receive all the necessary materials to build your ADU, but you will need to handle the construction yourself or hire additional contractors.",
          },
          {
            id: "988781",
            bolded: "ADU general contractors: ",
            sentence:
              "These professionals can handle all aspects of the construction process for you.",
          },
          {
            id: "823582",
            bolded: "Do-it-yourself: ",
            sentence:
              "If you have the skills and resources, you can save money by taking on the construction of your ADU yourself or with the help of friends. Just be prepared to put in the time and effort required to complete the project.",
          },
        ],
      },
      { type: "spacer", id: "399717", space: 1.5 },
      {
        type: "typography",
        subtype: "h6",
        content: "Find a contractor to build your ADU:",
        id: "485415",
      },
      {
        type: "ul",
        id: "676419",
        content: [
          "Ask for referrals from friends, family, or neighbors who have had similar work done.",
          "Search online directories or review sites such as Yelp, HomeAdvisor, or Houzz to find contractors in your area.",
          "Contact local building supply stores or home improvement centers and ask for recommendations.",
          "Contact local trade organizations such as the National Association of Home Builders or the National Association of the Remodeling Industry to find contractors in your area.",
          "Check with your city or county building department to see if they have a list of approved contractors.",
          "Meet with several contractors and ask for references, estimates, and proof of insurance before making a decision.",
        ],
      },
      { type: "spacer", id: "753581", space: 1.5 },
    ],
    nextUp: "Get Started",
    validations: [],
  },
  {
    id: "start",
    chapter: "Get Started",
    img: {
      local: true,
      src: "hero-get-started-02.png",
      alt: "Get Started Progress",
      style: { marginBottom: "-20px" },
    },
    title: "Get Started",
    goal: "Take your last steps before production starts.",
    time: "1 minute",
    components: [
      { type: "spacer", id: "837683", space: 1.5 },
      {
        type: "typography",
        subtype: "h4",
        fontWeight: "regular",
        content: "Save your spot in line",
        id: "775204",
      },
      { type: "spacer", id: "344055", space: 1.5 },
      {
        type: "typography",
        subtype: "h5",
        fontWeight: "regular",
        content:
          "Get started by putting out a request for proposals from ADU builders in your area and getting pre-approved for financing.",
        id: "832378",
      },
      { type: "spacer", id: "512628", space: 1.5 },
      { type: "mapbox", id: "812980", height: "300px" },
      { type: "spacer", id: "312638", space: 1.5 },
      {
        type: "box",
        id: "486264",
        background: "#FAFAFA",
        textAlign: "center",
        padding: 2,
        arr: [
          {
            type: "typography",
            subtype: "h6",
            fontWeight: "regular",
            content:
              "Sign up to automatically receive and compare quotes from builders in your area.",
            id: "228239",
          },
          { type: "spacer", id: "518130", space: 1.5 },
          {
            type: "form",
            subtype: "TextInput",
            width: "250px",
            label: "Email",
            id: "089891",
            field: "email",
          },
          {
            type: "form",
            subtype: "TextInput",
            textType: "password",
            width: "250px",
            label: "Password",
            id: "769969",
            field: "password",
          },
          // {
          //   type: "typography",
          //   subtype: "body2",
          //   fontWeight: "regular",
          //   content:
          //     "Save your progress and receive quotes from ADU builders directly in Dwellito. We never share your contact information until you choose a builder to move foward with.",
          //   id: "547760",
          // },
          // {
          //   type: "form",
          //   subtype: "Checkbox",
          //   label: "Secure a bid",
          //   id: "bidCheck",
          //   field: "bidCheck",
          // },
          // {
          //   type: "form",
          //   subtype: "Checkbox",
          //   label: "Sign contract with ADU Builder",
          //   id: "contractCheck",
          //   field: "contractCheck",
          // },
          // {
          //   type: "form",
          //   subtype: "Checkbox",
          //   label: "Get pre-qualified with a lender",
          //   id: "lenderCheck",
          //   field: "lenderCheck",
          // },
          // {
          //   type: "form",
          //   subtype: "Checkbox",
          //   label: "Pay deposit and get your ADU in production!",
          //   id: "depositCheck",
          //   field: "depositCheck",
          // },
        ],
      },
      { type: "spacer", id: "745450", space: 1.5 },
    ],
    validations: [],
  },
];

export default initialSteps;
