import PropTypes from "prop-types";
import Lottie from "lottie-react";
import checkmarkAnimation from "assets/animations/checkmark.json";

// @mui material components

// Material Kit 2 React components
import MKBox from "components/MKBox";

function Page5() {
  return (
    <>
      <MKBox display="flex" alignItems="center" justifyContent="center">
        <Lottie
          animationData={checkmarkAnimation}
          loop={false}
          style={{ height: 160, marginTop: -20, marginBottom: -20 }}
        />
      </MKBox>
      <MKBox
        py={2}
        sx={{ pt: 2, display: "flex", alignItems: "center", flexDirection: "column" }}
      ></MKBox>
    </>
  );
}

// Typechecking props for the Page3
Page5.propTypes = {
  nextPage: PropTypes.func.isRequired,
};

export default Page5;
