const calculateCosts = (
  costFactor,
  sqFt,
  condition,
  buildType,
  bedrooms,
  bathrooms,
  quality,
  state,
  assumptions,
  defaultCalc,
  turnkey
) => {
  // pull out assumptions
  const { apr, loanTerm, taxRate, insuranceRate } = assumptions;
  let hardCosts = [
    { name: "Site Prep/Foundation", value: 0 },
    { name: "Utilities", value: 0 },
    { name: "Construction", value: 0 },
  ];

  let softCosts = [
    { name: "Design/Architecture", value: 0 },
    { name: "Boundary Survey", value: 0 },
    { name: "Permits/Inspections", value: 0 },
  ];

  // Start calculation
  let buildTypeAdjustment = 1;
  if (buildType === 0) {
    buildTypeAdjustment = 0.4;
  } else if (buildType === 2) {
    buildTypeAdjustment = 1.25;
  }

  let siteAdjustment = 1;
  if (condition === 1) {
    siteAdjustment = 1.25;
  } else if (condition === 2) {
    siteAdjustment = 1.5;
  }
  // Site preparation depends on condition of the property
  const avgConstPerSqFt = 175;
  let qualityAdjustment = 1;
  let rentQualityAdjustment = 1;
  if (quality === 1) {
    qualityAdjustment = 1.4;
    rentQualityAdjustment = 1.2;
  } else if (quality === 2) {
    qualityAdjustment = 2.25;
    rentQualityAdjustment = 1.55;
  }

  hardCosts[0].value = 10000 * (sqFt / 800) * (siteAdjustment * costFactor * buildTypeAdjustment);
  hardCosts[1].value = 5000 * (siteAdjustment * costFactor * buildTypeAdjustment);
  // construction costs
  hardCosts[2].value =
    buildTypeAdjustment * avgConstPerSqFt * sqFt * qualityAdjustment * costFactor +
    7500 * bedrooms +
    15000 * bathrooms;

  if (assumptions.basePrice && defaultCalc) {
    hardCosts[2].name = "Base Price";
    hardCosts[2].value = assumptions.basePrice;
  }

  if (buildType === 0) {
    softCosts[0].value = 3000 + 2 * sqFt * qualityAdjustment * costFactor;
  } else if (buildType === 1) {
    softCosts[0].value = 0;
  } else if (buildType === 2) {
    softCosts[0].value = 10000 + 4 * sqFt * qualityAdjustment * costFactor;
  }

  softCosts[1].value = 500 * siteAdjustment * costFactor;
  softCosts[2].value = 2500 + buildTypeAdjustment * 8 * sqFt;

  if (state === "CA" && sqFt > 750) {
    softCosts.push({
      name: "Impact Fee",
      value: 4.08 * sqFt,
    });
  }

  if (turnkey && defaultCalc) {
    hardCosts = [{ name: "Turnkey Cost", value: assumptions.basePrice }];
    softCosts = [];
  }

  const totalHC = hardCosts.reduce((a, b) => a + b.value, 0);
  const totalSC = softCosts.reduce((a, b) => a + b.value, 0);

  // calculate rentEstimate
  const rentEstimate =
    0.9 *
    sqFt *
    (costFactor / 0.8) *
    rentQualityAdjustment *
    (1 + bedrooms / 10) *
    (1 + bathrooms / 18);

  // calculate costEstimate assume 30 year mortgage at 6.5% APR with 20% down, 0.8% base tax rate, and 0.5532% insurance rate
  // M = P [ i(1 + i)^n ] / [ (1 + i)^n – 1].
  const monthlyTax = (totalHC * taxRate * costFactor) / 12;
  const monthlyInsurance = (totalHC * insuranceRate * costFactor) / 12;
  const monthlyRate = apr / 12;
  const monthlyPayment =
    ((totalHC + totalSC) * 0.8 * monthlyRate * (1 + monthlyRate) ** loanTerm) /
    ((1 + monthlyRate) ** loanTerm - 1);

  const costBreakdown = [
    { title: "Mortgage*", value: Math.round(monthlyPayment), color: "#21238d" },
    { title: "Insurance", value: Math.round(monthlyInsurance), color: "#646A96" },
    { title: "Tax", value: Math.round(monthlyTax), color: "#2A2CB1" },
  ];
  const costEstimate = monthlyTax + monthlyInsurance + monthlyPayment;

  return { hardCosts, softCosts, totalHC, totalSC, rentEstimate, costEstimate, costBreakdown };
};

export { calculateCosts };
