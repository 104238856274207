import { useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import InputAdornment from "@mui/material/InputAdornment";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import NumberFormatCustom from "components/NumberFormat";

// @mui icons
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import Slider from "@mui/material/Slider";

import { stringToNumber, numShortener } from "helpers/formatters";

import { ReactComponent as NormalDist } from "assets/images/shapes/normal-distribution.svg";

function Page1({ nextPage, form: form1, updateForm: setForm1, loan }) {
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm1(name, value);
  };

  const handleCreditScore = (e, v) => {
    setForm1("creditScore", v);
  };

  const handleContinue = () => {
    setError(false);
    if (
      !form1.homeValue ||
      form1.currentDebt == null ||
      form1.income == null ||
      !form1.creditScore
    ) {
      setError(true);
      return;
    }
    nextPage();
  };

  return (
    <>
      <MKBox
        py={2}
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <MKTypography variant="h4" color="text" fontWeight="bold" textAlign="center">
          Your Property
        </MKTypography>

        <MKBox
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            maxWidth: "450px",
            width: "100%",
            mt: 2,
          }}
        >
          <MKTypography
            textTransform="uppercase"
            variant="caption"
            fontWeight="bold"
            color="text"
            fontSize="0.675rem"
            my={0.5}
            sx={{ alignSelf: "start" }}
          >
            Estimated Home Value
          </MKTypography>
          <MKBox
            p={0.25}
            mr={{ xs: 2, iframe: 0 }}
            mt={-2}
            sx={{
              backgroundColor: "#fff",
              border: "1px dotted",
              borderColor: "primary",
              alignSelf: "end",
              width: "100px",
              textAlign: "center",
            }}
          >
            <MKTypography variant="body1" fontWeight="bold" color="primary" fontSize="60%">
              {`$${numShortener(form1.homeValue[0])} - $${numShortener(form1.homeValue[1])}`}
            </MKTypography>
          </MKBox>
        </MKBox>
        <MKBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "450px",
            width: "90%",
          }}
        >
          <NormalDist width="100%" height="60px" />
          <Slider
            getAriaLabel={() => "Home Value"}
            value={[stringToNumber(form1.homeValue[0]), stringToNumber(form1.homeValue[1])]}
            valueLabelDisplay="auto"
            color="primary"
            valueLabelFormat={(l) => (l === 2000000 ? `$2M+` : `$${numShortener(l)}`)}
            onChange={(_, v) => setForm1("homeValue", v)}
            name="homeValue"
            step={5000}
            disableSwap
            marks={[{ value: 0 }, { value: 2000000, label: "2M+" }]}
            min={0}
            max={2000000}
            sx={{
              mt: "-15px",
              "& .MuiSlider-track": { height: "6px" },
              "& .MuiSlider-markLabel": {
                fontSize: "12px",
                mt: -0.75,
              },
            }}
          />
        </MKBox>

        <MKInput
          label={
            <span>
              Remaining mortgage{" "}
              <span style={{ fontSize: "12px", fontWeight: 400 }}>(plus any other debt)</span>
            </span>
          }
          variant="outlined"
          placeholder="820,000"
          name="currentDebt"
          fullWidth
          color="primary"
          error={error && form1.currentDebt == null}
          sx={{ maxWidth: "450px", my: 1.5 }}
          type="text"
          value={form1.currentDebt}
          onChange={handleChange}
          InputProps={{
            inputComponent: NumberFormatCustom,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            inputMode: "decimal",
          }}
          // eslint-disable-next-line
          inputProps={{
            inputMode: "numeric",
          }}
        />

        <MKInput
          label={
            <span>
              Household income
              <span style={{ fontSize: "12px", fontWeight: 400 }}>(include all sources)</span>
            </span>
          }
          placeholder="62,500"
          color="primary"
          variant="outlined"
          fullWidth
          name="income"
          type="text"
          error={error && form1.income == null}
          sx={{ maxWidth: "450px", my: 1.5 }}
          value={form1.income}
          onChange={handleChange}
          InputProps={{
            inputComponent: NumberFormatCustom,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            inputMode: "decimal",
          }}
          // eslint-disable-next-line
          inputProps={{
            inputMode: "numeric",
          }}
        />
        <MKBox
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            maxWidth: "450px",
            width: "100%",
          }}
        >
          <MKTypography
            textTransform="uppercase"
            variant="caption"
            fontWeight="bold"
            color="text"
            fontSize="0.675rem"
            my={0.5}
            sx={{ alignSelf: "start" }}
          >
            Credit Score
          </MKTypography>
          <ToggleButtonGroup
            color="primary"
            sx={{
              alignItems: "center",
              mx: 0,
              px: 0,
              "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                borderColor: "rgba(0,0,0,0.12)",
              },
              "& .Mui-selected": {
                backgroundColor: "#F6F7FF !important",
                borderColor: "#2A2CB1 !important",
              },
            }}
            exclusive
            fullWidth
            value={form1.creditScore}
            onChange={handleCreditScore}
          >
            <ToggleButton
              value="bad"
              sx={{
                fontWeight: "bold",
                borderRadius: "5px !important",
                marginRight: { xs: 0.25, md: 1 },
                textTransform: "none !important",
              }}
            >
              300-629
            </ToggleButton>
            <ToggleButton
              value="fair"
              sx={{
                fontWeight: "bold",
                borderRadius: "5px !important",
                marginRight: { xs: 0.25, md: 1 },
                textTransform: "none !important",
              }}
            >
              630-689
            </ToggleButton>
            <ToggleButton
              value="good"
              sx={{
                fontWeight: "bold",
                borderColor: "rgba(0,0,0,0.12) !important",
                borderRadius: "5px !important",
                marginRight: { xs: 0.25, md: 1 },
                textTransform: "none !important",
              }}
            >
              690-719
            </ToggleButton>
            <ToggleButton
              value="excellent"
              sx={{
                fontWeight: "bold",
                borderColor: "rgba(0,0,0,0.12) !important",
                borderRadius: "5px !important",
                marginRight: { xs: 0.25, md: 1 },
                textTransform: "none !important",
              }}
            >
              720+
            </ToggleButton>
          </ToggleButtonGroup>
        </MKBox>
        <MKBox sx={{ height: "10px" }}>
          {error && !form1.creditScore && (
            <MKTypography variant="caption" color="error" mt={-1}>
              Credit score is required
            </MKTypography>
          )}
        </MKBox>
        <MKBox
          sx={{
            height: "110px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loan.maxLoan > 0 && (
            <>
              <MKTypography variant="h6" color="text" fontWeight="bold" textTransform="uppercase">
                Get up to
              </MKTypography>
              <MKTypography className="number-font" variant="h4" color="text" fontWeight="regular">
                {`$${(Number(String(loan?.maxLoan).replace(/\D/g, "")) || "").toLocaleString()}`}
              </MKTypography>

              <MKTypography
                variant="body2"
                color="info"
                fontWeight="regular"
                textAlign="center"
                fontSize="12px"
              >
                {`For as low as ${`$${(
                  Number(String(loan?.monthlyPayment).replace(/\D/g, "")) || ""
                ).toLocaleString()}`}/mo`}
              </MKTypography>
            </>
          )}
        </MKBox>
      </MKBox>
      <MKBox display="flex" flexDirection="column" alignItems="center" pb={5}>
        <MKButton
          size="large"
          color="primary"
          sx={{ maxWidth: "450px" }}
          fullWidth
          onClick={handleContinue}
        >
          <LockOutlinedIcon color="light" sx={{ mr: "10px" }} />
          Continue
        </MKButton>
      </MKBox>
    </>
  );
}

// Typechecking props for the Page1
Page1.propTypes = {
  nextPage: PropTypes.func.isRequired,
  form: PropTypes.shape({
    homeValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    currentDebt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    income: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    creditScore: PropTypes.string,
  }).isRequired,
  loan: PropTypes.shape({
    maxLoan: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.string,
    monthlyPayment: PropTypes.string,
  }).isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default Page1;
