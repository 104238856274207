import { useState, useEffect, useContext } from "react";
import { useMemberstack, useAuth } from "@memberstack/react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Grid, Container, ToggleButton, ToggleButtonGroup } from "@mui/material";

import Navbar from "Users/Components/Navbar";

import useWindowSize from "Hooks/useWindowSize";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

import Authentication from "Context/auth";
import AddressAutoComplete from "components/AdressAutocomple";
import MKBox from "components/MKBox";

const mailformat =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Onboarding = ({
  noNavbar,
  NoFullHeight,
  shortened,
  bigButton,
  externalHandleSubmit,
  externalForm,
}) => {
  const { setJwt } = useContext(Authentication);
  const memberstack = useMemberstack();
  const { isLoggedIn } = useAuth();
  const { height, width } = useWindowSize();
  const [form, setForm] = useState(
    externalForm || {
      fname: "",
      lname: "",
      address: { formatted: "" },
      timeline: "",
      project: "",
      quality: "",
      services: [],
      email: "",
      password: "",
    }
  );
  const [step, setStep] = useState(0);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();

  useEffect(async () => {
    if (isLoggedIn && step !== 6) {
      try {
        const { data } = await axios.get("/api/v1/onboarding");
        setForm(data);
        return;
      } catch {
        return;
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/homeowner/onboarding/${step}`,
      title: `Onboarding Step ${step}`,
    });
  }, [step]);

  const updateForm = (field, value) => {
    setForm((e) => ({
      ...e,
      [field]: value,
    }));
  };

  const handleAddress = (v) => {
    updateForm("address", v);
  };

  const handleInput = (e) => {
    updateForm(e.target.name, e.target.value);
  };

  const handleToggle = (name, v, step) => {
    if (v !== null) {
      updateForm(name, v);
    }
    if (step) setStep(step);
  };

  const handleStep = async (fields, step) => {
    setError(false);
    const newErr = fields.find((f) => form[f].length === 0);
    if (newErr) {
      setError(true);
      return;
    }
    if (!newErr) setStep(step);
    return;
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleSubmit = async () => {
    if (isLoggedIn) {
      await axios.post("/api/v1/onboarding", { form });
      navigate("/homeowner");
    } else {
      setStep(6);
    }
  };

  const handleSignup = async () => {
    setError(false);
    if (!form.email.match(mailformat) || !form.email || !form.password) {
      return setError(true);
    }
    try {
      const res = await memberstack.signupMemberEmailPassword({
        email: form.email,
        password: form.password,
        customFields: {
          "first-name": form.fname,
          "last-name": form.lname,
          timeline: form.timeline,
          "project-type": form.project,
          budget: form.quality,
        },
        plans: [
          {
            planId: "pln_test-wvc0xxf", // Free plan
          },
        ],
      });
      const { tokens, member } = res.data;
      if (!member.id) return;
      setJwt(tokens.accessToken);
      await axios.post("/api/v1/onboarding-signup", { form: { ...form, msId: member.id } });
      navigate("/homeowner");
    } catch (err) {
      setErrorMsg(err.message);
    }
  };

  return (
    <>
      {!noNavbar && <Navbar />}
      <Container
        sx={{
          minHeight: NoFullHeight ? "inherit" : `${height - 64}px`,
          pb: NoFullHeight ? 0 : 6,
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {step !== 0 && (
          <MKButton
            variant="text"
            color="info"
            style={{ alignSelf: "start" }}
            onClick={handleBack}
            size="large"
          >
            Go Back
          </MKButton>
        )}
        {step === 0 && (
          <Grid
            container
            maxWidth="550px"
            textAlign="center"
            spacing={2}
            sx={{ mt: shortened ? 0 : { xs: "20px", md: "50px" } }}
          >
            <Grid item xs={12}>
              <img
                width="350px"
                style={{ marginBottom: "-50px" }}
                src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/6352f1a7ff883235a687cd06_View%202_White_Transparent%20Background.png"
                alt=""
              />
            </Grid>
            <Grid item xs={12}>
              <MKTypography variant="h2">
                Welcome! <br />
                Let&apos;s setup your profile.
              </MKTypography>
              <MKTypography variant="body2">(only 1 minute)</MKTypography>
            </Grid>
            <Grid item xs={12} md={6}>
              <MKInput
                style={{ width: "100%" }}
                label="First Name"
                name="fname"
                error={!!error && !form.fname}
                value={form.fname}
                type="text"
                placeholder="First Name"
                onChange={handleInput}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MKInput
                style={{ width: "100%" }}
                label="Last Name"
                name="lname"
                error={!!error && !form.lname}
                value={form.lname}
                type="text"
                placeholder="Last Name"
                onChange={handleInput}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {bigButton ? (
                <MKButton
                  onClick={() => handleStep(["fname", "lname"], 1)}
                  fullWidth
                  size="large"
                  variant="outlined"
                  color="primary"
                >
                  Next
                </MKButton>
              ) : (
                <MKButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => handleStep(["fname", "lname"], 1)}
                >
                  Next
                </MKButton>
              )}
            </Grid>
          </Grid>
        )}
        {step === 1 && (
          <Grid
            container
            maxWidth="550px"
            textAlign="center"
            spacing={2}
            sx={{ mt: shortened ? 0 : { xs: "20px", md: "50px" } }}
          >
            <Grid item xs={12}>
              <img
                width="350px"
                style={{ marginBottom: "-50px" }}
                src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/6352f1a7ff883235a687cd06_View%202_White_Transparent%20Background.png"
                alt=""
              />
            </Grid>
            <Grid item xs={12}>
              <MKTypography variant="h2">Address Information</MKTypography>
              <MKTypography variant="body2">Where are you looking to add an ADU?</MKTypography>
            </Grid>
            <Grid item xs={12}>
              <MKBox width="100%">
                <AddressAutoComplete
                  onChange={handleAddress}
                  id="address-autocomplete"
                  startingValue={form.address}
                  error={error && (!form.address || !form.address.formatted)}
                />
              </MKBox>
            </Grid>
            <Grid item xs={12}>
              {bigButton ? (
                <MKButton
                  onClick={() => handleStep(["fname", "lname"], 2)}
                  fullWidth
                  size="large"
                  variant="outlined"
                  color="primary"
                >
                  Next
                </MKButton>
              ) : (
                <MKButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ maxWidth: "420px" }}
                  onClick={() => handleStep(["fname", "lname"], 2)}
                >
                  Next
                </MKButton>
              )}
            </Grid>
          </Grid>
        )}
        {step === 2 && (
          <Grid
            container
            maxWidth="700px"
            textAlign="center"
            spacing={2}
            sx={{ mt: shortened ? 0 : { xs: "20px", md: "50px" } }}
          >
            <Grid item xs={12}>
              <MKTypography variant="body2">1/{bigButton ? 3 : 4}</MKTypography>
              <MKTypography variant="h2">What&apos;s your timeline?</MKTypography>
            </Grid>
            <Grid item xs={12}>
              <ToggleButtonGroup
                color="primary"
                sx={{
                  alignItems: "center",
                  mx: 0,
                  px: 0,
                  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                    borderColor: "rgba(0,0,0,0.12) ",
                  },
                  "& .Mui-selected": {
                    backgroundColor: "#F6F7FF !important",
                    borderColor: "#2A2CB1 !important",
                  },
                }}
                value={form.timeline}
                fullWidth
                exclusive
                orientation={width < 768 ? "vertical" : "horizontal"}
                onChange={(_, v) => handleToggle("timeline", v, 3)}
              >
                <ToggleButton
                  value={"ASAP"}
                  name="timeline"
                  sx={{
                    p: 0.5,
                    fontWeight: "normal",
                    marginRight: { xs: 0, md: 1 },
                    mt: 1,
                    width: "100%",
                    fontSize: "16px",
                    borderColor: "rgba(0,0,0,0.12) !important",
                    textTransform: "none !important",
                    borderRadius: "5px !important",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    pt: 3,
                  }}
                >
                  ASAP
                  <MKTypography variant="h6" color="primary" mb={2}>
                    Ready to break ground
                  </MKTypography>
                  <img
                    src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/60edefc65e33fa18ee738f50_Timeline-This%20Year.svg"
                    width="88"
                    alt="Timeline This Year"
                    style={{ marginBottom: "-60px" }}
                  />
                  <img
                    width="100%"
                    src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/6352f1a7ff883235a687cd06_View%202_White_Transparent%20Background.png"
                    alt=""
                  />
                </ToggleButton>
                <ToggleButton
                  value="1-Year"
                  name="timeline"
                  sx={{
                    p: 0.5,
                    fontWeight: "normal",
                    marginRight: { xs: 0, md: 1 },
                    mt: 1,
                    fontSize: "16px",
                    borderColor: "rgba(0,0,0,0.12) !important",
                    textTransform: "none !important",
                    borderRadius: "5px !important",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    pt: 3,
                  }}
                >
                  Within 1 year
                  <MKTypography variant="h6" color="primary" mb={2}>
                    Getting things setup
                  </MKTypography>
                  <img
                    src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/60edefc65e33fac28b738f4f_Timeline-Next%20Year.svg"
                    width="88"
                    alt="Timeline next year"
                    style={{ marginBottom: "-60px" }}
                  />
                  <img
                    width="100%"
                    src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/6352f1a7ff883235a687cd06_View%202_White_Transparent%20Background.png"
                    alt=""
                  />
                </ToggleButton>
                <ToggleButton
                  value="None"
                  name="timeline"
                  sx={{
                    p: 0.5,
                    fontWeight: "normal",
                    mt: 1,
                    fontSize: "16px",
                    borderColor: "rgba(0,0,0,0.12) !important",
                    textTransform: "none !important",
                    borderRadius: "5px !important",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    pt: 3,
                  }}
                >
                  None
                  <MKTypography variant="h6" color="primary" mb={2}>
                    Just browsing
                  </MKTypography>
                  <img
                    src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/60edefc65e33fa718e738f4e_Timeline-None.svg"
                    width="88"
                    alt="Timeline - none"
                    style={{ marginBottom: "-60px" }}
                  />
                  <img
                    width="100%"
                    src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/6352f1a7ff883235a687cd06_View%202_White_Transparent%20Background.png"
                    alt=""
                  />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        )}
        {step === 3 && (
          <Grid
            container
            maxWidth="1000px"
            textAlign="center"
            spacing={2}
            sx={{ mt: shortened ? 0 : { xs: "20px", md: "50px" } }}
          >
            <Grid item xs={12}>
              <MKTypography variant="body2">2/{bigButton ? 3 : 4}</MKTypography>
              <MKTypography variant="h2">What type of prefab are you looking for?</MKTypography>
              <MKTypography variant="body2">Select your project type</MKTypography>
            </Grid>
            <Grid item xs={12}>
              <ToggleButtonGroup
                color="primary"
                sx={{
                  alignItems: "center",
                  mx: 0,
                  px: 0,
                  "& .Mui-selected": {
                    backgroundColor: "#F6F7FF !important",
                    opacity: "1 !important",
                    borderColor: "#2A2CB1 !important",
                    border: "1px solid !important",
                  },
                }}
                value={form.project}
                exclusive
                fullWidth
                orientation={width < 768 ? "vertical" : "horizontal"}
                onChange={(_, v) => handleToggle("project", v, 4)}
              >
                <ToggleButton
                  value="Office"
                  name="project"
                  sx={{
                    p: 0.5,
                    fontWeight: "normal",
                    fontSize: "16px",
                    textTransform: "none !important",
                    borderRadius: "5px !important",
                    marginRight: { xs: 0, md: 1 },
                    mt: 1,
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    height: shortened ? "220px" : "300px",
                    backgroundImage:
                      "linear-gradient(#00000080, #00000080),url(https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/60edefc65e33fa0cb5738f2f_thumbnail-HomeOffice.jpg)",
                    backgroundSize: "cover",
                    justifyContent: "end",
                    opacity: 0.5,
                    "&:hover": {
                      opacity: 1,
                    },
                    "& .Mui-selected": {
                      backgroundColor: "#F6F7FF !important",
                      opacity: "1 !important",
                    },
                  }}
                >
                  <MKTypography variant="h4" color="white" mb={2}>
                    Studio Office
                  </MKTypography>
                </ToggleButton>
                <ToggleButton
                  value="ADU"
                  name="project"
                  sx={{
                    p: 0.5,
                    fontWeight: "normal",
                    fontSize: "16px",
                    textTransform: "none !important",
                    borderRadius: "5px !important",
                    marginRight: { xs: 0, md: 1 },
                    marginTop: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "end",
                    height: shortened ? "220px" : "300px",
                    width: "100%",
                    backgroundImage:
                      "linear-gradient(#00000080, #00000080),url(https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/60edefc65e33fa4f6e738f2e_thumbnail-BackyardHome.jpg)",
                    backgroundSize: "cover",
                    opacity: 0.5,
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                >
                  <MKTypography variant="h4" color="white" mb={2}>
                    ADU (guesthouse)
                  </MKTypography>
                </ToggleButton>
                <ToggleButton
                  value="Primary Home"
                  name="project"
                  sx={{
                    p: 0.5,
                    fontWeight: "normal",
                    fontSize: "16px",
                    textTransform: "none !important",
                    borderRadius: "5px !important",
                    marginTop: 1,
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "end",
                    height: shortened ? "220px" : "300px",
                    backgroundImage:
                      "linear-gradient(#00000080, #00000080), url(https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/60edefc65e33fa727d738f30_thumbnail-PrimaryHome.jpg)",
                    backgroundSize: "cover",
                    opacity: 0.5,
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                >
                  <MKTypography variant="h4" color="white" mb={2}>
                    Primary Home
                  </MKTypography>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        )}
        {step === 4 && (
          <Grid
            container
            maxWidth="1000px"
            textAlign="center"
            spacing={2}
            sx={{ mt: shortened ? 0 : { xs: "20px", md: "50px" } }}
          >
            <Grid item xs={12}>
              <MKTypography variant="body2">3/{bigButton ? 3 : 4}</MKTypography>
              <MKTypography variant="h2">Budget</MKTypography>
              <MKTypography variant="body2">
                Select what level of architecture you are interested in
              </MKTypography>
            </Grid>
            <Grid item xs={12}>
              <ToggleButtonGroup
                color="primary"
                sx={{
                  alignItems: "center",
                  mx: 0,
                  px: 0,
                  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                    borderColor: "rgba(0,0,0,0.12) ",
                  },
                  "& .Mui-selected": {
                    backgroundColor: "#F6F7FF !important",
                    borderColor: "#2A2CB1 !important",
                  },
                }}
                value={form.quality}
                exclusive
                orientation={width < 768 ? "vertical" : "horizontal"}
                onChange={(_, v) => {
                  if (shortened) {
                    handleToggle("quality", v);
                    externalHandleSubmit(form);
                  } else {
                    handleToggle("quality", v, 5);
                  }
                  return;
                }}
              >
                <ToggleButton
                  value="Good"
                  name="quality"
                  sx={{
                    p: 0.5,
                    marginRight: { xs: 0, md: 1 },
                    marginBottom: 1,
                    fontWeight: "normal",
                    width: "100%",
                    fontSize: "16px",
                    textTransform: "none !important",
                    borderRadius: "5px !important",
                    display: "flex",
                    backgroundColor: "#FFF",
                    height: shortened ? "320px" : "400px",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    pt: 3,
                    px: 1,
                  }}
                >
                  <img
                    src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/60edefc65e33fa76bc738f2c_Custom-Cover.svg"
                    width="150"
                    alt=""
                  />
                  <MKTypography variant="body2" mt={2} fontWeight="bold">
                    GOOD
                  </MKTypography>
                  {shortened
                    ? "Limited glass, mid-range bathroom & kitchen finishes."
                    : "Framed construction, stucco exterior, limited glass, high-end stock door and window systems, mid-range bathroom and kitchen finishes"}
                  <MKTypography variant="body2" mt={2} mb={3} fontWeight="bold">
                    $300/sf
                  </MKTypography>
                </ToggleButton>
                <ToggleButton
                  value="Better"
                  name="quality"
                  sx={{
                    p: 0.5,
                    fontWeight: "normal",
                    marginRight: { xs: 0, md: 1 },
                    marginBottom: 1,
                    width: "100%",
                    fontSize: "16px",
                    borderColor: "rgba(0,0,0,0.12) !important",
                    textTransform: "none !important",
                    borderRadius: "5px !important",
                    display: "flex",
                    backgroundColor: "#FFF",
                    height: shortened ? "320px" : "400px",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    pt: 3,
                    px: 1,
                  }}
                >
                  <img
                    width="150"
                    src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/60edefc65e33fa2879738f31_quality-better.png"
                    alt=""
                  />
                  <MKTypography variant="body2" mt={2} fontWeight="bold">
                    BETTER
                  </MKTypography>
                  {shortened
                    ? "Long spans, more glass, high-end materials & finishes."
                    : "Steel construction, long spans, metal exterior cladding, large glass expanses, high-end stock door and windows, high-end bathroom and kitchen finishes"}
                  <MKTypography variant="body2" mt={2} mb={3} fontWeight="bold">
                    $500/sf
                  </MKTypography>
                </ToggleButton>
                <ToggleButton
                  value="Best"
                  name="quality"
                  sx={{
                    p: 0.5,
                    fontWeight: "normal",
                    marginBottom: 1,
                    width: "100%",
                    fontSize: "16px",
                    borderColor: "rgba(0,0,0,0.12) !important",
                    textTransform: "none !important",
                    borderRadius: "5px !important",
                    display: "flex",
                    backgroundColor: "#FFF",
                    height: shortened ? "320px" : "400px",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    pt: 3,
                    px: 1,
                  }}
                >
                  <img
                    width="150"
                    src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/60edefc65e33fa6181738f32_quality-best.png"
                    alt=""
                  />
                  <MKTypography variant="body2" mt={2} fontWeight="bold">
                    BEST
                  </MKTypography>
                  {shortened
                    ? "Longest spans, large glass expanses, custom details & finishes."
                    : "Long spans, large glass expanses, custom door and window details, high-end bathroom and kitchen finishes"}
                  <MKTypography variant="body2" mt={2} mb={3} fontWeight="bold">
                    $800/sf
                  </MKTypography>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        )}
        {step === 5 && (
          <Grid
            container
            maxWidth="1600px"
            textAlign="center"
            spacing={2}
            sx={{ mt: shortened ? 0 : { xs: "20px", md: "50px" } }}
          >
            <Grid item xs={12}>
              <MKTypography variant="body2">4/{bigButton ? 3 : 4}</MKTypography>
              <MKTypography variant="h2">What can we do to help?</MKTypography>
              <MKTypography variant="body2">Select all the services that you may need</MKTypography>
            </Grid>
            <Grid item xs={12}>
              <ToggleButtonGroup
                color="primary"
                sx={{
                  alignItems: "center",
                  mx: 0,
                  px: 0,
                  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                    borderColor: "rgba(0,0,0,0.12) ",
                  },
                  "& .Mui-selected": {
                    backgroundColor: "#F6F7FF !important",
                    borderColor: "#2A2CB1 !important",
                    border: "1px solid !important",
                  },
                }}
                value={form.services}
                orientation={width < 768 ? "vertical" : "horizontal"}
                onChange={(_, v) => handleToggle("services", v, 5)}
              >
                <ToggleButton
                  value="Budget"
                  name="services"
                  sx={{
                    p: 0.5,
                    marginRight: { xs: 0, md: 1 },
                    marginBottom: 1,
                    fontWeight: "normal",
                    width: "100%",
                    minWidth: "180px",
                    fontSize: "16px",
                    borderColor: "rgba(0,0,0,0.12) !important",
                    textTransform: "none !important",
                    borderRadius: "5px !important",
                    display: "flex",
                    backgroundColor: "#FFF",
                    height: "200px",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "end",
                    py: 3,
                    px: 2,
                  }}
                >
                  <img
                    height="100px"
                    src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/60edefc65e33fa54a8738e6b_Just%20Property.png"
                    alt=""
                  />
                  <MKTypography variant="body2" mt={2}>
                    Cost & Budget
                  </MKTypography>
                </ToggleButton>
                <ToggleButton
                  value="Bids"
                  name="services"
                  sx={{
                    p: 0.5,
                    marginRight: { xs: 0, md: 1 },
                    marginBottom: 1,
                    fontWeight: "normal",
                    width: "100%",
                    minWidth: "180px",
                    fontSize: "16px",
                    borderColor: "rgba(0,0,0,0.12) !important",
                    textTransform: "none !important",
                    borderRadius: "5px !important",
                    display: "flex",
                    backgroundColor: "#FFF",
                    height: "200px",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "end",
                    py: 3,
                    px: 2,
                  }}
                >
                  <img
                    height="100px"
                    src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/60edefc65e33fa2712738e44_Illustration-Part-Only.png"
                    alt=""
                  />
                  <MKTypography variant="body2" mt={2}>
                    Get Bids
                  </MKTypography>
                </ToggleButton>
                <ToggleButton
                  value="Design"
                  name="services"
                  sx={{
                    p: 0.5,
                    marginRight: { xs: 0, md: 1 },
                    marginBottom: 1,
                    fontWeight: "normal",
                    width: "100%",
                    minWidth: "180px",
                    fontSize: "16px",
                    borderColor: "rgba(0,0,0,0.12) !important",
                    textTransform: "none !important",
                    borderRadius: "5px !important",
                    display: "flex",
                    backgroundColor: "#FFF",
                    height: "200px",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "end",
                    py: 3,
                    px: 2,
                  }}
                >
                  <img
                    height="100px"
                    src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/60edefc65e33fa73c7738e43_Illustration-Floor-Plan.png"
                    alt=""
                  />
                  <MKTypography variant="body2" mt={2}>
                    Find ADU Designs
                  </MKTypography>
                </ToggleButton>
                <ToggleButton
                  value="Lenders"
                  name="services"
                  sx={{
                    p: 0.5,
                    marginRight: { xs: 0, md: 1 },
                    marginBottom: 1,
                    fontWeight: "normal",
                    width: "100%",
                    minWidth: "180px",
                    fontSize: "16px",
                    borderColor: "rgba(0,0,0,0.12) !important",
                    textTransform: "none !important",
                    borderRadius: "5px !important",
                    display: "flex",
                    backgroundColor: "#FFF",
                    height: "200px",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "end",
                    py: 3,
                    px: 2,
                  }}
                >
                  <img
                    height="100px"
                    src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/6311185ce9ff8d82fea4ffee_Diagram-Financing.png"
                    alt=""
                  />
                  <MKTypography variant="body2" mt={2}>
                    Shop Lenders
                  </MKTypography>
                </ToggleButton>
                <ToggleButton
                  value="Zoning"
                  name="services"
                  sx={{
                    p: 0.5,
                    marginRight: { xs: 0, md: 1 },
                    marginBottom: 1,
                    fontWeight: "normal",
                    width: "100%",
                    minWidth: "180px",
                    fontSize: "16px",
                    borderColor: "rgba(0,0,0,0.12) !important",
                    textTransform: "none !important",
                    borderRadius: "5px !important",
                    display: "flex",
                    backgroundColor: "#FFF",
                    height: "200px",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "end",
                    py: 3,
                    px: 2,
                  }}
                >
                  <img
                    height="100px"
                    src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/636c7910f404199d36d101f4_onboarding-graphic-zoning.png"
                    alt=""
                  />
                  <MKTypography variant="body2" mt={2}>
                    Layout & Zoning
                  </MKTypography>
                </ToggleButton>
                <ToggleButton
                  value="Rental"
                  name="services"
                  sx={{
                    p: 0.5,
                    marginRight: { xs: 0, md: 1 },
                    marginBottom: 1,
                    fontWeight: "normal",
                    width: "100%",
                    minWidth: "180px",
                    fontSize: "16px",
                    borderColor: "rgba(0,0,0,0.12) !important",
                    textTransform: "none !important",
                    borderRadius: "5px !important",
                    display: "flex",
                    backgroundColor: "#FFF",
                    height: "200px",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "end",
                    py: 3,
                    px: 2,
                  }}
                >
                  <img
                    height="100px"
                    src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/636c7965e4870b6e4738b814_onboarding-graphic-rental.png"
                    alt=""
                  />
                  <MKTypography variant="body2" mt={2}>
                    Rental Projections
                  </MKTypography>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <MKButton
                variant="contained"
                color="primary"
                style={{ width: "100%", maxWidth: "420px" }}
                onClick={handleSubmit}
              >
                {isLoggedIn ? "Finish" : "Next"}
              </MKButton>
            </Grid>
          </Grid>
        )}
        {step === 6 && (
          <Grid
            container
            maxWidth="420px"
            textAlign="center"
            spacing={2}
            sx={{ mt: { xs: "20px", md: "50px" }, pb: 6 }}
          >
            <Grid item xs={12}>
              <MKTypography variant="h2">Finish setting up your account</MKTypography>
            </Grid>
            <Grid item xs={12}>
              <MKInput
                style={{ width: "100%" }}
                label="Email Address"
                name="email"
                error={!!error && (!form.email || !form.email.match(mailformat))}
                value={form.email}
                type="email"
                placeholder="Email Address"
                onChange={handleInput}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <MKInput
                style={{ width: "100%" }}
                label="Password"
                name="password"
                error={!!error && !form.password}
                value={form.password}
                type="password"
                placeholder="Password"
                onChange={handleInput}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MKButton
                variant="contained"
                color="primary"
                style={{ width: "100%", maxWidth: "420px" }}
                onClick={handleSignup}
              >
                Get Started
              </MKButton>
              <MKTypography variant="caption" textAlign="center" color="error">
                {errorMsg}
              </MKTypography>
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
};

// Typechecking props for the Page3
Onboarding.propTypes = {
  externalForm: PropTypes.shape({}),
  externalHandleSubmit: PropTypes.func,
  noNavbar: PropTypes.bool,
  NoFullHeight: PropTypes.bool,
  shortened: PropTypes.bool,
  bigButton: PropTypes.bool,
};

export default Onboarding;
