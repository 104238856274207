import { useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import {
  Chip,
  FormControl,
  Grid,
  InputLabel,
  ToggleButton,
  MenuItem,
  Stack,
  Select,
  ToggleButtonGroup,
} from "@mui/material";

// @mui icons
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import HourglassTopOutlinedIcon from "@mui/icons-material/HourglassTopOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { formatDollars } from "helpers/formatters";

// import loanCalc from "../calculator";
const rates = [
  { id: 0, monthlyPayment: 1245, apr: 0.15 },
  { id: 1, monthlyPayment: 1265, apr: 0.25 },
];

function Page2({ form, updateForm, handleSubmit }) {
  const [term, setTerm] = useState(30);
  const [sort, setSort] = useState("payment");
  console.log(form);
  console.log(updateForm);
  return (
    <>
      <MKBox
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          px: 1,
        }}
      >
        <MKTypography mt={2} variant="h4" textAlign="center">
          Congrats, you have 3 options!
        </MKTypography>
        <MKTypography variant="body2" mb={1} textAlign="center" maxWidth="380px">
          Select the loans that you&apos;d like to get started
        </MKTypography>

        <MKBox
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            maxWidth: "420px",
            width: "100%",
          }}
        >
          <MKBox display="flex" flexDirection="row" justifyContent="center" my={2}>
            <Stack direction="row" spacing={2} alignItems="center">
              <MKTypography variant="body2" fontWeight="bold">
                Filters
              </MKTypography>
              <FormControl sx={{ minWidth: "110px" }}>
                <InputLabel>Term</InputLabel>
                <Select value={term} label="Term" onChange={(e) => setTerm(e.target.value)}>
                  <MenuItem value={0}>Show All</MenuItem>
                  <MenuItem value={5}>5 Years</MenuItem>
                  <MenuItem value={10}>10 Years</MenuItem>
                  <MenuItem value={15}>15 Years</MenuItem>
                  <MenuItem value={20}>20 Years</MenuItem>
                  <MenuItem value={30}>30 Years</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: "175px" }}>
                <InputLabel>Sort</InputLabel>
                <Select value={sort} label="Sort" onChange={(e) => setSort(e.target.value)}>
                  <MenuItem value="payment">Monthly Payment</MenuItem>
                  <MenuItem value="rate">Interest Rate</MenuItem>
                  <MenuItem value="total">Lifetime Interest</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </MKBox>
          <Grid container rowSpacing={1.5} sx={{ maxWidth: "420px", width: "100%" }}>
            <Grid item xs={12} width="100%">
              <ToggleButtonGroup
                color="primary"
                sx={{
                  alignItems: "center",
                  mx: 0,
                  px: 0,
                  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                    borderColor: "rgba(0,0,0,0.12) ",
                  },
                  "& .Mui-selected": {
                    backgroundColor: "#F6F7FF !important",
                    borderColor: "#2A2CB1 !important",
                  },
                }}
                value={"flat"}
                exclusive
                orientation={"vertical"}
                // onChange={(_, v) => handleToggle("condition", v)}
                fullWidth
              >
                {rates.map((r) => {
                  return (
                    <ToggleButton
                      key={r.id}
                      value="calcon"
                      color="primary"
                      selected={false}
                      disabled
                      sx={{
                        display: "block",
                        cursor: "pointer",
                        flex: 1,
                        width: "100%",
                        height: "100%",
                        minHeight: "80px",
                        pt: 2,
                        pb: 2,
                        px: 2,
                        mb: 1,
                        borderColor: "rgba(0,0,0,0.12) !important",
                        borderRadius: "5px !important",
                        textTransform: "none",
                        boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.05)",
                        "&:hover:not(:disabled)": {
                          borderColor: "primary.main",
                          opacity: 1,
                        },
                        "& .Mui-selected": {
                          backgroundColor: "#F6F7FF !important",
                          borderColor: "#2A2CB1 !important",
                        },
                        "&:active": {
                          backgroundColor: "#FFF",
                        },
                      }}
                    >
                      <MKBox
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <MKBox
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <MKTypography variant="h4" fontWeight="regular" mr={0.5}>
                            {formatDollars(r.monthlyPayment)}
                          </MKTypography>
                          <span>
                            <MKTypography variant="body2"> /month</MKTypography>
                          </span>
                        </MKBox>
                        <Chip
                          label="2nd Mortgage"
                          sx={{ borderRadius: 0, backgroundColor: "light.lighter" }}
                        />
                      </MKBox>
                      <MKBox
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "start",
                          mt: 1,
                        }}
                      ></MKBox>
                      <MKBox
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", iframe: "row" },
                          justifyContent: "space-between",
                          alignItems: { xs: "start", iframe: "center" },
                          mt: 1,
                        }}
                      >
                        <MKBox display="flex" flexDirection="row" alignItems="center" mr={"16px"}>
                          <PercentOutlinedIcon
                            color="info"
                            sx={{
                              fontSize: "13px !important",
                              padding: 0.15,
                              mr: 0.35,
                              mt: -0.25,
                              borderRadius: "50%",
                              borderStyle: "solid",
                              borderWidth: "1px",
                              borderColor: "info.main",
                              color: "info.main",
                            }}
                          />
                          <MKTypography
                            variant="body1"
                            color="info"
                            fontWeight="regular"
                            fontSize="85%"
                          >
                            {/* {(aprArr[0] * 100).toFixed(2)}% APR */}
                          </MKTypography>
                        </MKBox>
                        <MKBox display="flex" flexDirection="row" alignItems="center" mr={"16px"}>
                          <HourglassTopOutlinedIcon
                            color="info"
                            sx={{
                              fontSize: "13px !important",
                              padding: 0.15,
                              mr: 0.35,
                              mt: -0.25,
                              borderRadius: "50%",
                              borderStyle: "solid",
                              borderWidth: "1px",
                              borderColor: "info.main",
                              color: "info.main",
                            }}
                          />
                          <MKTypography
                            variant="body1"
                            color="info"
                            fontWeight="regular"
                            fontSize="85%"
                          >
                            30/15
                          </MKTypography>
                        </MKBox>
                        <MKBox display="flex" flexDirection="row" alignItems="center">
                          <HomeOutlinedIcon
                            color="info"
                            sx={{
                              fontSize: "14px !important",
                              padding: 0.15,
                              mr: 0.25,
                              mt: -0.25,
                              borderRadius: "50%",
                              borderStyle: "solid",
                              borderWidth: "1px",
                              borderColor: "info.main",
                              color: "info.main",
                            }}
                          />
                          <MKTypography
                            variant="body1"
                            color="info"
                            fontWeight="regular"
                            fontSize="85%"
                            mr={1}
                          >
                            {/* {`$${numShortener(totalInterest)} Lifetime Interest`} */}
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <MKButton
            size="large"
            color="primary"
            sx={{ maxWidth: "420px", width: "100%", mt: 2 }}
            fullWidth
            onClick={handleSubmit}
          >
            <LockOutlinedIcon color="light" sx={{ mr: "10px" }} />
            Save
          </MKButton>
          <MKTypography variant="body2" mt={2} mb={2}>
            Amount and terms will be quoted after submission
          </MKTypography>
        </MKBox>
      </MKBox>
    </>
  );
}

// Typechecking props for the Page1
Page2.propTypes = {
  nextPage: PropTypes.func.isRequired,
  form: PropTypes.shape({
    creditScore: PropTypes.string,
    homeValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    desiredAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    propertyType: PropTypes.string,
    currentDebt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    calHfaEligible: PropTypes.bool,
    includeGrant: PropTypes.bool,
    monthlyPayment: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    potentialRates: PropTypes.arrayOf(PropTypes.number),
    maxLoan: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedLoan: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  }).isRequired,
  updateForm: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page2;
