export const initialState = {
  model: "",
  default: 0,
  sqFtInput: false,
  turnkey: 0,
  zip: "",
  state: "",
  stateName: "",
  costFactor: 1,
  condition: null,
  buildType: null,
  bedrooms: 0,
  bathrooms: 0,
  quality: 0,
  sqFt: 400,
  costPerSqFt: 0,
  hardCosts: [],
  softCosts: [],
  totalHardCosts: 0,
  totalSoftCosts: 0,
  totalCosts: 0,
  amountDown: 0,
  loanAmount: 0,
  estimatedRent: 0,
  estimatedCost: 0,
  email: "",
};
