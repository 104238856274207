import { useState } from "react";
import { Container } from "@mui/material";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";

import Navbar from "Users/Components/Navbar";
import ZipCode from "./Steps/ZipCode";
import Timeline from "./Steps/Timeline";
import Type from "./Steps/Type";
import Signup from "./Steps/Signup";
import useWindowSize from "Hooks/useWindowSize";

const Project = () => {
  const [form, setForm] = useState({ zip: "", timeline: "", type: "" });
  const [step, setStep] = useState(0);
  const { height } = useWindowSize();

  const handleBack = () => {
    setStep(step - 1);
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  return (
    <>
      {step !== 0 && (
        <>
          <Navbar />
          <Container width="100%">
            <MKButton variant="text" color="info" size="large" onClick={handleBack}>
              Go Back
            </MKButton>
          </Container>
          <MKBox sx={{ display: { xs: "block", md: "none" }, height: "10px" }} />
        </>
      )}
      <Container
        sx={{
          minHeight: step === 0 ? `${height}px` : `${height - 111}px`,
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {step === 0 && (
          <ZipCode
            zip={form.zip}
            setZip={(v) => setForm({ ...form, zip: v.target.value })}
            nextStep={nextStep}
          />
        )}
        {step === 1 && (
          <Timeline
            back={handleBack}
            nextStep={nextStep}
            setTimeline={(v) => {
              setForm({ ...form, timeline: v });
              nextStep();
            }}
          />
        )}
        {step === 2 && (
          <Type
            back={handleBack}
            setType={(v) => {
              setForm({ ...form, type: v });
              nextStep();
            }}
          />
        )}
        {step === 3 && <Signup form={form} />}
      </Container>
    </>
  );
};

// Typechecking props for the Page3
Project.propTypes = {};

export default Project;
