import { useEffect, useState, useRef } from "react";
import { useAuth } from "@memberstack/react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import ReactGA from "react-ga4";
import Authentication from "Context/auth";
import useDimension from "Hooks/useDimension";
// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Widget from "Widget";
import Estimate from "Estimate";
import Login from "Users/Auth/Login";
import Dashboard from "Users/Dashboard";
import Quotes from "Users/Quotes";
import Onboarding from "Users/Onboarding";
import Autoadvisor from "Users/Autoadvisor";
import Favorite from "Users/Components/FavoriteButton";

import Calculator from "Users/Calculator";
import Signup from "Users/Auth/Signup";
import Project from "Users/Project";
import Overview from "Users/Project/Overview";

import "App.css";

ReactGA.initialize("G-GGP9TN3QGC", {
  gaOptions: {
    cookieFlags: "SameSite=None; Secure",
  },
  gtagOptions: {
    cookieFlags: "SameSite=None; Secure",
  },
});

export default function App() {
  const { getToken } = useAuth();
  const divRef = useRef(null);
  const { height } = useDimension(divRef);
  const [jwt, setJwt] = useState(null);
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();

  useEffect(() => {
    parent.postMessage(height, "*");
  }, [height]);

  useEffect(() => {
    const localJwt = getToken();
    if (localJwt) {
      setJwt(localJwt);
      axios.defaults.headers.common["Authorization"] = localJwt;
    }
    parent.postMessage({ type: "ready" }, "*");
    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
    eventer(
      messageEvent,
      function (e) {
        if (
          e.origin !== "https://www.dwellito.com" &&
          e.origin !== "https://dwellito-staging.webflow.io"
        ) {
          return;
        }
        var key = e.message ? "message" : "data";
        var data = e[key];
        if (data.type === "jwt") {
          if (data.token) {
            setJwt(data.token);
            axios.defaults.headers.common["Authorization"] = data.token;
          }
        }
      },
      false
    );
  }, []);

  const updateJwt = (v) => {
    setJwt(v);
    axios.defaults.headers.common["Authorization"] = v;
  };

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <Authentication.Provider value={{ jwt, setJwt: updateJwt }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div ref={divRef}>
          <Routes>
            <Route path="/calculator" element={<Estimate formId={searchParams.get("formId")} />} />
            <Route path="/" element={<Widget formId={searchParams.get("formId")} />} />
            <Route path="/favorite" element={<Favorite />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/homeowner" element={<Dashboard />} />
            <Route path="/homeowner/onboarding" element={<Onboarding />} />
            <Route path="/homeowner/calculator" element={<Calculator />} />
            <Route path="/homeowner/autoadvisor" element={<Autoadvisor />} />
            <Route path="/homeowner/project" element={<Project />} />
            <Route path="/homeowner/dashboard" element={<Overview />} />
            <Route path="/homeowner/quotes" element={<Quotes />} />
            <Route path="*" element={<Navigate to="/signup" />} />
          </Routes>
        </div>
      </ThemeProvider>
    </Authentication.Provider>
  );
}
