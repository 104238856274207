/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React base styles
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";

// Material Kit 2 React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { size } = typography;
const { grey, white, info, primary, error } = colors;
const { borderRadius, borderWidth } = borders;
const { sliderBoxShadow } = boxShadows;

export default {
  styleOverrides: {
    root: {
      width: "100%",

      "& .MuiSlider-active, & .Mui-focusVisible": {
        boxShadow: "none !important",
      },

      "& .MuiSlider-valueLabelCircle": {
        marginBottom: "-13px",
        marginLeft: "-10px",
      },

      "& .MuiSlider-valueLabel": {
        color: primary.main,
        fontSize: size.sm,
        backgroundColor: "transparent",
      },

      "& .MuiSlider-markLabel": {
        fontSize: size.xs,
        marginTop: "-5px",
      },

      "& .MuiSlider-thumb": {
        width: "20px",
        height: "20px",
        '&[data-index="0"]': {
          "& .MuiSlider-valueLabel": {},
        },
        '&[data-index="1"]': {
          "& .MuiSlider-valueLabel": {},
        },
      },

      "&.MuiSlider-error": {
        border: `${borderWidth[1]} solid ${error.main}`,
        borderRadius: "5px",
        "& .MuiSlider-valueLabel": {
          color: error.main,
        },
      },
    },

    rail: {
      height: pxToRem(2),
      background: grey[300],
      borderRadius: borderRadius.sm,
      opacity: 1,
    },

    track: {
      background: primary.main,
      height: pxToRem(3),
      position: "relative",
      border: "none",
      borderRadius: borderRadius.lg,
      zIndex: 1,
    },

    thumb: {
      width: pxToRem(40),
      height: pxToRem(40),
      backgroundColor: white.main,
      zIndex: 10,
      boxShadow: sliderBoxShadow.thumb,
      border: `${borderWidth[1]} solid ${info.main}`,
      transition: "all 150ms linear",

      "&:hover": {
        boxShadow: "none",
      },
    },
  },
};
