import { useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import { LockOutlined } from "@mui/icons-material";

import { ReactComponent as ProgressBar } from "assets/images/shapes/signup-step.svg";
import users from "assets/images/adu/users.png";
import aduBorder from "assets/images/adu/adus_border.png";

import MKBox from "components/MKBox";

const Signup = ({ form, setForm }) => {
  const [error, setError] = useState(false);

  const handleStep = () => {
    setError(false);
  };

  return (
    <>
      <MKBox maxWidth="100%">
        <Grid container maxWidth="450px" textAlign="center" spacing={2}>
          <Grid item xs={12}>
            <MKTypography variant="h3" fontWeight="regular">
              Almost there!
            </MKTypography>
            <MKTypography variant="body2" fontWeight="regular" mb={2}>
              Signing up will allow you to keep your project planning safe and secure. You&apos;ll
              also have more functionality!
            </MKTypography>
            <ProgressBar style={{ width: "100%" }} />
          </Grid>
        </Grid>
        <Grid container maxWidth="440px" rowSpacing={2} columnSpacing={2}>
          <Grid item xs={6}>
            <MKInput
              fullWidth
              size="large"
              type="text"
              style={{ backgroundColor: "white", borderRadius: "10px" }}
              name="fname"
              placeholder="First Name"
              onChange={(v) => setForm("fname", v.target.value)}
              value={form.fname}
              error={error && !form.fname}
              inputProps={{
                style: { fontSize: "18px" },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <MKInput
              fullWidth
              size="large"
              type="text"
              style={{ backgroundColor: "white", borderRadius: "10px" }}
              name="lname"
              placeholder="Last Name"
              onChange={(v) => setForm("lname", v.target.value)}
              value={form.lname}
              error={error && !form.lname}
              inputProps={{
                style: { fontSize: "18px" },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MKInput
              fullWidth
              size="large"
              type="text"
              style={{ backgroundColor: "white", borderRadius: "10px" }}
              name="email"
              placeholder="Email Address"
              onChange={(v) => setForm("email", v.target.value)}
              value={form.email}
              error={error && !form.email}
              inputProps={{
                style: { fontSize: "18px" },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MKInput
              fullWidth
              size="large"
              type="password"
              style={{ backgroundColor: "white", borderRadius: "10px" }}
              name="password"
              placeholder="Password"
              onChange={(v) => setForm("password", v.target.value)}
              value={form.password}
              error={error && !form.password}
              inputProps={{
                style: { fontSize: "18px" },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MKButton fullWidth size="large" color="primary" onClick={handleStep}>
              <LockOutlined sx={{ mt: -0.5, mr: 1 }} />
              Sign up
            </MKButton>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt={2}
      >
        <img src={users} width="100px" />
        <MKTypography variant="body2" mt={1}>
          We&apos;ve helped over <span style={{ textDecoration: "underline" }}>3,000</span>{" "}
          homeowners!
        </MKTypography>
      </MKBox>
      <img src={aduBorder} style={{ maxWidth: "100%" }} />
    </>
  );
};

// Typechecking props for the Page3
Signup.propTypes = {
  form: PropTypes.shape({
    fname: PropTypes.string,
    lname: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
  }),
  setForm: PropTypes.func,
};

export default Signup;
