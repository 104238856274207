import { Grid } from "@mui/material";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { ReactComponent as HomeWAdu } from "assets/images/adu/home_w_adu.svg";
import MKButton from "components/MKButton";
import AddressAutoComplete from "components/AdressAutocomple";

const Zoning = () => {
  return (
    <MKBox
      width="100%"
      sx={{
        backgroundColor: "#fff",
        pt: 8,
        pb: 4,
        borderRadius: 2,
        boxShadow: "0px 0px 10px 2px rgba(0,0,0,0.1)",
      }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid container maxWidth="450px" width="100%" textAlign="center" spacing={2}>
        <Grid item xs={12}>
          <MKTypography variant="h4">See what you can build</MKTypography>
          <MKTypography variant="body2">
            We&apos;ll review your property and give you a report of the size of the ADU you can
            build
          </MKTypography>
        </Grid>
        <Grid item xs={12}>
          <HomeWAdu />
        </Grid>
        <Grid item xs={12}>
          <AddressAutoComplete />
        </Grid>
        <Grid item xs={12}>
          <MKButton fullWidth size="large" color="primary">
            Continue
          </MKButton>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <MKTypography variant="caption">Already have zoning down?</MKTypography>
          <MKButton variant="text" sx={{ px: 0, ml: 0.5 }} color="primary">
            Skip to Design & Bids
          </MKButton>
        </Grid>
      </Grid>
    </MKBox>
  );
};

export default Zoning;
