import { useEffect, useState, useContext, useRef } from "react";
import { useAuth } from "@memberstack/react";

import Lottie from "lottie-react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Container,
  CircularProgress,
  Checkbox,
} from "@mui/material";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";
import MKButton from "components/MKButton";

import ExpandMore from "@mui/icons-material/ExpandMore";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ThumbUpOutlined from "@mui/icons-material/ThumbUpOutlined";

import checkmarkAnimation from "assets/animations/checkmark.json";
import loadingAnimation from "assets/animations/loader.json";

import Authentication from "Context/auth";

import Navbar from "Users/Components/Navbar";

import useWindowSize from "Hooks/useWindowSize";
import initialForm from "./Form";
import steps from "./Steps";

import renderer from "./Renderer";

const Autoadvisor = () => {
  const lottieRef = useRef();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(initialForm);
  const [step, setStep] = useState({});
  const [stepCount, setStepCount] = useState(initialForm.stepCount);
  const { height } = useWindowSize();
  const { jwt } = useContext(Authentication);
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    setStep(steps[stepCount]);
    window.scrollTo(0, 0);
  }, [stepCount]);

  useEffect(async () => {
    if (isLoggedIn && jwt) {
      console.log("logged in");
    }
  }, [isLoggedIn, jwt]);

  const updateForm = (f, v) => {
    setForm({ ...form, [f]: v });
  };

  const handleCheck = (id, state) => {
    if (state) {
      updateForm(
        "checked",
        form.checked.filter((i) => i !== id)
      );
    } else {
      updateForm("checked", [...form.checked, id]);
    }
  };

  const handleCta = (c) => {
    if (step.validations && step.validations.length > 0) {
      // run validations
    } else if (step.id === "basic") {
      const index = form.checked.findIndex((o) => o === step.id);
      setLoading(true);
      if (index === -1) {
        setForm({ ...form, checked: [...form.checked, step.id], onboarding: c });
      } else {
        setForm({ ...form, onboarding: c });
      }
      setStepCount(stepCount + 1);
      setTimeout(() => {
        setLoading(false);
      }, 900);
      return;
    } else {
      const index = form.checked.findIndex((o) => o === step.id);
      lottieRef.current.play();
      if (step.id === "cost") {
        setLoading(true);
        if (index === -1) {
          setForm({ ...form, checked: [...form.checked, step.id], cost: c });
        } else {
          updateForm("cost", c);
        }
        setStepCount(stepCount + 1);
        lottieRef.current.stop();
        setTimeout(() => {
          setLoading(false);
        }, 900);
        return;
      } else if (stepCount !== 7) {
        setTimeout(() => {
          if (index === -1) {
            setForm({ ...form, checked: [...form.checked, step.id] });
          }
          setStepCount(stepCount + 1);
          setLoading(true);
          lottieRef?.current.stop();
          setTimeout(() => {
            setLoading(false);
          }, 900);
        }, 1500);
      } else {
        if (index === -1) {
          setForm({ ...form, checked: [...form.checked, step.id] });
        }
      }
    }
  };

  const changeStep = (s) => {
    setLoading(true);
    const newStep = steps.find((i) => i.id === s);
    setStepCount(steps.indexOf(newStep));
    setStep(newStep);
    setTimeout(() => {
      setLoading(false);
    }, 900);
  };

  return (
    <>
      <Container
        sx={{
          minHeight: `${height}px`,
          pb: 3,
          pt: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Navbar />
        <Grid container maxWidth="xl" sx={{ pt: 2, alignItems: "start" }} spacing={5}>
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              position: { sx: "inherit", md: "sticky" },
              top: "0",
            }}
          >
            <MKBox>
              <MKTypography variant="h4" fontWeight="light">
                Welcome to your ADU AutoAdvisor
              </MKTypography>
              <MKTypography variant="body2" style={{ marginTop: "10px" }}>
                Dwellito&apos;s guided journey from concept to turnkey.
              </MKTypography>
            </MKBox>
            <MKBox
              style={{
                position: "relative",
                marginTop: "10px",
              }}
            >
              <MKBox
                style={{
                  display: "inline",
                  position: "absolute",
                  width: "120px",
                  height: "120px",
                  border: "2px solid #dee2e6",
                  borderRadius: "60px",
                  top: 0,
                  left: 0,
                }}
              />
              <MKBox
                textAlign="left"
                style={{ position: "absolute", top: "45px", left: "37px", lineHeight: 0 }}
              >
                <MKTypography color="primary" fontWeight="regular" variant="h4" lineHeight={1}>
                  {`${(100 * (form.checked.length / steps.length)).toFixed(0)}%`}
                </MKTypography>
                <MKTypography variant="body2" color="primary" style={{ fontSize: "12px" }}>
                  Complete
                </MKTypography>
              </MKBox>

              <CircularProgress
                thickness={1}
                color="primary"
                value={100 * (form.checked.length / steps.length)}
                variant="determinate"
                size={120}
              />
            </MKBox>
            <Accordion TransitionProps={{ unmountOnExit: true }} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <MKTypography variant="body2">Steps</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                {steps.map((s) => {
                  return (
                    <MKBox key={s.id} display="flex" alignItems="center" justifyContent="start">
                      <Checkbox
                        checked={!!form.checked.includes(s.id)}
                        onChange={() => handleCheck(s.id, !!form.checked.includes(s.id))}
                        sx={{ py: 0.75 }}
                      />
                      <MKTypography
                        variant="caption"
                        color={
                          s.id === step.id
                            ? "primary"
                            : form.checked.includes(s.id)
                            ? "success"
                            : "info"
                        }
                        fontWeight={
                          s.id === step.id || form.checked.includes(s.id) ? "bold" : "light"
                        }
                        sx={{ textDecoration: form.checked.includes(s.id) && "line-through" }}
                        style={{ cursor: "pointer" }}
                        onClick={() => changeStep(s.id)}
                      >
                        {s.chapter}
                      </MKTypography>
                    </MKBox>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} md={9} lg={7} sx={{ mt: { xs: "-30px", lg: "0px" } }}>
            <MKBox
              height="350px"
              display="flex"
              flexDirection="column"
              textAlign="center"
              alignItems="center"
              justifyContent="center"
              position="relative"
              pt={15}
              style={{ display: loading ? "inherit" : "none" }}
            >
              <MKTypography
                variant="body2"
                color="primary"
                style={{ fontSize: "15px", marginBottom: "-95px", marginRight: "-5px" }}
              >
                loading
              </MKTypography>
              <Lottie
                animationData={loadingAnimation}
                style={{
                  height: "150px",
                }}
              />
            </MKBox>
            <MKBox
              display="flex"
              alignItems="start"
              justifyContent="center"
              flexDirection="column"
              textAlign="left"
              lineHeight={1}
              style={{ visibility: loading ? "hidden" : "inherit" }}
            >
              {step.img && (
                <img
                  src={
                    step.img.local
                      ? require(`../../assets/images/autoadvisor/${step.img.src}`)
                      : step.img.src
                  }
                  loading="lazy"
                  width="100%"
                  style={{
                    maxWidth: "100%",
                    alignSelf: "center",
                    ...step.img.style,
                  }}
                  alt={step.img.alt}
                />
              )}
              <MKBox display="flex" flexDirection="row" justifyContent="start">
                <MKBadge
                  color="primary"
                  size="md"
                  variant="contained"
                  sx={{
                    "& .MuiBadge-badge": { fontWeight: "bold", fontSize: "1rem", py: 0.5 },
                  }}
                  badgeContent={`Step ${stepCount + 1} - ${step.title}`}
                />
                <MKBox display="flex" flexDirection="row" alignItems="center" ml={2} py={0.5}>
                  <ScheduleIcon
                    fontSize="medium"
                    color="info"
                    style={{
                      marginRight: "4px",
                      marginTop: "-3px",
                      fontSize: "1.75rem",
                      stroke: "#F5F5F5",
                      strokeWidth: 1,
                    }}
                  />
                  <MKTypography variant="body2" color="info" fontWeight="light">
                    {step?.time}
                  </MKTypography>
                </MKBox>
              </MKBox>
              <MKBox
                sx={{
                  background: "white",
                  my: "10px",
                  py: 1,
                  px: 2,
                  borderRadius: 1,
                  minWidth: "100%",
                }}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <ThumbUpOutlined
                  color="info"
                  fontSize="md"
                  style={{
                    stroke: "#fff",
                    strokeWidth: 1.25,
                    fontSize: "1.75rem",
                  }}
                />
                <MKTypography
                  variant="body2"
                  color="info"
                  lineHeight={1.5}
                  fontWeight="regular"
                  ml={1}
                >
                  <span style={{ fontWeight: "bold" }}>Goal: </span>
                  {step?.goal}
                </MKTypography>
              </MKBox>
              <MKBox mt={"10px"} display="flex" flexDirection="column" maxWidth="100%">
                {step?.components && renderer(step.components, form, updateForm, handleCta)}
                {step.id !== "cost" && step.id !== "basic" && (
                  <>
                    <MKButton
                      fullWidth
                      size="large"
                      variant="outlined"
                      color="primary"
                      onClick={handleCta}
                    >
                      <span>Mark Complete</span>
                      <Lottie
                        animationData={checkmarkAnimation}
                        lottieRef={lottieRef}
                        autoplay={false}
                        loop={false}
                        style={{
                          height: "80px",
                          width: "80px",
                          marginTop: "-100px",
                          marginBottom: "-100px",
                          marginLeft: "-20px",
                          marginRight: "-60px",
                        }}
                      />
                    </MKButton>
                    {step.nextUp && (
                      <MKButton
                        onClick={handleCta}
                        variant="text"
                        color="primary"
                        style={{ marginTop: "5px", textAlign: "center" }}
                      >
                        <MKTypography
                          variant="body2"
                          color="primary"
                          fontWeight="bold"
                          style={{ textDecoration: "underline" }}
                        >
                          Next Up: {step.nextUp}
                        </MKTypography>
                      </MKButton>
                    )}
                  </>
                )}
              </MKBox>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Autoadvisor;
