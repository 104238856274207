import secureLocalStorage from "react-secure-storage";
const { REACT_APP_USE_UNSECURE_LOCAL_STORAGE } = process.env;

export const setLocalStorage = (variableName, value) => {
  if (REACT_APP_USE_UNSECURE_LOCAL_STORAGE) {
    localStorage.setItem(variableName, JSON.stringify(value));
  } else {
    secureLocalStorage.setItem(variableName, JSON.stringify(value));
  }
  return;
};

export const readLocalStorage = (variableName, defaultValue) => {
  let variable;
  if (REACT_APP_USE_UNSECURE_LOCAL_STORAGE) {
    variable = localStorage.getItem(variableName);
  } else {
    variable = secureLocalStorage.getItem(variableName);
  }
  return variable ? JSON.parse(variable) : defaultValue;
};
