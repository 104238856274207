import { useEffect, useState, useContext } from "react";
import { useAuth } from "@memberstack/react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import { MemberstackProtected } from "@memberstack/react";
import { Grid, Container, CircularProgress, Modal, IconButton } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import Authentication from "Context/auth";

import Login from "Users/Auth/Login";
import Navbar from "Users/Components/Navbar";
import Default from "Lenders/Default";

import useWindowSize from "Hooks/useWindowSize";
import MKInput from "components/MKInput";

const Dashboard = () => {
  const { hash } = useLocation();
  const { jwt } = useContext(Authentication);
  const { height } = useWindowSize();
  const [stepCount, setStepCount] = useState(0);
  const navigate = useNavigate();
  const [favoriteView, setFavoriteView] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [allAdus, setAllAdus] = useState([]);
  const [financingOpen, setFinancingOpen] = useState(false);
  const [search, setSearch] = useState("");
  const { isLoggedIn } = useAuth();
  const [loading, setLoading] = useState(true);
  const [top, setTop] = useState({
    Name: "",
    "Main Image": [{ thumbnails: { large: { url: "" } } }],
  });
  const [dbData, setDbData] = useState({
    allIn: null,
    financing: null,
    onboarding: null,
  });

  useEffect(() => {
    if (hash === "#favorites") {
      setFavoriteView(true);
    }
  }, []);

  useEffect(async () => {
    if (favoriteView && favorites.length === 0) {
      const { data } = await axios.get("/api/v1/favorites/list");
      if (data.favorites) {
        setFavorites(data.favorites);
      }
      let { data: modulars } = await axios.get("/api/v1/favorites/modular-list");
      modulars = modulars.filter((f) => !data.favorites.find((e) => e.Slug === f.Slug));
      setAllAdus(modulars);
    }
  }, [favoriteView]);

  useEffect(async () => {
    if (isLoggedIn && jwt) {
      try {
        const { data } = await axios.get("/api/v1/dashboard");
        let localCount = 0;
        if (data.allIn) localCount++;
        if (data.financing) localCount++;
        if (data.onboarding) localCount++;
        if (data.favoriteCount) localCount++;
        setStepCount(localCount);
        setDbData(data);
      } catch {
        console.log("not found");
      }
      setLoading(false);
    }
  }, [isLoggedIn, jwt, financingOpen]);

  useEffect(async () => {
    if (isLoggedIn && jwt) {
      const { data: airtable } = await axios.get("/api/v1/favorites/contender");
      if (airtable?.Name) {
        setTop(airtable);
      }
    }
  }, [isLoggedIn, jwt, favoriteView]);

  const toggleFavorite = async (id, favorite) => {
    if (favorite) {
      await axios.post("/api/v1/favorites/toggle", { id, toggle: false });
      const newFav = favorites.find((f) => f.Slug === id);
      setFavorites(favorites.filter((f) => f.Slug !== id));
      setAllAdus([...allAdus, newFav]);
    } else {
      await axios.post("/api/v1/favorites/toggle", { id, toggle: true });
      const newFav = allAdus.find((f) => f.Slug === id);
      setAllAdus(allAdus.filter((f) => f.Slug !== id));
      setFavorites([...favorites, newFav]);
    }
  };

  return (
    <>
      <Container
        sx={{
          minHeight: `${height}px`,
          pb: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Navbar />
        <MemberstackProtected onUnauthorized={<Login />}>
          <Modal
            open={financingOpen}
            onClose={() => setFinancingOpen(false)}
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <MKBox
              bgColor="white"
              maxWidth="550px"
              width="100%"
              display="flex"
              flexDirection="column"
              borderRadius="lg"
            >
              <Default handleLocalClose={() => setFinancingOpen(false)} />
            </MKBox>
          </Modal>
          <Grid container maxWidth="lg" sx={{ mt: { xs: "0px", lg: "50px" } }} spacing={4} pb={6}>
            <Grid
              item
              xs={12}
              lg={4}
              sx={{
                textAlign: { xs: "center", md: "left" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                maxHeight: "532px",
              }}
            >
              <MKTypography variant="body2">Hi {dbData?.onboarding?.fname}!</MKTypography>
              <MKTypography variant="h1" fontWeight="light">
                Welcome to your ADU Planner
              </MKTypography>
              <MKBox
                style={{
                  position: "relative",
                  marginTop: "10px",
                  marginBottom: "10px",
                  alignSelf: "center",
                }}
              >
                <MKTypography
                  variant="body2"
                  style={{ position: "absolute", top: "48px", left: "50px" }}
                >
                  {stepCount}/6
                </MKTypography>
                <CircularProgress
                  sx={{ alignSelf: "start" }}
                  thickness={0.5}
                  color="info"
                  value={100 * (stepCount / 6)}
                  variant="determinate"
                  size={120}
                />
              </MKBox>
              <MKTypography variant="body1" fontWeight="light">
                Did you know? To save you cash, you can apply for the California ADU grant for
                incomes up to $250k in your area.
              </MKTypography>
              <hr style={{ marginTop: "10px" }} />
              <MKTypography variant="body2" my="10px">
                Need Help?
              </MKTypography>
              <MKButton variant="outlined" color="info" href={`https://www.dwellito.com/contact`}>
                Contact Us
              </MKButton>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={2}>
                {favoriteView ? (
                  <>
                    <Grid item xs={12}>
                      <MKButton
                        size="medium"
                        variant="text"
                        color="info"
                        onClick={() => {
                          navigate("#");
                          setFavoriteView(false);
                        }}
                      >
                        ← Back to dashboard
                      </MKButton>
                    </Grid>
                    <Grid item xs={12}>
                      <MKTypography variant="h6" textAlign="center" mt={2}>
                        Favorited ADUs
                      </MKTypography>
                    </Grid>
                    {favorites.map((f) => {
                      return (
                        <Grid key={f.Slug} item xs={6} lg={4} style={{ position: "relative" }}>
                          <IconButton
                            sx={{
                              position: "absolute",
                              top: 20,
                              right: 5,
                              zIndex: 2500,
                              fontSize: "22px",
                            }}
                            onClick={() => toggleFavorite(f.Slug, true)}
                          >
                            <FavoriteIcon color="error" />
                          </IconButton>
                          <MKButton
                            style={{ padding: 0, margin: 0, width: "100%" }}
                            href={`https://dwellito.com/modular-homes/${f.Slug}`}
                            target="_blank"
                            fullWidth
                          >
                            <MKBox
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "end",
                                p: 2,
                                width: "100%",
                                height: "100%",
                                minHeight: "200px",
                                borderRadius: 2,
                                boxShadow: 2,
                                backgroundImage: `linear-gradient(#0000004D, #0000004D), url(${f["Main Image"][0].thumbnails.large.url})`,
                                backgroundSize: "cover",
                                textAlign: "center",
                                position: "relative",
                              }}
                            >
                              <MKTypography color="white">{f.Name}</MKTypography>
                              <MKTypography variant="body2" color="white">
                                {f["Square Foot"] && `${f["Square Foot"]} sf`}
                              </MKTypography>
                              <MKTypography variant="body2" color="white">
                                {f.Price &&
                                  `$${f.Price.toLocaleString("en", {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  })}`}
                              </MKTypography>
                            </MKBox>
                          </MKButton>
                        </Grid>
                      );
                    })}
                    {/* <Grid item xs={6} lg={4}>
                      <MKButton
                        style={{
                          padding: 0,
                          margin: 0,
                          width: "100%",
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        }}
                        href={`https://dwellito.com/prefab-homes/`}
                        fullWidth
                      >
                        <MKBox
                          sx={{
                            border: "2px dashed #ddd",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            p: 2,
                            width: "100%",
                            height: "100%",
                            minHeight: "200px",
                            borderRadius: 2,
                            textAlign: "center",
                          }}
                        >
                          <MKTypography
                            color="info"
                            variant="h1"
                            fontWeight="light"
                            lineHeight={0.75}
                          >
                            +
                          </MKTypography>
                          <MKTypography color="info" variant="body2">
                            Favorite
                          </MKTypography>
                        </MKBox>
                      </MKButton>
                    </Grid> */}
                    <Grid item xs={12}>
                      <hr style={{ marginTop: "20px" }} />
                      <MKTypography variant="h6" textAlign="center" mt={2}>
                        All ADUs
                      </MKTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        style={{ width: "100%" }}
                        label="Search"
                        name="search"
                        value={search}
                        type="text"
                        placeholder="Search by ADU name, square footage, bedrooms, description, etc."
                        onChange={(v) => setSearch(v.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    {allAdus
                      .filter((o) =>
                        Object.keys(o).some((k) =>
                          o[k].toString().toLowerCase().includes(search.toLowerCase())
                        )
                      )
                      .map((f) => {
                        return (
                          <Grid key={f.Slug} item xs={6} lg={4} style={{ position: "relative" }}>
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: 20,
                                right: 5,
                                zIndex: 2500,
                                fontSize: "22px",
                              }}
                              onClick={() => toggleFavorite(f.Slug, false)}
                            >
                              <FavoriteBorderIcon color="error" />
                            </IconButton>
                            <MKButton
                              style={{ padding: 0, margin: 0, width: "100%" }}
                              href={`https://dwellito.com/modular-homes/${f.Slug}`}
                              target="_blank"
                              fullWidth
                            >
                              <MKBox
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "end",
                                  p: 2,
                                  width: "100%",
                                  height: "100%",
                                  minHeight: "200px",
                                  borderRadius: 2,
                                  boxShadow: 2,
                                  backgroundImage: `linear-gradient(#0000004D, #0000004D), url(${f["Main Image"][0].thumbnails.large.url})`,
                                  backgroundSize: "cover",
                                  textAlign: "center",
                                }}
                              >
                                <MKTypography color="white">{f.Name}</MKTypography>
                                <MKTypography variant="body2" color="white">
                                  {f["Square Foot"] && `${f["Square Foot"]} sf`}
                                </MKTypography>
                                <MKTypography variant="body2" color="white">
                                  {f.Price &&
                                    `$${f.Price.toLocaleString("en", {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0,
                                    })}`}
                                </MKTypography>
                              </MKBox>
                            </MKButton>
                          </Grid>
                        );
                      })}
                  </>
                ) : (
                  <>
                    <Grid item xs={6} lg={4}>
                      <MKBox
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "end",
                          p: 2,
                          height: "100%",
                          borderRadius: 2,
                          boxShadow: 2,
                          backgroundImage: `linear-gradient(#0000004D, #0000004D), url(${top["Main Image"][0].thumbnails.large.url})`,
                          backgroundSize: "cover",
                          textAlign: "center",
                        }}
                      >
                        <MKTypography variant="body2" color="white">
                          Favorite ADUs:
                        </MKTypography>
                        <MKTypography color="white">
                          {top.Name}
                          <br />
                          {dbData.favoriteCount > 1 && `+${dbData.favoriteCount - 1} Other`}
                          {dbData.favoriteCount > 2 && "s"}
                        </MKTypography>
                        <MKButton
                          variant="outlined"
                          color="white"
                          size="small"
                          onClick={() => {
                            navigate("#favorites");
                            setFavoriteView(true);
                          }}
                        >
                          View Favorites
                        </MKButton>
                      </MKBox>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                      <MKBox
                        sx={{
                          border: "1px solid #ddd",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          p: 2,
                          height: "100%",
                          borderRadius: 2,
                          boxShadow: 2,
                          background: "white",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/6352f1a7ff883235a687cd06_View%202_White_Transparent%20Background.png"
                          loading="lazy"
                          width="200px"
                          sizes="(max-width: 420px) 100vw, 300px"
                          alt=""
                        ></img>
                        <MKTypography variant="body2">All-In Estimate:</MKTypography>
                        <MKTypography>
                          {loading ? <CircularProgress size="20px" /> : dbData.allIn}
                        </MKTypography>
                        <MKButton
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => navigate("/homeowner/calculator")}
                        >
                          {dbData.allIn ? "Edit" : "Get Started"}
                        </MKButton>
                      </MKBox>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                      <MKBox
                        sx={{
                          border: "1px solid #ddd",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          p: 2,
                          height: "100%",
                          borderRadius: 2,
                          boxShadow: 2,
                          background: "white",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/6352f1a7ff883235a687cd06_View%202_White_Transparent%20Background.png"
                          loading="lazy"
                          width="200px"
                          sizes="(max-width: 420px) 100vw, 300px"
                          alt=""
                        ></img>
                        <MKTypography variant="body2">Financing Estimate:</MKTypography>
                        <MKTypography>
                          {loading ? <CircularProgress size="20px" /> : dbData.financing}
                        </MKTypography>
                        <MKButton
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => setFinancingOpen(true)}
                        >
                          {dbData.financing ? "Edit" : "Get Started"}
                        </MKButton>
                      </MKBox>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                      <MKBox
                        sx={{
                          border: "1px solid #ddd",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          p: 2,
                          height: "100%",
                          borderRadius: 2,
                          boxShadow: 2,
                          background: "white",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/6352f1a7ff883235a687cd06_View%202_White_Transparent%20Background.png"
                          loading="lazy"
                          width="200px"
                          sizes="(max-width: 420px) 100vw, 300px"
                          alt=""
                        ></img>
                        <MKTypography variant="body2">Timeline:</MKTypography>
                        <MKTypography>{dbData?.onboarding?.timeline}</MKTypography>
                        <MKButton
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => navigate("/homeowner/onboarding")}
                        >
                          Edit
                        </MKButton>
                      </MKBox>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                      <MKBox
                        sx={{
                          border: "1px solid #ddd",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          p: 2,
                          height: "100%",
                          borderRadius: 2,
                          boxShadow: 2,
                          background: "white",
                          textAlign: "center",
                          opacity: 0.5,
                        }}
                      >
                        <img
                          src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/6352f1a7ff883235a687cd06_View%202_White_Transparent%20Background.png"
                          loading="lazy"
                          width="200px"
                          sizes="(max-width: 420px) 100vw, 300px"
                          alt=""
                        ></img>
                        <MKTypography variant="body2">Rental Income Estimate</MKTypography>
                        <MKTypography>Coming Soon...</MKTypography>
                      </MKBox>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                      <MKBox
                        sx={{
                          border: "1px solid #ddd",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          p: 2,
                          height: "100%",
                          borderRadius: 2,
                          boxShadow: 2,
                          background: "white",
                          textAlign: "center",
                          opacity: 0.5,
                        }}
                      >
                        <img
                          src="https://uploads-ssl.webflow.com/60edefc65e33fa4dda738c05/6352f1a7ff883235a687cd06_View%202_White_Transparent%20Background.png"
                          loading="lazy"
                          width="200px"
                          sizes="(max-width: 420px) 100vw, 300px"
                          alt=""
                        ></img>
                        <MKTypography variant="body2">Buildable Plot Analysis</MKTypography>
                        <MKTypography>Coming Soon...</MKTypography>
                      </MKBox>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </MemberstackProtected>
      </Container>
    </>
  );
};

export default Dashboard;
