import { useState, useRef, useCallback } from "react";
import Map from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import PropTypes from "prop-types";

import MKBox from "components/MKBox";

const Mapbox = ({
  startLat = 34.053708112554695,
  startLng = -118.24274534750228,
  startZoom = 17.5,
  ...rest
}) => {
  const mapRef = useRef();
  const [viewState, setViewState] = useState({
    longitude: startLng,
    latitude: startLat,
    zoom: startZoom,
    pitch: 35,
  });

  const onMapLoad = useCallback(() => {
    mapRef.current.resize();
  }, []);

  return (
    <MKBox {...rest}>
      <Map
        ref={mapRef}
        onLoad={onMapLoad}
        onMove={(evt) => setViewState(evt.viewState)}
        mapStyle="mapbox://styles/dwellito-chris/clcjgwf3z000015qs1088al6u"
        style={{ height: "300px", width: "100%" }}
        {...viewState}
      />
    </MKBox>
  );
};

// Typechecking props for Mapbox
Mapbox.propTypes = {
  startLat: PropTypes.number,
  startLng: PropTypes.number,
  startZoom: PropTypes.number,
};

export default Mapbox;
