import { useAuth } from "@memberstack/react";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import IconButton from "@mui/material/IconButton";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { CircularProgress } from "@mui/material";

const FavoriteButton = () => {
  const { isLoggedIn } = useAuth();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(async () => {
    const paramId = searchParams.get("id");
    if (paramId) {
      setId(paramId);
      if (isLoggedIn) {
        try {
          const { data } = await axios.get(`/api/v1/favorites/favorited`, {
            params: { id: paramId },
          });
          setIsFavorite(data.favorite);
        } catch {
          setLoading(false);
        }
      }
    }
    setLoading(false);
  }, [isLoggedIn]);

  const handleSelect = async () => {
    if (!isLoggedIn) {
      parent.postMessage(
        {
          type: "redirect",
          url: "https://app.dwellito.com/signup",
        },
        "https://www.dwellito.com"
      );
      return;
    }
    setIsFavorite(true);
    await axios.post("/api/v1/favorites/toggle", { id, toggle: true });
  };

  const handleDeselect = async () => {
    setIsFavorite(false);
    await axios.post("/api/v1/favorites/toggle", { id, toggle: false });
  };

  return (
    <MKBox
      style={{
        backgroundColor: "white",
        marginLeft: "-5px",
        marginTop: "-5px",
        width: "150px",
      }}
    >
      {loading ? (
        <CircularProgress size="18px" style={{ marginLeft: "10px", marginTop: "10px" }} />
      ) : isFavorite ? (
        <IconButton onClick={handleDeselect}>
          <FavoriteIcon color="primary" />
          <MKTypography variant="caption" mt={0.5} ml={0.5}>
            Favorited
          </MKTypography>
        </IconButton>
      ) : (
        <IconButton onClick={handleSelect}>
          <FavoriteBorderIcon color="primary" />
          <MKTypography variant="caption" mt={0.5} ml={0.5}>
            Add to favorites
          </MKTypography>
        </IconButton>
      )}
    </MKBox>
  );
};

export default FavoriteButton;
