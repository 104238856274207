const zipLookups = [
  {
    ID: 6,
    state: "AK",
    name: "Alaska",
    min: 99501,
    max: 99950,
  },
  {
    ID: 5,
    state: "AL",
    name: "Alabama",
    min: 35004,
    max: 36925,
  },
  {
    ID: 69,
    state: "AR",
    name: "Arkansas",
    min: 71601,
    max: 72959,
  },
  {
    ID: 8,
    state: "AR",
    name: "Arkansas",
    min: 75502,
    max: 75502,
  },
  {
    ID: 7,
    state: "AZ",
    name: "Arizona",
    min: 85001,
    max: 86556,
  },
  {
    ID: 9,
    state: "CA",
    name: "California",
    min: 90001,
    max: 96162,
  },
  {
    ID: 10,
    state: "CO",
    name: "Connecticut",
    min: 80001,
    max: 81658,
  },
  {
    ID: 11,
    state: "CT",
    name: "Connecticut",
    min: 60011,
    max: 63899,
  },
  {
    ID: 73,
    state: "CT",
    name: "Connecticut",
    min: 64011,
    max: 69289,
  },
  {
    ID: 13,
    state: "DC",
    name: "Washington DC",
    min: 20001,
    max: 20039,
  },
  {
    ID: 74,
    state: "DC",
    name: "Washington DC",
    min: 20042,
    max: 20599,
  },
  {
    ID: 63,
    state: "DC",
    name: "Washington DC",
    min: 20799,
    max: 20799,
  },
  {
    ID: 12,
    state: "DE",
    name: "Delaware",
    min: 19701,
    max: 19980,
  },
  {
    ID: 14,
    state: "FL",
    name: "Florida",
    min: 32004,
    max: 34997,
  },
  {
    ID: 15,
    state: "GA",
    name: "Georgia",
    min: 30001,
    max: 31999,
  },
  {
    ID: 65,
    state: "GA",
    name: "Georgia",
    min: 39901,
    max: 39901,
  },
  {
    ID: 16,
    state: "HI",
    name: "Hawaii",
    min: 96701,
    max: 96898,
  },
  {
    ID: 20,
    state: "IA",
    name: "Iowa",
    min: 50001,
    max: 52809,
  },
  {
    ID: 66,
    state: "IA",
    name: "Iowa",
    min: 68119,
    max: 68120,
  },
  {
    ID: 17,
    state: "ID",
    name: "Idaho",
    min: 83201,
    max: 83876,
  },
  {
    ID: 18,
    state: "IL",
    name: "Illinois",
    min: 60001,
    max: 62999,
  },
  {
    ID: 19,
    state: "IN",
    name: "Indiana",
    min: 46001,
    max: 47997,
  },
  {
    ID: 21,
    state: "KS",
    name: "Kansas",
    min: 66002,
    max: 67954,
  },
  {
    ID: 22,
    state: "KY",
    name: "Kentucky",
    min: 40003,
    max: 42788,
  },
  {
    ID: 68,
    state: "LA",
    name: "Louisiana",
    min: 70001,
    max: 71232,
  },
  {
    ID: 23,
    state: "LA",
    name: "Louisiana",
    min: 71234,
    max: 71497,
  },
  {
    ID: 26,
    state: "MA",
    name: "Massachusetts",
    min: 1001,
    max: 2791,
  },
  {
    ID: 58,
    state: "MA",
    name: "Massachusetts",
    min: 5501,
    max: 5544,
  },
  {
    ID: 77,
    state: "MD",
    name: "Maryland",
    min: 20331,
    max: 20331,
  },
  {
    ID: 78,
    state: "MD",
    name: "Maryland",
    min: 20335,
    max: 20797,
  },
  {
    ID: 25,
    state: "MD",
    name: "Maryland",
    min: 20812,
    max: 21930,
  },
  {
    ID: 24,
    state: "ME",
    name: "Maine",
    min: 3901,
    max: 4992,
  },
  {
    ID: 27,
    state: "MI",
    name: "Michegan",
    min: 48001,
    max: 49971,
  },
  {
    ID: 28,
    state: "MN",
    name: "Minessota",
    min: 55001,
    max: 56763,
  },
  {
    ID: 57,
    state: "MO",
    name: "Missouri",
    min: 63001,
    max: 65899,
  },
  {
    ID: 29,
    state: "MS",
    name: "Mississippi",
    min: 38601,
    max: 39776,
  },
  {
    ID: 59,
    state: "MS",
    name: "Mississippi",
    min: 71233,
    max: 71233,
  },
  {
    ID: 31,
    state: "MT",
    name: "Montana",
    min: 59001,
    max: 59937,
  },
  {
    ID: 38,
    state: "NC",
    name: "North Carolina",
    min: 27006,
    max: 28909,
  },
  {
    ID: 39,
    state: "ND",
    name: "North Dakota",
    min: 58001,
    max: 58856,
  },
  {
    ID: 67,
    state: "NE",
    name: "Nebraska",
    min: 68001,
    max: 68118,
  },
  {
    ID: 32,
    state: "NE",
    name: "Nebraska",
    min: 68122,
    max: 69367,
  },
  {
    ID: 34,
    state: "NH",
    name: "New Hampshire",
    min: 3031,
    max: 3897,
  },
  {
    ID: 35,
    state: "NJ",
    name: "New Jersey",
    min: 7001,
    max: 8989,
  },
  {
    ID: 36,
    state: "NM",
    name: "New Mexico",
    min: 87001,
    max: 88441,
  },
  {
    ID: 33,
    state: "NV",
    name: "Nevada",
    min: 88901,
    max: 89883,
  },
  {
    ID: 60,
    state: "NY",
    name: "New York",
    min: 6390,
    max: 6390,
  },
  {
    ID: 37,
    state: "NY",
    name: "New York",
    min: 10001,
    max: 14975,
  },
  {
    ID: 40,
    state: "OH",
    name: "Ohio",
    min: 43001,
    max: 45999,
  },
  {
    ID: 70,
    state: "OK",
    name: "Oklahoma",
    min: 73001,
    max: 73199,
  },
  {
    ID: 41,
    state: "OK",
    name: "Oklahoma",
    min: 73401,
    max: 74966,
  },
  {
    ID: 42,
    state: "OR",
    name: "Oregon",
    min: 97001,
    max: 97920,
  },
  {
    ID: 43,
    state: "PA",
    name: "Pennsylvania",
    min: 15001,
    max: 19640,
  },
  {
    ID: 44,
    state: "PR",
    name: "Puerto Rico",
    min: 0,
    max: 0,
  },
  {
    ID: 45,
    state: "RI",
    name: "Rhode Island",
    min: 2801,
    max: 2940,
  },
  {
    ID: 46,
    state: "SC",
    name: "South Carolina",
    min: 29001,
    max: 29948,
  },
  {
    ID: 47,
    state: "SD",
    name: "South Dakota",
    min: 57001,
    max: 57799,
  },
  {
    ID: 48,
    state: "TN",
    name: "Tennessee",
    min: 37010,
    max: 38589,
  },
  {
    ID: 49,
    state: "TX",
    name: "Texas",
    min: 73301,
    max: 73301,
  },
  {
    ID: 71,
    state: "TX",
    name: "Texas",
    min: 75001,
    max: 75501,
  },
  {
    ID: 72,
    state: "TX",
    name: "Texas",
    min: 75503,
    max: 79999,
  },
  {
    ID: 61,
    state: "TX",
    name: "Texas",
    min: 88510,
    max: 88589,
  },
  {
    ID: 50,
    state: "UT",
    name: "Utah",
    min: 84001,
    max: 84784,
  },
  {
    ID: 64,
    state: "VA",
    name: "Virginia",
    min: 20040,
    max: 20041,
  },
  {
    ID: 75,
    state: "VA",
    name: "Virginia",
    min: 20040,
    max: 20167,
  },
  {
    ID: 76,
    state: "VA",
    name: "Virginia",
    min: 20042,
    max: 20042,
  },
  {
    ID: 52,
    state: "VA",
    name: "Virginia",
    min: 22001,
    max: 24658,
  },
  {
    ID: 51,
    state: "VT",
    name: "Vermont",
    min: 5001,
    max: 5495,
  },
  {
    ID: 62,
    state: "VT",
    name: "Vermont",
    min: 5601,
    max: 5907,
  },
  {
    ID: 53,
    state: "WA",
    name: "Washington",
    min: 98001,
    max: 99403,
  },
  {
    ID: 55,
    state: "WI",
    name: "Wisconsin",
    min: 53001,
    max: 54990,
  },
  {
    ID: 54,
    state: "WV",
    name: "West Virgina",
    min: 24701,
    max: 26886,
  },
  {
    ID: 56,
    state: "WY",
    name: "Wyoming",
    min: 82001,
    max: 83128,
  },
];

const costFactor = {
  SD: 0.7040309221,
  KS: 0.71783545,
  NE: 0.7316399779,
  ND: 0.7385422419,
  IA: 0.7385422419,
  MI: 0.7523467697,
  IN: 0.7592490337,
  OH: 0.7661512976,
  MO: 0.7868580895,
  MN: 0.8213694092,
  WI: 0.8282716731,
  AR: 0.8696852568,
  MS: 0.8834897846,
  OK: 0.9041965765,
  TN: 0.9041965765,
  FL: 0.9110988404,
  TX: 0.9110988404,
  IL: 0.9180011044,
  AL: 0.9249033683,
  LA: 0.9318056322,
  GA: 0.9456101601,
  KY: 0.9732192159,
  ME: 0.9732192159,
  UT: 1.000828272,
  WY: 1.000828272,
  NM: 1.007730536,
  MD: 1.0146328,
  AZ: 1.021535064,
  CO: 1.035339591,
  MT: 1.035339591,
  NH: 1.035339591,
  WV: 1.042241855,
  NC: 1.042241855,
  PA: 1.042241855,
  SC: 1.042241855,
  VT: 1.062948647,
  VA: 1.069850911,
  ID: 1.090557703,
  DE: 1.131971287,
  NV: 1.152678078,
  RI: 1.166482606,
  OR: 1.180287134,
  WA: 1.187189398,
  CT: 1.194091662,
  MA: 1.214798454,
  NY: 1.24240751,
  NJ: 1.304527885,
  CA: 1.40115958,
  HI: 1.428768636,
  AK: 1.456377692,
};

export { zipLookups, costFactor };
